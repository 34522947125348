// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import "./App.css";
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import HomeScreen from "../../components/src/HomeScreen";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/AccountLogin.web";
import Register from "../../blocks/email-account-registration/src/AccountRegistation.web";
import RegisterOtp from "../../blocks/otp-input-confirmation/src/OTPInputAuth.web";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import UploadDocuments from "../../blocks/documentstorage/src/UploadDocuments.web";
import Menu from "../../blocks/landingpage/src/SideMenuBar.web";
import PanDocumentPage from "../../blocks/documentstorage/src/PanDocument.web";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import FileIncomeTax from "../../blocks/dashboard/src/FileIncomeTax.web";
import Analytics from "../../blocks/analytics/src/Analytics";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import ITRHistory from "../../blocks/user-profile-basic/src/ITRHistory.web";
import TransactionHistory from "../../blocks/user-profile-basic/src/TransactionHistory.web";
import KnowladgeBase from "../../blocks/landingpage/src/KnowladgeBase.web";
import TaxCalculator from "../../blocks/landingpage/src/TaxCalculator.web";
import CryptoVirtualDigitalManualAsset from "../../blocks/multipageforms2/src/CryptoVirtualDigitalManualAsset.web"

import Assessmenttest from '../../blocks/assessmenttest/src/Assessmenttest.web';
import ResidentialStatus from '../../blocks/assessmenttest/src/ResidentialStatus.web';
import HRACalculator from '../../blocks/assessmenttest/src/HRACalculator.web';




const routeMap = {

  HRACalculator:{
    component: HRACalculator,
    path:"/HRACalculator"
  },
  
  Assessmenttest:{
    component: Assessmenttest,
    path:"/IncomeTaxPage"
  },
  ResidentialStatus:{
    component: ResidentialStatus,
    path:"/ResidentialStatus"
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: "/ApiIntegration"
  },
  Register: {
    component: Register,
    path: "/Register"
  },
  RegisterOtp: {
    component: RegisterOtp,
    path: "/RegisterOtp"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/login"
  },
  FileIncomeTax: {
    component: FileIncomeTax,
    path: "/FileIncomeTax"
  },
  UserProfileBasicBlock:{
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"

  },
  KnowladgeBase: {
    component: KnowladgeBase,
    path: "/KnowladgeBase"
  },
  TaxCalculator: {
    component: TaxCalculator,
    path: "/TaxCalculator"
  },
  ITRHistory:{
    component: ITRHistory,
    path: "/ITRHistory"

  },
  TransactionHistory:{
    component: TransactionHistory,
    path: "/TransactionHistory"

  },
  UploadDocuments: {
    component: UploadDocuments,
    path: "/UploadDocuments"
  },
  PanDocumentPage: {
    component: PanDocumentPage,
    path: "/PanDocumentPage"
  },
  Menu: {
    component: Menu,
    path: "/Menu/:navigationBarTitleText"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  LandingPage: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  CryptoVirtualDigitalManualAsset:{
    component:CryptoVirtualDigitalManualAsset,
    path:"/CryptoVirtualDigitalManualAsset"
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <>
        {WebRoutesGenerator({ routeMap })}
      </>
    );
  }
}

export default App;
