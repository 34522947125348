import React, { useState, memo, useEffect } from 'react'

import {
    Box,
    Typography,
    styled,
    Collapse,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Card,
} from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { ResimeSwitch } from './ResimeSwitch.web';
import classNames from 'classnames';
const uploadUp = require("./uploadUp.png")
const Header = (props: any) => {
    const {
        info,
        active = false,
        panlist = [],
        primaryPan = {},
        yearList = [],
        primaryAssismentYear,
        handleSetAssismentYear,
        handleSelector,
        handleNavigateToAddPan,
        isRegimeToggleVisible =false

    } = props
    const [Regime, setRegime] = useState();
    const [openPan, setOpenPan] = useState(false);
    const [primaryPAN, setPrimeryPanel] = useState("")
    const [userName, setUserName] = useState("")
    const [assismentYear, setAssismentYear] = useState("")
    const [openYear, setOpenYear] = useState(false)
    // const 
    useEffect(() => {
        setPrimeryPanel(primaryPan?.attributes?.profile?.attributes?.pan_number)
        setUserName(primaryPan?.attributes?.profile?.attributes?.name)
    }, [primaryPan])
    useEffect(() => {
        setAssismentYear(primaryAssismentYear?.attributes?.assessment_year?.assessment_year)

    }, [primaryAssismentYear])

    const formatAssignmentYear  = (assignmentYear: string): string => {
        const academicYear = assignmentYear.split("");

       const firstStartYear = academicYear.slice(5, 9).join("");
       const secondEndYear = academicYear.slice(14, 17).join("");
       const firstFY = academicYear.slice(21, 25).join("");
       const secondFY = academicYear.slice(30, 32).join("");
    
       const formattedAcademicYear = `A.Y. ${firstStartYear}-${secondEndYear} (FY ${firstFY}-${secondFY})`;

       return formattedAcademicYear;
    };

    const handleSwitch = (e: any) => {
        setRegime(e.target.checked)
    }
    const handleToggleDropDown = () => {
        if (active) {
            setOpenPan(!openPan);
        }
    };
    const handleSelectPan = (pan: string, name: string) => {
        setPrimeryPanel(pan)
        setUserName(name)
        handleSelector(pan)
        setOpenPan(!openPan);
    }
    const blurClass = classNames({
        "displan_none": !openPan && !openYear,
        "blur": true
    })
    const panListCard = classNames({
        "pan_list--card": true,
        "border_green": openPan
    })
    const yearListCard = classNames({
        "year_list--card": true,
        "border_green": openYear
    })
    const activePanPointer = classNames({
        "pointer": active,
        "capital_box-primary-1": true
    })
    const activeYearPointer = classNames({
        "pointer": active,
        "capital_box-primary-2": true,
        "flex_far": true
    })
    const handleOpenYearDropDown = () => {
        setOpenYear(true)
    }
    const handleSelectAssismentYear = (year: string) => {
        setAssismentYear(year)
        setOpenYear(false)
        handleSetAssismentYear(year)
    }
    const handleCloseList = () => {
        setOpenPan(false)
        setOpenYear(false)
    }
    return (
        <BoxWrapper className="capital_header">
            <Box className={blurClass} onClick={handleCloseList}></Box>
            <Box className='pan_information'>
                <Box className='container_relative'>
                    <Box className={activePanPointer} onClick={handleToggleDropDown}>
                        <Typography className="capital_text-bold active_text">PAN : {primaryPAN}</Typography>
                        <Box className="capital_user_name">
                            <Typography style={{ color: "#868A88", fontSize: "11px" }}>{userName}</Typography>
                            <Box>
                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" className="MuiSelect-icon">
                                    <path d="M7.65625 12.9008L12.5 17.707L17.3438 12.9008M12.5 17.0392L12.5 7.29297" stroke="#36403B" stroke-opacity="0.9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                                    </path>
                                    <path d="M12.5 0.875C18.918 0.875 24.125 6.08203 24.125 12.5C24.125 18.918 18.918 24.125 12.5 24.125C6.08203 24.125 0.875 18.918 0.875 12.5C0.875 6.08203 6.08203 0.875 12.5 0.875Z" stroke="#36403B" stroke-opacity="0.9" stroke-width="1.5" stroke-miterlimit="10">
                                    </path>
                                </svg>
                            </Box>
                        </Box>
                    </Box>
                    <Card className={panListCard}>
                        <Collapse in={openPan} timeout={200} unmountOnExit>
                            <List className='pan_list' component="div" disablePadding>
                                {panlist.map((data: {
                                    id: string;
                                    attributes: {
                                        profile: {
                                            attributes: {
                                                pan_number: string;
                                                name: string;
                                            };
                                        };
                                        is_primary: boolean
                                    };
                                }) => {
                                    return (
                                        <ListItem onClick={() => handleSelectPan(data.attributes.profile.attributes.pan_number, data.attributes.profile.attributes.name)}>
                                            <ListItemText >
                                                <Box style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}>
                                                    <Typography className={`capital_text-bold ${data.attributes.is_primary && "active_text"}`}>PAN : {data.attributes.profile.attributes.pan_number}</Typography>
                                                    <Box className="capital_user_name">
                                                        <Typography style={{ color: "#868A88", fontSize: "11px" }}>{data.attributes.profile.attributes.name}</Typography>
                                                        <Box>
                                                            {data.attributes.profile.attributes.pan_number === primaryPAN && <img src={uploadUp} style={{ width: "25px", height: "25px" }} />}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </ListItemText>
                                        </ListItem>
                                    )
                                })
                                }
                                <ListItem button onClick={() => { handleNavigateToAddPan() }}>
                                    <ListItemText >
                                        <Box style={{ display: "flex" }}>
                                            <AddCircle className='add_pan--icon' />
                                            < Typography className="capital_text-bold">ADD NEW PAN CARD</Typography>
                                        </Box>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Collapse>
                    </Card>
                </Box>
                <Box className='container_relative'>

                    <Box className={activeYearPointer} onClick={handleOpenYearDropDown}>
                        <Typography className="capital_text-bold active_text">{assismentYear && formatAssignmentYear(assismentYear)}</Typography>
                        <Box>
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" className="MuiSelect-icon">
                                <path d="M7.65625 12.9008L12.5 17.707L17.3438 12.9008M12.5 17.0392L12.5 7.29297" stroke="#36403B" stroke-opacity="0.9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                                </path>
                                <path d="M12.5 0.875C18.918 0.875 24.125 6.08203 24.125 12.5C24.125 18.918 18.918 24.125 12.5 24.125C6.08203 24.125 0.875 18.918 0.875 12.5C0.875 6.08203 6.08203 0.875 12.5 0.875Z" stroke="#36403B" stroke-opacity="0.9" stroke-width="1.5" stroke-miterlimit="10">
                                </path>
                            </svg>
                        </Box>
                    </Box>
                    <Card className={yearListCard}>
                        <Collapse in={openYear} timeout={200} unmountOnExit>
                            <List className='pan_list' component="div" disablePadding>
                                {yearList.map((data: {
                                    id: number,
                                    assessment_year: string,
                                    start_year: number,
                                    end_year: number,
                                    is_primary: boolean,
                                    created_at: string,
                                    updated_at: string
                                }) => {
                                    return (
                                        <ListItem onClick={() => { handleSelectAssismentYear(data.assessment_year) }}>
                                            <ListItemText >
                                                <Box className='flex_far'>
                                                    <Box className="">
                                                        <Typography className='capital_text-bold active_text'>{data.assessment_year}</Typography>
                                                    </Box>
                                                    {data.assessment_year === assismentYear && < img src={uploadUp} style={{ width: "25px", height: "25px" }} />}
                                                </Box>
                                            </ListItemText>
                                        </ListItem>
                                    )
                                })
                                }
                            </List>
                        </Collapse>
                    </Card>
                </Box>
            </Box>
            {isRegimeToggleVisible && <Box className="capital_regime">
                <Box>
                    <img src={info} />
                    <Typography style={{ margin: "0px 5px" }}>
                        Regime
                    </Typography>
                    <Box className="capital_resime_container">
                        {Regime && <Box className="capital_resime--old">Old</Box>}
                        <ResimeSwitch onClick={(e) => handleSwitch(e)} />
                        {!Regime && <Box className="capital_resime--new">New</Box>}
                    </Box>
                </Box>
            </Box>}
        </BoxWrapper >
    )
}

export default memo(Header)

const BoxWrapper = styled(Box)(({ theme }) => ({
    width: "100%",
    "&.capital_header": {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        margin: "20px 0px"
    },
    "& .pan_information": {
        width: "100%",
        display: "flex",
        flexDirection: "row"
    },
    "& .capital_box-primary-1": {
        backgroundColor: "#F4F6F5",
        display: "flex",
        justifyContent: "space-between",
        width: "400px",
        height: "56px",
        marginRight: "20px",
        padding: "0px 10px",
        borderRadius: "10px",
        alignItems: "center",
    },
    "& .capital_box-primary-2": {
        backgroundColor: "#F4F6F5",
        width: "350px",
        padding: "20px",
        height: "56px",
        display: "flex",
        alignItems: "center",
        borderRadius: "10px"
    },
    "& .capital_regime>div": {
        display: "flex",
        width: "100%",
        justifyContent: "right",
        alignItems: "center",
    },
    "& .capital_user_name": {
        display: "flex",
        gap: "5px",
        alignItems: "center"
    },
    "& .capital_resime_container": {
        position: "relative"
    },
    "& .capital_resime--new": {
        position: "absolute",
        top: "11px",
        right: "11px",
        fontSize: "13.5px",
        color: "#ffffff",
        zIndex: 1,
        fontFamily: "Nunito",
        fontWeight: 600,
    },
    "& .capital_resime--old": {
        position: "absolute",
        top: "11px",
        left: "15px",
        fontSize: "13.5px",
        color: "#ffffff",
        zIndex: 1,
        fontFamily: "Nunito",
        fontWeight: 600,
    },
    "& .container_relative": {
        display: "flex",
        flexDirection: "column",
        position: "relative",
    },
    "& .pan_list--card": {
        marginRight: "20px",
        position: "absolute",
        width: "93%",
        zIndex: 100,
        boxShadow: "3px 3px 9px 0px #3B7252",
        borderRadius: "7px",
    },
    "& .year_list--card": {
        marginRight: "20px",
        position: "absolute",
        width: "100%",
        zIndex: 100,
        boxShadow: "3px 3px 9px 0px #3B7252",
        borderRadius: "7px",
    },
    "& .border_green": {
        border: "1px solid  #3B7252"
    },
    "& .blur": {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0px",
        left: "0px",
        zIndex: 10,
    },
    "& .displan_none": {
        display: "none"
    },
    "& .add_pan--icon": {
        color: "#3B7252",
        marginRight: "10px",
    },
    "& .pan_list": {
        "&>li:nth-child(even)": {
            backgroundColor: "#F4F6F5",
        },
        "&:hover": {
            cursor: "pointer"
        }
    },
    "& .pointer": {
        cursor: "pointer"
    },
    "& .active_text": {
        fontWeight: 'bold',
        opacity: 0.8
    },
    "& .capital_text-bold": {
        fontSize: "16px",
        fontFamily: "Nunito"
    },
    "& .flex_far": {
        display: "flex",
        justifyContent: "space-between",
    },
    [theme.breakpoints.down(1200)]: {
        "& .pan_information": {
            flexDirection: "column",
        },
        "& .capital_box-primary-1": {
            width: "100%",
            marginBottom: "10px"
        },
        "& .capital_box-primary-2": {
            width: "100%"
        },
        "& .pan_list--card": {
            maxWidth: "350px"
        },
        "& .year_list--card": {
            maxWidth: "350px"
        }
    },
    [theme.breakpoints.down(900)]: {
        "&.capital_header": {
            flexDirection: "column",
            alignItems: "start"
        },
        "& .capital_box-primary-2": {
            marginBottom: "10px"
        },
    },

    [theme.breakpoints.down(500)]: {
        "& .pan_list--card": {
            maxWidth: "100%"
        },
    }
}));
