import React from "react";
import {
    Box,
    Button,
    Card,
    Container,
    styled,
    Typography,
    Stepper,
    Step,
    StepLabel,
    Grid,
    IconButton,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from "@material-ui/core";
import {
    LeftArrow,
    calanderIcon,
    downArrow,
    info,
    tooltip,
    DeleteIcon,
    edit,
    DeleteRedIcon,
    search
} from "./assets"
import {
    AddCircle,
    ExpandMore,
} from '@material-ui/icons';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import Header from "../../../components/src/Header.web";
import { CustomTextArea, CustomTextfield } from "../../../components/src/CustomTextfield.web";
import { CustomAutocomplete, StepperContainer, TableContainer } from "../../../components/src/StyledMuiComponent";
import CustomCalander from "../../../components/src/CustomCalander.web";
import LandBuildingController, {
    Props
} from "./LandBuildingController.web";
import ReturnGrid from "../../../components/src/ReturnGrid.web.";
import { ResimeSwitch } from "../../../components/src/ResimeSwitch.web";
import DescriptionAmount from "../../../components/src/DescriptionAmount.web";
import DashboardPanselector from "../../dashboard/src/DashboardPanselector.web";

export const RenderDropdown = (props: any) => {
    return (
        <Box className="LB_autocomplete_box">
            <CustomAutocomplete
                value={props.value}
                renderInput={(params: any) => <CustomTextfield
                    {...params}
                    variant="outlined"
                    fullWidth
                    placeholder="Select"
                />}
                onChange={(e: any, value: any) => {
                    props.handleGetValue(value, props.name)
                }}
                options={props.list}
                fullWidth
            />
            <img className="LB_dropdown_img" src={downArrow} />
        </Box>
    )
}

const Heading = (props: any) => {
    const { value, handleGoToBack } = props;
    return (
        <Box className="LB_main-title">
            <img
                data-testid="back_btn"
                src={LeftArrow}
                alt="back_btn"
                onClick={handleGoToBack}
            />
            <Typography className="LB_body_title-text">{value}</Typography>
        </Box>
    )
}
export const AddMoreItem = (props: any) => {
    const { handleAddMoreItem } = props
    return (
        <Box className="add_more_item">
            <IconButton data-testid="handleAddCostImprovementDetail" style={{ padding: "7px" }} size="medium" onClick={handleAddMoreItem}>
                <AddCircle className="add_icon" />
            </IconButton>
            <Typography>Add More items</Typography>
        </Box>
    )
}
const SwitchContainer = styled(Box)({
    "& .MuiSwitch-track": {
        backgroundColor: "#868C8A"
    },
    "& .MuiSwitch-root": {
        width: "65px !important",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(25px) !important"
    }
})
const CustomAccordion = styled(Accordion)({
    backgroundColor: "#F4F6F5",
    boxShadow: "none",
    borderRadius: "15px",
    "& .MuiSvgIcon-root": {
        fontSize: "2rem"
    },
    "& .MuiIconButton-root": {
        color: "#337152"
    },
    "&.MuiAccordionSummary-root": {
    },
    padding: "5px 15px !important",
    "&:last-child": {
        borderBottomLeftRadius: "15px !important",
        borderBottomRightRadius: "15px !important"
    },
    "&:first-child": {
        borderTopLeftRadius: "15px !important",
        borderTopRightRadius: "15px !important"
    }
})
export default class LandBuilding extends LandBuildingController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const steps = ["Add Details of the asset", "Property Address", "Buyer Details"];
        const properyDetail = classNames({
            PA_body: true,
            "mb-50": this.state.buyerDetails.length > 1 ? true : false
        })
        const handleReturnErrorMessage = (message: any) => {
            return (
                <>
                    {
                        message &&
                        <Box className="error_message">{message}</Box>
                    }
                </>
            )
        }

        return (
            <MainWrapper maxWidth={false}>
                <DashboardPanselector navigation={this.props.navigation} id={""} data-test-id="pancard" />
                <Box className="LB_main">

                    {this.state.landOrBuildingPage === 1 && <>
                        <Heading
                            data-testid="prop_detail_back"
                            value="Add Property Details"
                            handleGoToBack={this.handleNavigateToCapitalGainsPage}
                        />
                        <Typography className="LB_sub_title-text">Applicable if you have sold land or building(s)</Typography>
                        <Card className="card">
                            <Box className="LB_Property_detail">
                                <Box className="LB_property_detail--name mt-30">
                                    <Typography className="font_style-1 mb-10">Description of Asset Sold <span className="star">*</span></Typography>
                                    <CustomTextfield
                                        data-testid="description_of_asset_sold"
                                        variant="outlined"
                                        placeholder="Enter"
                                        fullWidth
                                        name="description_of_asset_sold"
                                        value={this.state.landBuilding.description_of_asset_sold}
                                        onChange={this.handleSetLandBuildingFields}
                                    />
                                    {handleReturnErrorMessage(this.state.landBuildingError.description_of_asset_sold)}
                                </Box>
                                <Box className="LB_calander_grp mt-30">
                                    <Box>
                                        <Typography className="font_style-1 mb-10" >Date of Sale <span className="star">*</span></Typography>
                                        <CustomCalander
                                            data-testid="date_of_sale"
                                            calanderIcon={calanderIcon}
                                            name="date_of_sale"
                                            handleGetDate={this.handleGetDate}
                                            maxDate={Date.now()}
                                        />
                                        {handleReturnErrorMessage(this.state.landBuildingError.date_of_sale)}
                                    </Box>
                                    <Box>
                                        <Typography className="font_style-1 mb-10">Date of Purchase</Typography>
                                        <CustomCalander
                                            data-testid="date_of_purchase"
                                            calanderIcon={calanderIcon}
                                            name="date_of_purchase"
                                            handleGetDate={this.handleGetDate}
                                            maxDate={Date.now()}
                                        />
                                        {handleReturnErrorMessage(this.state.landBuildingError.date_of_purchase)}
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="add_item">
                                <img src={tooltip} className="tooltip_icon" alt="tooltip" />
                                <Typography className="LB_example--text">Example: Sale of Shares or Sale of Property</Typography>
                            </Box>
                        </Card>
                        <Button
                            data-testid="land_building"
                            className="btn btn_active mt-50"
                            variant="outlined"
                            onClick={this.submitLandBuildingForm}
                        >
                            Next
                        </Button>
                    </>
                    }
                    {this.state.landOrBuildingPage === 2 && <>
                        <Box className="LB_stepper--container">
                            <Box data-testid="back_stepper" className="left_arrow" onClick={this.goToPrevStep}>
                                <img src={LeftArrow} className="back_btn-stepper" />
                            </Box>
                            <StepperContainer className="w-700">
                                <Stepper nonLinear activeStep={this.state.activeStep} alternativeLabel>
                                    {steps.map((label, index) => {
                                        const stepProps: { completed?: boolean } = {};
                                        const labelProps: {
                                            optional?: React.ReactNode;
                                        } = {}
                                        return (
                                            <Step key={label} {...stepProps}>
                                                <StepLabel className={(index < this.state.activeStep) ? "steplabel" as any : null} {...labelProps}>{label}</StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </StepperContainer>
                        </Box>
                        {this.state.activeStep === 0 && <Box>
                            <Card className="card p-20-35 mt-50">
                                <Box className="date_grp">
                                    <Box>
                                        <Typography className="font_style-1 m-remove" style={{ marginTop: "45px" }}>Date of Sale <span className="star">*</span></Typography>
                                        <Typography className="font_style-2">{this.getFormatedDate(this.state.landBuilding.date_of_sale)}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography className="font_style-1 m-remove" style={{ marginTop: "45px" }}>Date of Purchase <span className="star">*</span></Typography>
                                        <Typography className="font_style-2">{this.getFormatedDate(this.state.landBuilding.date_of_purchase)}</Typography>
                                    </Box>
                                </Box>
                            </Card>
                            <Card className="card mt-20">
                                <Typography className="LB_body_title-text mb-30">Add Details of the asset</Typography>
                                <Grid container className="grid_container">
                                    <Grid className="align_flex" item lg={5} sm={12} md={5}>
                                        <Typography className="font_style-1 font_gray--dark">Description of Asset Sold <span className="star">*</span></Typography>
                                    </Grid>
                                    <Grid item lg={7} sm={12} md={7}>
                                        <Grid container spacing={2}>
                                            <Grid item lg={10} md={12} className="error_container">
                                                <CustomTextfield
                                                    data-testid="description_of_asset_sold1"
                                                    variant="outlined"
                                                    placeholder="Enter"
                                                    name="description_of_asset_sold1"
                                                    value={this.state.landBuilding.description_of_asset_sold1}
                                                    fullWidth
                                                    onChange={this.handleSetLandBuildingFields}
                                                />
                                                {handleReturnErrorMessage(this.state.stepOneError.description_of_asset_sold1)}
                                                <Box className="add_item mt-10">
                                                    <img src={tooltip} className="tooltip_icon" alt="tooltip" />
                                                    <Typography className="LB_example--text">Example: Plot at Delhi or House at Mumbai</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <ReturnGrid
                                    data-testid="total_sale_price"
                                    title="Total Sale Price"
                                    errormessage={this.state.stepOneError.total_sale_price}
                                    readOnly={false}
                                    name="total_sale_price"
                                    value={this.state.landBuilding.total_sale_price}
                                    handleSetValue={this.handleSetLandBuildingFields}
                                    startIcon={true}
                                    required={true}
                                />
                                <ReturnGrid
                                    data-testid="total_purchase_price"
                                    title="Total Purchase Price"
                                    errormessage={this.state.stepOneError.total_purchase_price}
                                    readOnly={false}
                                    name="total_purchase_price"
                                    value={this.state.landBuilding.total_purchase_price}
                                    handleSetValue={this.handleSetLandBuildingFields}
                                    startIcon={true}
                                    required={true}
                                />
                                <ReturnGrid
                                    data-testid="transfer_expenses"
                                    title="Transfer Expenses"
                                    errormessage={this.state.stepOneError.transfer_expenses}
                                    readOnly={false}
                                    name="transfer_expenses"
                                    value={this.state.landBuilding.transfer_expenses}
                                    handleSetValue={this.handleSetLandBuildingFields}
                                    showTooltip={[true, "Example: Brokerage fees on sale of property"]}
                                />
                                <ReturnGrid
                                    data-testid="value_of_property_stamp_duty_paid"
                                    title="Value of Property on which stamp duty paid (in Rs.)"
                                    errormessage={this.state.stepOneError.value_of_property_stamp_duty_paid}
                                    readOnly={false}
                                    name="value_of_property_stamp_duty_paid"
                                    value={this.state.landBuilding.value_of_property_stamp_duty_paid}
                                    handleSetValue={this.handleSetLandBuildingFields}
                                    showTooltip={[true, "When property is sold, Stamp duty is paid on the value of property. Please mention the value of property on which you paid stamp duty. Typically this amount is the same as Sale price"]}
                                />
                            </Card>
                            <Card className="card mt-20">
                                <Box className="improve_detail--container">
                                    <Box>
                                        <Typography className="LB_body_title-text fs-20">Do you want to add cost of improvement details?</Typography>
                                        <Typography className="font_style-1 fs-16">Expenses incurred for increasing the value of the property</Typography>
                                    </Box>
                                    <SwitchContainer>
                                        <ResimeSwitch
                                            data-testid="toggleCostInprovement"
                                            onChange={this.toggleCostInprovement}
                                            value={this.state.showCostImprovement}
                                            checked={this.state.showCostImprovement}
                                        />
                                    </SwitchContainer>
                                </Box>
                                {this.state.showCostImprovement && <Box>
                                    {this.state.costImprovementDetailsAttributes.map((data: any, index: any) => {
                                        return <Box key={index} className="mb-20">
                                            <DescriptionAmount
                                                data-testid="description_amount1"
                                                data={data}
                                                handleDeleteCostImprovement={this.handleDeleteCostImprovement}
                                                handleSetCostImprovement={this.handleSetCostImprovement}
                                            />
                                        </Box>
                                    })}
                                    <AddMoreItem
                                        data-testid="handleAddCostImprovement1"
                                        handleAddMoreItem={this.handleAddCostImprovementDetail}
                                    />
                                </Box>}
                            </Card>
                        </Box>}
                        {this.state.activeStep === 1 && <Card className="card mt-50">
                            <Typography className="LB_body_title-text mb-30">Property Address</Typography>
                            <Grid container className="grid_container">
                                <Grid className="align_flex" item lg={5} sm={12} md={5}>
                                    <Typography className="font_style-1">Pincode</Typography>
                                </Grid>
                                <Grid className="align_flex" item lg={6} sm={12} md={6}>
                                    <Box className="wp-100">
                                        <CustomTextfield
                                            data-testid="test_pan"
                                            variant="outlined"
                                            placeholder="Enter"
                                            fullWidth
                                            name="pincode"
                                            value={this.state.landBuilding.pincode}
                                            onChange={this.handleSetLandBuildingFields}
                                        />
                                        {handleReturnErrorMessage(this.state.stepTwoError.pincode)}
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container className="grid_container">
                                <Grid className="align_flex" item lg={5} sm={12} md={5}>
                                    <Typography className="font_style-1">Town/City</Typography>
                                </Grid>
                                <Grid className="align_flex" item lg={6} sm={12} md={6}>
                                    <CustomTextfield
                                        variant="outlined"
                                        placeholder="Enter"
                                        fullWidth
                                        name="city"
                                        value={this.state.landBuilding.city}
                                        onChange={this.handleSetLandBuildingFields}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className="grid_container">
                                <Grid className="align_flex" item lg={5} sm={12} md={5}>
                                    <Typography className="font_style-1">Address Line</Typography>
                                </Grid>
                                <Grid className="align_flex" item lg={6} sm={12} md={6}>
                                    <CustomTextfield
                                        variant="outlined"
                                        placeholder="Enter"
                                        fullWidth
                                        name="address_line"
                                        value={this.state.landBuilding.address_line}
                                        onChange={this.handleSetLandBuildingFields}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className="grid_container">
                                <Grid className="align_flex" item lg={5} sm={12} md={5}>
                                    <Typography className="font_style-1">State</Typography>
                                </Grid>
                                <Grid item lg={6} sm={12} md={6}>
                                    <RenderDropdown
                                        data-testid="deduction_caimed_year"
                                        name="state"
                                        handleGetValue={this.handleSetState}
                                        list={this.handleGetStateList()}
                                        value={this.state.landBuilding.state}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className="grid_container">
                                <Grid className="align_flex" item lg={5} sm={12} md={5}>
                                    <Typography className="font_style-1">Country</Typography>
                                </Grid>
                                <Grid item lg={6} sm={12} md={6}>
                                    <RenderDropdown
                                        data-testid="deduction_caimed_year"
                                        name="country"
                                        handleGetValue={this.handleSetState}
                                        list={["India"]}
                                        value={this.state.landBuilding.country}
                                    />
                                </Grid>
                            </Grid>
                        </Card>
                        }
                        {this.state.activeStep === 2 && <Card className="card mt-50">
                            <Typography className="LB_body_title-text mb-30">Buyer Details</Typography>
                            {this.state.buyerDetails.map((data: any, index: any) => {
                                return (
                                    <Box className={properyDetail} key={index}>
                                        <Box className="mw-700">
                                            <Grid container spacing={2} className="mb-20">
                                                <Grid item lg={8} sm={8} md={8}>
                                                    <Box>
                                                        <Typography className="font_style-1 mb-10">Buyer Name</Typography>
                                                        <CustomTextfield
                                                            data-testid="buyer_name"
                                                            variant="outlined"
                                                            placeholder="Enter"
                                                            fullWidth
                                                            name="buyer_name"
                                                            value={data.buyer_name.value}
                                                            onChange={(e: any) => this.handleSetPropertyDetail(e, data.id)}
                                                        />
                                                        {handleReturnErrorMessage(this.state.landBuildingError.description_of_asset_sold)}
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={4} sm={4} md={4}>
                                                    <Box>
                                                        <Typography className="font_style-1 mb-10">Ownership Percentage</Typography>
                                                        <Box className="textfield_box">
                                                            <Box className="ruppee">
                                                                <span>%</span>
                                                            </Box>
                                                            <CustomTextfield
                                                                data-testid="ownership_percentage"
                                                                variant="outlined"
                                                                placeholder="Enter"
                                                                name="ownership_percentage"
                                                                value={data.ownership_percentage.value}
                                                                onChange={(e: any) => this.handleSetPropertyDetail(e, data.id)}
                                                            />
                                                        </Box>
                                                        {handleReturnErrorMessage(data.ownership_percentage.error)}
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item lg={4} sm={4} md={4}>
                                                    <Box>
                                                        <Typography className="font_style-1 mb-10">Aadhaar <span className="star">*</span></Typography>
                                                        <CustomTextfield
                                                            data-testid="aadhar"
                                                            variant="outlined"
                                                            placeholder="Enter"
                                                            fullWidth
                                                            name="aadhar"
                                                            value={data.aadhar.value}
                                                            onChange={(e: any) => this.handleSetPropertyDetail(e, data.id)}
                                                        />
                                                        {handleReturnErrorMessage(data.aadhar.error)}

                                                    </Box>
                                                </Grid>
                                                <Grid item lg={4} sm={4} md={4}>
                                                    <Box>
                                                        <Typography className="font_style-1 mb-10">PAN <span className="star">*</span></Typography>
                                                        <CustomTextfield
                                                            data-testid="pan"
                                                            variant="outlined"
                                                            placeholder="Enter"
                                                            fullWidth
                                                            name="pan"
                                                            value={data.pan.value}
                                                            onChange={(e: any) => this.handleSetPropertyDetail(e, data.id)}
                                                        />
                                                        {handleReturnErrorMessage(data.pan.error)}

                                                    </Box>
                                                </Grid>
                                                <Grid item lg={4} sm={4} md={4}>
                                                    <Box>
                                                        <Typography className="font_style-1 mb-10">Amount Paid <span className="star">*</span></Typography>
                                                        <Box className="textfield_box">
                                                            <Box className="ruppee">
                                                                <span>₹</span>
                                                            </Box>
                                                            <CustomTextfield
                                                                data-testid="amount_paid"
                                                                variant="outlined"
                                                                placeholder="Enter"
                                                                name="amount_paid"
                                                                value={data.amount_paid.value}
                                                                onChange={(e: any) => this.handleSetPropertyDetail(e, data.id)}
                                                            />
                                                        </Box>
                                                        {handleReturnErrorMessage(data.amount_paid.error)}

                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <img data-testid="delete_property_address" src={DeleteIcon} className=" delete_icon ml-50" onClick={() => this.handleDeletePropertyAddress(data.id, data.objID)} />
                                    </Box>
                                )
                            })
                            }
                            <AddMoreItem
                                handleAddMoreItem={this.handleAddPropertyAddress}
                            />
                        </Card>}
                        <Button
                            data-testid="stepper_test"
                            className="btn btn_active mt-50"
                            variant="outlined"
                            onClick={this.handleGoToNextStep}
                        >
                            Next
                        </Button>
                    </>
                    }
                    {this.state.landOrBuildingPage === 3 && <Box>
                        <Heading
                            data-testid="table_back_btn"
                            value="Add Sale of Land or Building"
                            handleGoToBack={this.backToStepper}
                        />
                        <Card className="card mt-50">
                            <Box className="LB_search mb-20">
                                <CustomTextArea variant="outlined" fullWidth placeholder="Search" />
                                <img src={search} alt="" className="LB_search-icon" />
                            </Box>
                            <TableContainer>
                                <table className="w-1250">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Property Name</th>
                                            <th>Buyer Name</th>
                                            <th>Sales Consideration</th>
                                            <th>Capital Gains</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.landBuildingTable && this.state.landBuildingTable.map((data: any, index: any) => {
                                            return <tr>
                                                <td>{index > 8 ? index + 1 : `0${index + 1}`}</td>
                                                <td>{data.attributes.description_of_asset_sold}</td>
                                                <td>Dummy name</td>
                                                <td>₹ 0</td>
                                                <td>₹ 0</td>
                                                <td>
                                                    <Box className="action_button_grp">
                                                        <img data-testid="edit_icon" className="action_edit_icon" src={edit} alt="edit_icon" onClick={() => { this.handleGetLandBuildingDataByID(data.id) }} />
                                                        <img data-testid="delete_test" className="action_delete_icon" src={DeleteRedIcon} alt="delete_icom" onClick={() => this.handleDeleteLandBuildingRow(data.id)} />
                                                    </Box>
                                                </td>
                                            </tr>
                                        })
                                        }
                                    </tbody>
                                </table>
                            </TableContainer>
                            <AddMoreItem
                                handleAddMoreItem={this.handleGoToAddPropertyDetailForm}
                            />
                        </Card>
                        <Box className="btn_grp gap-50 mt-50">
                            <Button
                                data-testid="save_form"
                                className="btn btn_active m-remove"
                                variant="outlined"
                            >
                                Save
                            </Button>
                            <Button
                                data-testid="exemption_btn"
                                className="btn-outlined"
                                variant="outlined"
                                onClick={this.handleOpenAddExemptionForm}
                            >
                                Exemption on Capital Gains (on certain investments)
                            </Button>
                        </Box>
                    </Box>
                    }
                    {this.state.landOrBuildingPage === 4 && <Box>
                        <Heading
                            data-testid="capital_gain_exemption"
                            value="Capital Gain Exemptions"
                            handleGoToBack={this.handleGoToLandAndBuildingTablePage}
                        />
                        <Card className="card mt-50">
                            <Box className="LB_CGE_container">
                                <Grid container>
                                    <Grid item lg={6} sm={6} md={6}>
                                        <Typography className="font_style-1">Please select the asset again which you want to use the capital gains exemption</Typography>
                                    </Grid>
                                    <Grid item lg={6} sm={6} md={6}>
                                        <RenderDropdown />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className="LB_CGE_container">
                                <Grid container>
                                    <Grid item lg={6} sm={6} md={6}>
                                        <Typography className="font_style-1">Please select the asset again which you want to use the capital gains exemption</Typography>
                                    </Grid>
                                    <Grid item lg={6} sm={6} md={6}>
                                        <RenderDropdown />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box>
                                <CustomAccordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className="font_style-1">Lorem ipsum has been</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Card className="card wp-100">
                                            <Grid container className="grid_container">
                                                <Grid className="align_flex" item lg={5} sm={12} md={5}>
                                                    <Typography className="font_style-1 font_bold">Date of Transfer of Original Asset</Typography>
                                                </Grid>
                                                <Grid item lg={7} sm={12} md={7}>
                                                    <CustomCalander
                                                        calanderIcon={calanderIcon}
                                                        name="cost_of_acquisition_date"
                                                        handleGetDate={this.handleGetDate}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <ReturnGrid
                                                data-testid="amounts_payable"
                                                title="Cost ofNew Asset/Amount invested in Agricultural Land"
                                                errormessage={""}
                                                readOnly={false}
                                                name=""
                                                value={""}
                                                handleSetValue={this.handleSetLandBuildingFields}
                                                bold={true}
                                            />

                                            <Grid container className="grid_container">
                                                <Grid className="align_flex" item lg={5} sm={12} md={5}>
                                                    <Typography className="font_style-1 font_bold">Date of Purchase/Construction of New Agricultural Land</Typography>
                                                </Grid>
                                                <Grid item lg={7} sm={12} md={7}>
                                                    <CustomCalander
                                                        calanderIcon={calanderIcon}
                                                        name="cost_of_acquisition_date"
                                                        handleGetDate={this.handleGetDate}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <ReturnGrid
                                                data-testid="amounts_payable"
                                                title="Amount deposited in Capital Gains Scheme Account"
                                                errormessage={""}
                                                readOnly={false}
                                                name=""
                                                value={""}
                                                handleSetValue={this.handleSetLandBuildingFields}
                                                bold={true}
                                            />

                                            <ReturnGrid
                                                data-testid="amounts_payable"
                                                title="Amount of Deduction Claimed (auto calculated)"
                                                errormessage={""}
                                                readOnly={false}
                                                name=""
                                                value={""}
                                                handleSetValue={this.handleSetLandBuildingFields}
                                                bold={true}
                                            />
                                        </Card>
                                    </AccordionDetails>
                                </CustomAccordion>
                            </Box>
                            <Button
                                data-testid="save_form"
                                className="btn btn_active mt-50"
                                variant="outlined"
                            >
                                Save
                            </Button>
                        </Card>
                        <Button startIcon={<AddIcon />} className="add_more_asset mt-50">Add more asset</Button>
                    </Box>
                    }
                </Box>
            </MainWrapper >
        );
    }
}

const MainWrapper = styled(Container)({
    width: "100%",
    "& .grid_container": {
        width: "90%",
        marginBottom: "35px"
    },

    "& .align_flex": {
        display: "flex",
        alignItems: "center",
        marginBottom: "0px"
    },
    "& .font_style-1": {
        color: "rgba(54, 64, 59, 0.70)",
        fontFamily: 'Nunito',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 600,
        paddingRight: "10px"
    },
    "& .font_style-2": {
        color: "rgba(54, 64, 59, 0.79)",
        fontFamily: 'Nunito',
        fontSize: '20px',
        fontWeight: 600,
        paddingRight: "10px"
    },
    "& .star": {
        color: "#C46F60",
        fontFamily: "Nunito",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600,
    },
    "& .card": {
        padding: "35px",
        borderRadius: "16px",
        overflow: "visible"
    },
    "& .btn": {
        width: "200px",
        height: "50px",
        borderRadius: "10px",
        fontFamily: "Nunito",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600,
        color: "#858585",
    },
    "& .btn_active": {
        backgroundColor: "#337152",
        color: "#ffffff !important"
    },
    "& .LB_main": {
        borderRadius: "16px",
        backgroundColor: "#F4F6F5",
        padding: "30px",
    },
    "& .LB_main-title": {
        display: "flex",
        "&>img": {
            marginRight: "30px",
            cursor: "pointer"
        }
    },
    "& .LB_body_title-text": {
        color: "#36403B",
        fontFamily: "Nunito",
        fontSize: "27px",
        fontStyle: "normal",
        fontWeight: 700,
    },
    "& .LB_sub_title-text": {
        color: "#A0A6A4",
        fontFamily: "Nunito",
        fontSize: "18px",
        fontStyle: "normal",
        paddingLeft: "50px",
        marginTop: "5px",
        marginBottom: "30px",
        fontWeight: 500
    },
    "& .AMS_security_name-container": {
        maxWidth: "310px"
    },
    "& .LB_Property_detail": {
        display: "flex",
        justifyContent: "space-between",
        gap: "20px"
    },
    "& .LB_calander_grp": {
        display: "flex",
        gap: "20px"
    },
    "& .LB_property_detail--name": {
        maxWidth: "400px",
        width: "400px"
    },
    "& .add_item": {
        display: "flex",
        alignItems: "center",
        marginTop: "20px"
    },
    "& .tooltip_icon": {
        marginRight: "12px",
        width: "20px",
        cursor: "pointer"
    },
    "& .LB_example--text": {
        color: "#D1D1D1",
        fontSize: "18px",
        fontStyle: "normal",
        fontFamily: "Nunito",
        fontWeight: 600
    },
    "& .LB_stepper--container": {
        display: "flex"
    },
    "& .MuiStepper-root": {
        padding: 0
    },
    "& .w-700": {
        width: "700px"
    },
    "& .mw-700": {
        maxWidth: "700px"
    },
    "& .back_btn-stepper": {
        marginTop: "9px"
    },
    "& .date_grp": {
        display: "flex",
        gap: "100px"
    },
    "& .m-remove": {
        margin: "0 !important"
    },
    "& .p-20-35": {
        padding: "20px 35px !important"
    },
    "& .mt-50": {
        marginTop: "50px !important"
    },
    "& .mt-30": {
        marginTop: "30px !important"
    },
    "& .mt-20": {
        marginTop: "20px !important"
    },
    "& .mt-10": {
        marginTop: "10px !important"
    },
    "& .fs-20": {
        fontSize: "20px !important"
    },
    "& .fs-16": {
        fontSize: "16px !important"
    },
    "& .mb-10": {
        marginBottom: "10px"
    },
    "& .mb-20": {
        marginBottom: "10px"
    },
    "& .mb-30": {
        marginBottom: "30px"
    },
    "& .ml-50": {
        marginLeft: "50px"
    },
    "& .w-1250": {
        width: "1250px !important"
    },
    "& .wp-100": {
        width: "100% !important"
    },
    "& .mb-50": {
        marginBottom: "50px !important"
    },
    "& .improve_detail--container": {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "50px"
    },
    "& .add_more_item": {
        display: "flex",
        alignItems: "center",
        marginTop: "30px"
    },
    "& .add_icon": {
        width: "24px",
        height: "24px",
        color: '#337152'
    },


    "& .LB_autocomplete_box": {
        position: "relative"
    },
    "& .LB_dropdown_img": {
        position: "absolute",
        right: "15px",
        bottom: "20px",
        zIndex: 1
    },
    "& .textfield_box": {
        display: "flex",
        position: "relative"
    },
    "& .ruppee": {
        width: "54px",
        height: "48px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "8px 0px 0px 8px",
        backgroundColor: "#DEF5EA",
        zIndex: 1,
        marginRight: "-6px",
        flexShrink: 0
    },
    "& .PA_body": {
        display: "flex"
    },
    "& .delete_icon": {
        cursor: "pointer"
    },
    "& .action_button_grp": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    "& .action_edit_icon": {
        width: "30px",
        cursor: "pointer"
    },
    "& .action_delete_icon": {
        width: "26px",
        cursor: "pointer"
    },
    "& .LB_search": {
        maxWidth: "552px",
        position: "relative"
    },
    "& .LB_search-icon": {
        width: "25px",
        height: "25px",
        position: "absolute",
        top: "30%",
        right: "3.5%"
    },
    "& .btn-outlined": {
        height: "50px",
        color: "#337152",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontWeight: 600,
        borderRadius: "10px",
        border: "1px solid"
    },
    "& .btn_grp": {
        display: "flex",
        alignItems: "center"
    },
    "& .gap-50": {
        gap: "50px"
    },
    "& .left_arrow": {
        cursor: "pointer"
    },
    "& .LB_CGE_container": {
        backgroundColor: "#F4F6F5",
        padding: "30px",
        borderRadius: "15px",
        marginBottom: "30px"
    },
    "& .MuiAutocomplete-inputRoot": {
        backgroundColor: "#ffffff"
    },
    "& .font_bold": {
        color: "rgb(54, 64, 59) !important"
    },
    "& .add_more_asset": {
        width: "171px",
        height: "46px",
        borderRadius: "8px",
        backgroundColor: "#FFF5DC",
        color: "#C7AA61",
        fontSize: "16px",
        fontStyle: "normal",
        fontFamily: "Nunito",
        fontWeight: 600
    },
    "& .error_message": {
        color: "red",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontWeight: 600,
        marginTop: "5px",
        marginBottom: "-10px",
        marginLeft: "5px",
    },
    "& .font_gray--dark": {
        color: "#737975 !important",
    }
});