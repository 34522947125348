import React, { ChangeEvent, ReactNode } from "react";

import {
    Container,
    Box,
    Button,
    Typography,
    TextField,
    Select,
    MenuItem,
    FormControl,
    FormHelperText,
    Snackbar,
    Theme
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Alert from '@material-ui/lab/Alert';
import { withStyles } from "@material-ui/core/styles";
import DashboardPanselector from "../../dashboard/src/DashboardPanselector.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Customizable Area End

import DonationsCharitableOrganizationController, {
    Props,
    configJSON,
} from "./DonationsCharitableOrganizationController.web";

export class DonationsCharitableOrganization extends DonationsCharitableOrganizationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderCostumTextfiledforDonations = (name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined) => {
        const { classes } = this.props;
        return (
            <Box className={classes.amount}>
                <Box className={classes.ruppeslogo}>
                    <Typography className={classes.rupeessstyles}>₹</Typography>
                </Box>
                <TextField
                    className={classes.textfiled}
                    error={error}
                    variant="outlined"
                    placeholder={configJSON.passplaceHolder}
                    name={name}
                    type={type}
                    value={value}
                    onChange={onChange}
                    data-test-id={name}
                    helperText={error && configJSON.requriedfield}
                    onBlur={this.handleBlurMultipage}
                    onFocus={this.handleOnFocus.bind(this, name)}
                    FormHelperTextProps={{
                        style: { margin: '0' },
                    }}

                />
            </Box>
        )
    }

    renderSelectInputDonations80G = (stylesprop: string, name: string, value: string, onChange: ((event: React.ChangeEvent<{
        name?: string;
        value: unknown;
    }>, child: React.ReactNode) => void), error: boolean | undefined) => {
        const { classes } = this.props;

        return (
            <Box className={stylesprop}>
                <FormControl fullWidth variant="outlined" error={error}>
                    <Select
                        fullWidth
                        IconComponent={ExpandMoreIcon}
                        placeholder="select"
                        variant="outlined"
                        value={value}
                        name={name}
                        onFocus={this.handleOnFocus.bind(this, name)}
                        error={error}
                        onChange={onChange}
                        data-test-id={name}
                        
                        


                    >
                        <MenuItem className={classes.menuStyles} value="choose"> <span className={classes.selectColor}>Select</span></MenuItem>

                        <MenuItem value={10}>{configJSON.Common80GGA2aOrganizations}</MenuItem>
                        <MenuItem value={20}>{configJSON.Common80GGA2aaOrganizations}</MenuItem>
                        <MenuItem value={30}>{configJSON.Common80GGA2bOrganizations}</MenuItem>
                        <MenuItem value={30}>{configJSON.Common80GGA2bbOrganizations}</MenuItem>
                        <MenuItem value={30}>{configJSON.Common80GGA2cOrganizations}</MenuItem>
                        <MenuItem value={30}>{configJSON.Common80GGA2ccOrganizations}</MenuItem>
                        <MenuItem value={30}>{configJSON.Common80GGA2dOrganizations}</MenuItem>
                        <MenuItem value={30}>{configJSON.Common80GGA2eOrganizations}</MenuItem>
                    </Select>

                    <FormHelperText className={classes.formMargin}>{error && configJSON.requriedfield}</FormHelperText>
                    <Box style={{width: '500px', maxWidth: '100%'}}>
                        {<Typography variant="caption" className={classes.helpertext}>
                            <HelpOutlineIcon />{configJSON.Common80GOrganizationsMessage}
                        </Typography>}
                    </Box>

                </FormControl >
            </Box>

        )
    }

    renderSelectInputDonations = (stylesprop: string, name: string, value: string, onChange: ((event: React.ChangeEvent<{
        name?: string;
        value: unknown;
    }>, child: React.ReactNode) => void), error: boolean | undefined) => {
        const { classes, type } = this.props;

        return (
            <Box className={stylesprop}>
                <FormControl fullWidth variant="outlined" error={error}>
                    <Select
                        fullWidth
                        variant="outlined"
                        placeholder="select"
                        error={error}
                        name={name}
                        value={value}
                        onChange={onChange}
                        data-test-id={name}
                        onFocus={this.handleOnFocus.bind(this, name)}
                        IconComponent={ExpandMoreIcon}


                    >
                        <MenuItem className={classes.menuStyles} value="choose"> <span className={classes.selectColor}>Select</span></MenuItem>

                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select>

                    <FormHelperText className={classes.formMargin}>{error && configJSON.requriedfield}</FormHelperText>
                    <Box>
                        {type !== "section80g" && name !== "StateDistrict" && <Typography variant="caption" className={classes.helpertext}>
                            <HelpOutlineIcon />{configJSON.helpertext}
                        </Typography>}
                    </Box>

                </FormControl >
            </Box>

        )
    }
    renderStandardInput = (name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined) => {
        const { classes } = this.props;
        return (
            <>
                <TextField
                    className={classes.selectdropDown}
                    variant="outlined"
                    error={error}
                    placeholder={configJSON.emptyPlaceholder}
                    name={name}
                    type={type}
                    value={value}
                    onChange={onChange}
                    data-test-id={name}
                    helperText={error && configJSON.requriedfield}
                    onFocus={this.handleOnFocus.bind(this, name)}
                    onBlur={this.handleBlurMultipage}
                    FormHelperTextProps={{
                        style: { margin: '0' },
                    }}

                />
                <Box>
                    {name === "PANofDonee" && <Typography variant="caption" className={classes.underhovertext} >
                        {configJSON.helpertextpan}
                    </Typography>}
                </Box>
            </>
        )
    }
    renderCommonTextField = (commonAttributes:
        {
            heading: string,
            selectActive: boolean,
            selectValues: {
                name: string, value: string, onChange: ((event: React.ChangeEvent<{
                    name?: string;
                    value: unknown;
                }>, child: React.ReactNode) => void), error: boolean | undefined
            },
            amountActive: boolean,
            amountValues: { name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined },
            textInputValues: { name: string, value: string, type: string, onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined, error: boolean | undefined }
            stateCity: boolean,
            commonOrganization:boolean,
            commonOrganizationValues:{
                name: string, value: string, onChange: ((event: React.ChangeEvent<{
                    name?: string;
                    value?: unknown;
                }>, child: React.ReactNode) => void), error: boolean | undefined
            },
            stateCityValues: {
                name: string, value: string, onChange: ((event: React.ChangeEvent<{
                    name?: string;
                    value?: unknown;
                }>, child: React.ReactNode) => void), error: boolean | undefined
            }
        }) => {
        const { classes } = this.props;
        const { selectValues, amountValues, textInputValues, stateCityValues,commonOrganizationValues } = commonAttributes
        let rendertxt;
        if (commonAttributes.selectActive) {
            rendertxt = this.renderSelectInputDonations(classes.selectdropDown, selectValues.name, selectValues.value, selectValues.onChange, selectValues.error)
        } else if (commonAttributes.amountActive) {
            rendertxt = this.renderCostumTextfiledforDonations(amountValues.name, amountValues.value, amountValues.type, amountValues.onChange, amountValues.error)
        } else if (commonAttributes.stateCity) {
            rendertxt = (
                <Box className={classes.citystate}>
                    <Box className={classes.townCityWrapper}>
                        <span className={classes.inputlableStyle}>{configJSON.TownCity}</span>
                        {this.renderStandardInput(textInputValues.name, textInputValues.value, textInputValues.type, textInputValues.onChange, textInputValues.error)}
                    </Box>
                    <Box className={classes.cityValuesWrapper}>
                        <Typography variant="subtitle2" className={classes.inputlableStyle}>{configJSON.StateSelect}</Typography>
                        {this.renderSelectInputDonations(classes.selectOptionDrop, stateCityValues.name, stateCityValues.value, stateCityValues.onChange, stateCityValues.error)}

                    </Box>
                </Box>
            )
        }
        else if(commonAttributes.commonOrganization){
            rendertxt =  this.renderSelectInputDonations80G(classes.selectOptionDrop, commonOrganizationValues.name, commonOrganizationValues.value, commonOrganizationValues.onChange, commonOrganizationValues.error)
        }
        else {
            rendertxt = this.renderStandardInput(textInputValues.name, textInputValues.value, textInputValues.type, textInputValues.onChange, textInputValues.error)
        }
        const marginTopMap: {
            [x: string]: string;
        } = {
            [configJSON.Common80GOrganizations]: "-4rem",
            [configJSON.CityStateCountry]: "1.5rem",

        };
        const customStyle: React.CSSProperties = {
            marginTop: marginTopMap[commonAttributes?.heading] || "0px",
        };
        const isRequired = (textInputValues.name === "AddressLine" || amountValues.name === "DonationAmountCash" || textInputValues.name === "PINCode" || textInputValues.name === "TownCity" || textInputValues.name === "StateDistrict") || null
        return (
            <Box>
                <Box className={classes.textAlignment}>
                    <Box style={customStyle} className={classes.boxwidth}>
                        <Typography className={classes.labelheading}>{commonAttributes.heading} {isRequired ? <span className={classes.star}>*</span> : null}</Typography>
                    </Box>
                    <Box>
                        {rendertxt}
                    </Box>
                </Box>
            </Box>

        )
    }

    rendercommonComponetDeductions = () => {
        const { classes } = this.props;
        const { type } = this.props;

        const {
            NameoftheDonee,
            DonationAmountCash,
            DonationAmountNonCash,
            PANofDonee,
            AddressLine,
            PINCode,
            TownCity,
            StateDistrict,
            CommonOrganizations
        } = this.state.DonationsCharitableOrganizations

        return (
            <>
                <Box className={classes.headingwrapeer}>
                    <Typography className={classes.subheadingdonations}>{configJSON.DonationDetails}</Typography>
                </Box>
                {this.renderCommonTextField({
                    heading: configJSON.Common80GOrganizations,
                    selectActive: false,
                    selectValues: { name: configJSON.emptyString, value: configJSON.emptyString, onChange: configJSON.emptyString, error: false },
                    amountActive: false,
                    amountValues: { name: configJSON.emptyString, value: configJSON.emptyString, type: configJSON.emptyString, onChange: undefined, error: false },
                    textInputValues: { name: "Common 80G Organizations", value: CommonOrganizations, type: "text", onChange:this.handleonChangeDropdown, error: false },
                    stateCity: false,
                    commonOrganization:true,
                    commonOrganizationValues:{ name: "CommonOrganizations", value: CommonOrganizations, onChange: this.handleonChangeDropdown as (event: ChangeEvent<{ name?: string; value?: unknown; }>, child: ReactNode) => void, error: this.state.errors["CommonOrganizations"] },
                    stateCityValues: { name: configJSON.emptyString, value: configJSON.emptyString, onChange: configJSON.emptyString, error: false }
                })}


                {this.renderCommonTextField({
                    heading: configJSON.NameoftheDonee,
                    selectActive: false,
                    selectValues: { name: configJSON.emptyString, value: configJSON.emptyString, onChange: configJSON.emptyString, error: false },
                    amountActive: false,
                    amountValues: { name: configJSON.emptyString, value: configJSON.emptyString, type: configJSON.emptyString, onChange: undefined, error: false },
                    textInputValues: { name: "NameoftheDonee", value: NameoftheDonee, type: "text", onChange: this.handleonChangeTextData, error: false },
                    stateCity: false,
                    stateCityValues: { name: configJSON.emptyString, value: configJSON.emptyString, onChange: configJSON.emptyString, error: false },
                    commonOrganization:false,
                    commonOrganizationValues:{ name: configJSON.emptyString, value: configJSON.emptyString, onChange: configJSON.emptyString, error: false },

                })}
                {this.renderCommonTextField({
                    heading: configJSON.DonationAmountCash,
                    selectActive: false,
                    selectValues: { name: configJSON.emptyString, value: configJSON.emptyString, onChange: configJSON.emptyString, error: false },
                    amountActive: true,
                    amountValues: { name: "DonationAmountCash", value: DonationAmountCash, type: "text", onChange: this.handleonChangeTextNumber, error: this.state.errors["DonationAmountCash"] },
                    textInputValues: { name: configJSON.emptyString, value: configJSON.emptyString, type: configJSON.emptyString, onChange: undefined, error: false },
                    stateCity: false,
                    stateCityValues: { name: configJSON.emptyString, value: configJSON.emptyString, onChange: configJSON.emptyString, error: false },
                    commonOrganization:false,
                    commonOrganizationValues:{ name: configJSON.emptyString, value: configJSON.emptyString, onChange: configJSON.emptyString, error: false },

                })}
                {this.renderCommonTextField({
                    heading: configJSON.DonationAmountNonCash,
                    selectActive: false,
                    selectValues: { name: configJSON.emptyString, value: configJSON.emptyString, onChange: configJSON.emptyString, error: false },
                    amountActive: true,
                    amountValues: { name: "DonationAmountNonCash", value: DonationAmountNonCash, type: "text", onChange: this.handleonChangeTextNumber, error: false },
                    textInputValues: { name: configJSON.emptyString, value: configJSON.emptyString, type: configJSON.emptyString, onChange: undefined, error: false },
                    stateCity: false,
                    stateCityValues: { name: configJSON.emptyString, value: configJSON.emptyString, onChange: configJSON.emptyString, error: false },
                    commonOrganization:false,
                    commonOrganizationValues:{ name: configJSON.emptyString, value: configJSON.emptyString, onChange: configJSON.emptyString, error: false },

                })}
                {this.renderCommonTextField({
                    heading: configJSON.PANofDonee,
                    selectActive: false,
                    selectValues: { name: configJSON.emptyString, value: configJSON.emptyString, onChange: configJSON.emptyString, error: false },
                    amountActive: false,
                    amountValues: { name: configJSON.emptyString, value: configJSON.emptyString, type: configJSON.emptyString, onChange: undefined, error: false },
                    textInputValues: { name: "PANofDonee", value: PANofDonee, type: "text", onChange: this.handleonChangePan, error: false },
                    stateCity: false,
                    stateCityValues: { name: configJSON.emptyString, value: configJSON.emptyString, onChange: configJSON.emptyString, error: false },
                    commonOrganization:false,
                    commonOrganizationValues:{ name: configJSON.emptyString, value: configJSON.emptyString, onChange: configJSON.emptyString, error: false },

                })}
                <Box className={classes.headingwrapeer}>
                    <Typography className={classes.subheadingdonations}>{configJSON.AddresOfDonee}</Typography>
                </Box>
                {this.renderCommonTextField({
                    heading: configJSON.AddressLine,
                    selectActive: false,
                    selectValues: { name: configJSON.emptyString, value: configJSON.emptyString, onChange: configJSON.emptyString, error: false },
                    amountActive: false,
                    amountValues: { name: configJSON.emptyString, value: configJSON.emptyString, type: configJSON.emptyString, onChange: undefined, error: false },
                    textInputValues: { name: "AddressLine", value: AddressLine, type: "text", onChange: this.handleonChangeAddress, error: this.state.errors["AddressLine"] },
                    stateCity: false,
                    stateCityValues: { name: configJSON.emptyString, value: configJSON.emptyString, onChange: configJSON.emptyString, error: false },
                    commonOrganization:false,
                    commonOrganizationValues:{ name: configJSON.emptyString, value: configJSON.emptyString, onChange: configJSON.emptyString, error: false },

                })}
                {this.renderCommonTextField({
                    heading: configJSON.PINCode,
                    selectActive: false,
                    selectValues: { name: configJSON.emptyString, value: configJSON.emptyString, onChange: configJSON.emptyString, error: false },
                    amountActive: false,
                    amountValues: { name: configJSON.emptyString, value: configJSON.emptyString, type: configJSON.emptyString, onChange: undefined, error: false },
                    textInputValues: { name: "PINCode", value: PINCode, type: "text", onChange: this.handleonChangePin, error: this.state.errors["PINCode"] },
                    stateCity: false,
                    stateCityValues: { name: configJSON.emptyString, value: configJSON.emptyString, onChange: configJSON.emptyString, error: false },
                    commonOrganization:false,
                    commonOrganizationValues:{ name: configJSON.emptyString, value: configJSON.emptyString, onChange: configJSON.emptyString, error: false },

                })}
                {this.renderCommonTextField({
                    heading: configJSON.CityStateCountry,
                    selectActive: false,
                    selectValues: { name: configJSON.emptyString, value: configJSON.emptyString, onChange: configJSON.emptyString, error: false },
                    amountActive: false,
                    amountValues: { name: configJSON.emptyString, value: configJSON.emptyString, type: configJSON.emptyString, onChange: undefined, error: false },
                    textInputValues: { name: "TownCity", value: TownCity, type: "text", onChange: this.handleonChangeTextData, error: this.state.errors["TownCity"] },
                    stateCity: true,
                    stateCityValues: { name: "StateDistrict", value: StateDistrict, onChange: this.handleonChangeDropdown as (event: ChangeEvent<{ name?: string; value?: unknown; }>, child: ReactNode) => void, error: this.state.errors["StateDistrict"] },
                    commonOrganization:false,
                    commonOrganizationValues:{ name: configJSON.emptyString, value: configJSON.emptyString, onChange: configJSON.emptyString, error: false },

                })}
            </>
        )
    }
    // Customizable Area End

    render() {
        const { classes } = this.props;
        const { type, onClick } = this.props;
        let deductionType;
        if (type === "section80ggc") {
            deductionType = configJSON.Deduction80GGC;
        } else if (type === "section80gga") {
            deductionType = configJSON.Deduction80GGA;
        } else {
            deductionType = configJSON.donationsnameOne;
        }
        return (
            // Customizable Area Start
            <>
                <DashboardPanselector navigation={this.props.navigation} id={""} />
                <Container className={classes.mainWrapper} maxWidth={false}>
                    <Snackbar anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }} open={this.state.status} autoHideDuration={5000} >
                        <Alert severity="success">
                            {configJSON.succesmessageSnackBar}
                        </Alert>
                    </Snackbar>
                    <Box className={classes.conditionalheading}>
                        <ArrowBackIosIcon fontSize="large" onClick={onClick} /><Typography className={classes.donationdeading} data-test-id="heading">{deductionType}</Typography>
                    </Box>
                    <Box className={classes.backgroundWrapper}>
                        {this.rendercommonComponetDeductions()}
                    </Box>
                    <Box >
                        <Button className={classes.buttonComponent} data-test-id="savedonations" onClick={this.saveDonations}>{configJSON.SaveDonationButton}</Button>
                    </Box>
                </Container>
            </>
            // Customizable Area End
        );
    }
}


// Customizable Area Start
export const styles = (theme: Theme) => ({
    cityValuesWrapper: {
        width: "47%"

    },
    star: {
        color: "#C46F60",
        fontFamily: "Nunito",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600,
    },
    townCityWrapper: {
        width: "50%"

    },
    menuStyles: {
        display: "none", color: '#ABABB0'
    },
    selectColor: {
        color: "#ABABB0"
    },
    boxwidth: {
        width: '30%',
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '100%',
        },
    },
    formMargin: {
        margin: '0'
    },
    underhovertext: {
        color: "rgba(54,64,59,0.5)",
        fontSize: "12px",
        fontWeight: 3500,
        fontFamily: "Nunito",
    },
    labelheading: {
        color: "rgba(54,64,59,0.7)",
        fontSize: "17px",
        fontWeight: 600,
        fontFamily: "Nunito",
        [theme.breakpoints.between('xs', 'sm')]: {
            marginTop: '3em',
        },
    },
    rupeessstyles: {
        color: "rgba(54,64,59,0.9)",
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Nunito",
    },
    subheadingdonations: {
        color: "#36403b",
        fontSize: "24px",
        fontWeight: 700,
        fontFamily: "Nunito",
    },
    donationdeading: {
        color: "#36403b",
        fontSize: "27px",
        fontWeight: 700,
        fontFamily: "Nunito",
    },
    inputlableStyle: {
        color: "rgba(54,64,59,0.7)",
        fontSize: "15px",
        fontWeight: 600,
        fontFamily: "Nunito",
    },
    helpertext: {
        display: "flex",
        aliginItems: "center",
        gap: "5px",
        marginTop: '0.9em',
        color: "rgba(54,64,59,0.5)",
        fontSize: "12px",
        fontWeight: 350,
        fontFamily: "Nunito",

    },
    conditionalheading: {
        padding: "2em 0em 0 0em",
        display: "flex",
        alignItems: "center",
        gap: '4px',
        cursor: "pointer"
    },
    buttonComponent: {
        background: "#337152",
        borderRadius: "5px",
        color: "#ffff",
        fontWeight: 600,
        fontFamily: "Nunito",
        padding: '0.5em 2em 0.5em 2em',
        margin: "2.5em 1em 4em 0",
        '&:hover': {
            background: "#337152",
        },
    },
    headingwrapeer: {
        marginLeft: '2em'

    },
    amount: {
        display: "flex"
    },
    citystate: {
        display: "flex",
        gap: '20px',
        width: "100%",
        maxWidth: "500",
        [theme.breakpoints.between('xs', 'sm')]: {
            display: "unset",
        },
    },
    ruppeslogo: {
        background: "#ddfef7",
        padding: '1em 2em 1em 1em',
        height: '42px',
        borderRadius: "8px 0px 0px 8px",
        display: "flex",
        alignItems: "center"
    },
    textAlignment: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: '2em 8em 0.5em 2em',
        gap: "50px",
        [theme.breakpoints.between('xs', 'sm')]: {
            display: "unset",
        },
    },
    textfiled: {

        "& input": {
            padding: "11px 0px 11px 12px",

            border: "1px solid #grey"
        },
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderLeft: 'none',
                borderRadius: "0px 8px 8px 0px",
            },
        },

    },
    selectdropDown: {
        maxWidth: '100%',
        width: "500px",
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },
        '& .MuiInputBase-input': {
            height: '0px'
        }
    },
    selectOptionDrop: {
        '& .MuiSelect-select': {
            padding: '8px'
        }
    },
    mainWrapper: {
        background: "#F4F6F5",
        borderRadius: "16px",
        margin: "4em 0 3em 1em",
        "@media(max-width:484px)": {
            margin: "0 0 0 1em",
        },

    },
    backgroundWrapper: {
        borderRadius: "16px 10px 10px 16px",
        background: "#FFF",
        marginTop: '2em',
        padding: '2em 2em 2em 0em',
        "@media(max-width:484px)": {
            padding: '2em 0 2em 0em',
        },
    },
})

export default withStyles(styles)(DonationsCharitableOrganization);
// Customizable Area End

