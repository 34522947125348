// Customizable Area Start
import React from 'react';
import {
  // Customizable Area Start
  Box,
  Typography,
  Grid,
  Snackbar,
  // Customizable Area End
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import SalaryDetailsEditController, { Props, configJSON } from "./SalaryDetailsEditController";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export class SalaryDetailsEdit extends SalaryDetailsEditController {
  constructor(props: Props) {
    super(props);
  }

  steps = [
    <div className='stepNo'> <span>1</span>{configJSON.EmploymentTdstitle}</div>,
    <div className='stepNo'> <span>2</span>{configJSON.Salarybreakdown}</div>,
    <div className='stepNo'> <span>3</span>{configJSON.Employeraddress}</div>
  ];

  renderEmployerDetails() {

    return (
      <div className="step-first">
      <span className="prev" onClick={this.windowReload} />
        <Box sx={webStyle.mainWrapper} className="tdsEditForm">
          <Snackbar style={webStyle.sucessMessage} anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }} open={this.state.snackbarOpen} autoHideDuration={4000} onClose={this.handleSnackbarClose} >
            <Alert severity="success">
              {configJSON.DraftMessage}
            </Alert>
          </Snackbar>
          <Grid>
            <h2 className="mt0 mb10 formHeading">{configJSON.EmploymentTdstitle}</h2>
            <Grid container spacing={2}>
              <Grid item sm={12} className='flex commonFormBg1'>
                <Grid item sm={6}>
                  <label className="labelHeading" htmlFor="fname">
                    Employer name
                  </label>
                </Grid>
                <Grid item sm={6}>
                  <input
                    type="text"
                    id="emp_company"
                    placeholder="Type here..."
                    data-test-id="emp_company"
                    onChange={this.handleOnChange}
                    name="emp_company"
                    autoComplete="off"
                    value={this.state.emp_company}
                    onFocus={this.handleFocus}
                  />
                  <Box>
                    {this.state.errors.emp_company &&
                      <Typography className='errorMessage'>{this.state.errors.emp_company}</Typography>
                    }
                  </Box>
                </Grid>
              </Grid>
              <Grid item sm={12} className='flex commonFormBg1'>
                <Grid item sm={6}>
                  <label className="labelHeading" htmlFor="employerNature">
                    Employer nature
                  </label>
                </Grid>
                <Grid item sm={6} style={{ position: "relative" }} className='select-dropdown'>
                  <select
                  style={{appearance:"none"}}
                    name="nature_of_employer"
                    id="nature_of_employer"
                    autoComplete="off"
                    data-testid="nature_of_employer"
                    value={this.state.nature_of_employer}
                    onChange={this.handleOnChange}
                    onFocus={this.handleFocus}
                  >
                    <option value="Select" selected>Select</option>
                    {this.state.employerNature?.map((item: any) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                  <HelpOutlineRoundedIcon style={{ marginLeft: "20px", position: "absolute", top: "10px", fill: "#68706C", fontWeight: 500 }} />
                  <Box>
                    {this.state.errors.nature_of_employer &&
                      <Typography className='errorMessage'>{this.state.errors.nature_of_employer}</Typography>
                    }
                  </Box>
                </Grid>
              </Grid>
              <Grid item sm={12} className='flex tanInput'>
                <Grid item sm={6}>
                  <label className="labelHeading" htmlFor="emplyerTan">
                    Employer TAN
                  <span style={{textDecoration:"underline",marginLeft:"20px",fontSize:"18px",fontWeight:400,fontFamily:"Nunito"}}>Sample</span>
                  </label>
                </Grid>
                <Grid item sm={6}>
                  <input
                    type="text"
                    id="tan_of_employer"
                    placeholder="Type here..."
                    data-test-id="tan_of_employer"
                    onChange={this.handleOnChange}
                    name="tan_of_employer"
                    autoComplete="off"
                    value={this.state.tan_of_employer}
                    onFocus={this.handleFocus}
                  />
                  <Box>
                    {this.state.errors.tan_of_employer &&
                      <Typography className='errorMessage'>{this.state.errors.tan_of_employer}</Typography>
                    }
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <button className='commonBtn' data-test-id="nextBtn" onClick={this.tdsDetailsSubmit}>Next</button>
        <button className='commonBtn ml' data-test-id="savethedraftBtn" onClick={this.savetheDraftTDS.bind(this, "draft")}>{configJSON.savedraftbtn}</button>
      </div>
    );
  }

  renderSalaryDetails() {
    const { activeStepAccordian } = this.state;
    return (
      <div className="step-second" data-test-id="stepSecond">
        <span className="prev" onClick={this.handlePrevStep} />
        <Box sx={webStyle.mainWrapper} className="tdsEditForm">
          <Snackbar style={webStyle.sucessMessage} anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }} open={this.state.snackbarOpen} autoHideDuration={4000} onClose={this.handleSnackbarClose} >
            <Alert severity="success">
              {configJSON.DraftMessage}
            </Alert>
          </Snackbar>
          <Grid>
            <h2 className="mt0 mb10 formHeading">{configJSON.Salarybreakdown}</h2>
            <Grid>
              <Box className="step-accordion">
                <div className={`accordianStep ${activeStepAccordian === 0 ? 'active' : ''}`}>
                  <div className="accordianStepHeader" data-test-id="toggleStep" onClick={this.toggleSteps.bind(this, 0)}>
                    <span className="accordianStepTitle">
                      Gross salary
                    </span>
                    <span className={`arrow ${activeStepAccordian === 0 ? 'up' : 'down'}`}></span>
                  </div>
                  {activeStepAccordian === 0 && (
                    <div className="accordianStepContent">
                      <Grid>
                        <Box sx={{ width: '100%' }} className='accordianStepSection'>
                          <div className='accordianContentList'>
                            <div className='valueList'>
                            <p>1. Gross salary <span style={{textDecoration:"underline",fontSize:"18px",fontWeight:400,fontFamily:"Nunito"}}>Read more</span></p>
                              <div className='amountValue'>₹ {Number(this.state.gross_salary)}</div>
                            </div>
                            <div className='valueList'>
                             <p> 1(a) Salary as per section 17(1) <span style={{marginLeft:"0px"}} className='redSign'>*</span><span style={{textDecoration:"underline",fontWeight:400,fontSize:"18px"}}>Sample</span></p>
                              <div className='amountValue'>₹ {this.calculatePartA()}</div>
                            </div>
                            <div className='valueList basicSalary'>
                              <span className='titleLabel'>Basic Salary</span>
                              <div className='amountValueInput'>
                                <span className='rsSign'>₹</span>
                                <input
                                  type="number"
                                  id="basic_salary"
                                  placeholder="Type here..."
                                  data-test-id="basic_salary"
                                  onChange={this.handleOnChangeSalary}
                                  name="basic_salary"
                                  autoComplete="off"
                                  value={this.state.basic_salary}
                                  onFocus={this.handleFocus}
                                />
                                <Box className='salaryError'>
                                  <Typography className='errorMessage'>
                                    {this.displayError('basic_salary')}
                                  </Typography>
                                </Box>
                              </div>
                            </div>
                            <div className='valueList hra'>
                              <span className='titleLabel hra'>House Rent Allowance<span style={{textDecoration:"underline",fontSize:"18px",fontWeight:400,fontFamily:"Nunito"}}>Sample</span></span>
                              <div className='amountValueInput'>
                                <span className='rsSign'>₹</span>
                                <input
                                  type="number"
                                  id="house_rent_allowance"
                                  placeholder="Type here..."
                                  data-test-id="house_rent_allowance"
                                  onChange={this.handleOnChangeSalary}
                                  name="house_rent_allowance"
                                  autoComplete="off"
                                  value={this.state.house_rent_allowance}
                                  onFocus={this.handleFocus}
                                />
                                <Box className='salaryError'>
                                  <Typography className='errorMessage'>
                                    {this.displayError('house_rent_allowance')}
                                  </Typography>
                                </Box>

                              </div>
                            </div>
                            <div className='valueList lta'>
                              <span className='titleLabel hra'>Leave Travel Allowance<span style={{textDecoration:"underline",fontSize:"18px",fontWeight:400,fontFamily:"Nunito"}}>Sample</span></span>
                              <div className='amountValueInput'>
                                <span className='rsSign'>₹</span>
                                <input
                                  type="number"
                                  id="leave_travel_allowance"
                                  placeholder="Type here..."
                                  data-test-id="leave_travel_allowance"
                                  onChange={this.handleOnChangeSalary}
                                  name="leave_travel_allowance"
                                  autoComplete="off"
                                  value={this.state.leave_travel_allowance}
                                  onFocus={this.handleFocus}
                                />
                                <Box className='salaryError'>
                                  <Typography className='errorMessage'>
                                    {this.displayError('leave_travel_allowance')}
                                  </Typography>
                                </Box>
                              </div>
                            </div>
                            {this.state.salary_section17a.map((salaryA: any, index: any) => (
                              <div key={index} className='valueList'>
                                <div className='selectDropdownAccordian select-dropdown1' style={{ position: "relative" }}>
                                  <select data-test-id="salaryAselect"
                                   style={{appearance:"none"}}
                                    value={salaryA.section_name}
                                    onChange={this.handleSelectChangeSalaryPartA.bind(this, index)}
                                  >
                                    <option value="">Select</option>
                                    {this.state.salaryPartDropdown.map((item: any) => {
                                      return (
                                        <option value={item}>{item.substring(0, 20)}</option>
                                      )
                                    })}
                                  </select>
                                </div>
                                <div className='amountValueInput'>
                                  <span className='rsSign'>₹</span>
                                  <input data-test-id="handleinputA"
                                    type="number"
                                    placeholder="Type here..."
                                    value={salaryA.amount}
                                    onChange={this.handleInputChangeSalaryPartA.bind(this, index)}
                                  />
                                </div>
                                {index > 0 && (
      <div data-test-id="handledeleteA" className='deleteIcon' onClick={this.handleDeleteSalaryPartA.bind(this, index)} />
    )}
                              </div>

                            ))}
                            <div data-test-id="addmoreOption" onClick={this.handleAddSalaryPartA} className='addIcon'>Add more items</div>
                          </div>
                          <div>
                          </div>
                        </Box>
                        <Box sx={{ width: '100%' }} className='accordianStepSection section2'>
                          <div className='accordianContentList'>
                            <p style={{marginLeft:"30px"}}>1(b) Perquisites under section 17(2) </p>
                            {this.state.prequisite_section17bs.map((salaryB: any, index: any) => (
                              <div key={index} className='valueList sectionb'>
                                <div className='selectDropdownAccordian select-dropdown1' style={{ position: "relative" }}>
                                  <select  style={{appearance:"none"}} data-test-id="salarySectionB" value={salaryB.section_name} onChange={this.handleSelectChangeSalaryPartB.bind(this, index)}>
                                    <option value="">Select</option>
                                    {this.state.perquisites.map((item: any, index: number) => {
                                      return (
                                        <option key={index} value={item}>{item.substring(0, 20)}</option>
                                      )
                                    })}
                                  </select>
                                </div>
                                <div className='amountValueInput'>
                                  <span className='rsSign'>₹</span>
                                  <input data-test-id="salaryBinput"
                                    type="number"
                                    placeholder='0'
                                    value={salaryB.amount}
                                    onChange={this.handleInputChangeSalaryPartB.bind(this, index)}
                                  />
                                </div>
                              { index > 0 && <div data-test-id={`salaryBinputdelete${index}`} className='deleteIcon' onClick={() => this.handleDeleteSalaryPartB(index)} />}
                              </div>
                            ))}
                            <div data-test-id="handlesalaryB" onClick={this.handleAddSalaryPartB} className='addIcon'>Add more items</div>
                          </div>
                          <div>
                          </div>
                        </Box>
                        <Box sx={{ width: '100%' }} className='accordianStepSection sectionThree'>
                          <div className='accordianContentList'>
                            <p style={{marginLeft:"30px"}}>1(c) Profits in lieu of salary under section 17(3)</p>
                            {this.state.salary_in_lieu_under_section_17_3.map((salaryC: any, index: any) => (
                              <div key={index} className='valueList'>
                                <div className='selectDropdownAccordian select-dropdown1' style={{ position: "relative" }}>
                                  <select  style={{appearance:"none"}} data-test-id="spartC" value={salaryC.section_name} onChange={this.handleSelectChangesalary_in_lieu_under_section_17_3.bind(this, index)}>
                                    <option value="">Select</option>
                                    {this.state.lieu_salary.map((item: any) => {
                                      return (
                                        <option value={item}>{item.substring(0, 20)}</option>
                                      )
                                    })}
                                  </select>
                                </div>
                                <div className='amountValueInput undersection2'>
                                  <span className='rsSign'>₹</span>
                                  <input data-test-id="salaryCinput"
                                    type="number" placeholder='0' value={salaryC.amount}
                                    onChange={this.handleInputChangesalary_in_lieu_under_section_17_3.bind(this, index)}
                                  />
                                </div>
                             {index > 0 &&  <div className='deleteIcon' data-test-id={`handleDeletec${index}`} onClick={this.handleDeletesalary_in_lieu_under_section_17_3.bind(this, index)} />}
                              </div>
                            ))}
                            <div data-test-id="handlepartC" onClick={this.handleAddsalary_in_lieu_under_section_17_3} className='addIcon newItem'>Add more items</div>
                          </div>
                          <div>
                          </div>
                        </Box>
                      </Grid>
                    </div>
                  )}
                </div>
                <div className={`accordianStep ${activeStepAccordian === 1 ? 'active' : ''}`}>
                  <div className="accordianStepHeader" onClick={this.toggleSteps.bind(this, 1)}>
                    <div className="accordianStepTitle">
                      <p>Exempt allowances under section 10 <span style={{marginLeft:"0px"}} className='redSign'>*</span></p>
                    </div>
                    <span className={`arrow ${activeStepAccordian === 1 ? 'up' : 'down'}`}></span>
                  </div>
                  {activeStepAccordian === 1 && (
                    <div className="accordianStepContent newContent">
                      <div className='valueList'>
                       <p> 2. Exempt allowances under section 10 <span style={{marginLeft:"0px"}} className='redSign'>*</span></p>
                        <div className='amountValue'>₹ {Number(this.state.exempt_allowances_under_section_10)}</div>
                      </div>
                      <div className='valueList exSection'>
                        <span className='titleLabel'>HRA Exemption<span style={{textDecoration:"underline",fontSize:"18px",fontWeight:400,fontFamily:"Nunito"}}>Read more</span></span>
                        <div className='amountValueInput'>
                          <span className='rsSign'>₹</span>
                          <input
                            type="number"
                            id="hra_exemption"
                            placeholder="Type here..."
                            data-test-id="hra_exemption"
                            onChange={this.handleOnChangeExemption}
                            name="hra_exemption"
                            autoComplete="off"
                            value={this.state.hra_exemption}
                            onFocus={this.handleFocus}
                          />

                          <Box className='salaryError'>
                            <Typography className='errorMessage'>
                              {this.displayError('hra_exemption')}
                            </Typography>
                          </Box>
                        </div>
                      </div>

                      <div className='valueList lta'>
                        <span className='titleLabel'>LTA Exemption</span>
                        <div className='amountValueInput'>
                          <span className='rsSign'>₹</span>
                          <input
                            type="number"
                            id="lta_exemption"
                            placeholder="Type here..."
                            data-test-id="lta_exemption"
                            onChange={this.handleOnChangeExemption}
                            name="lta_exemption"
                            autoComplete="off"
                            value={this.state.lta_exemption}
                            onFocus={this.handleFocus}
                          />

                          <Box className='salaryError'>
                            <Typography className='errorMessage'>
                              {this.displayError('lta_exemption')}
                            </Typography>
                          </Box>
                        </div>
                      </div>
                      {this.state.exampt_allowance_section10as.map((value: any, index: any) => (
                        <div key={index} className='valueList'>
                          <div className='selectDropdownAccordian select-dropdown1' style={{ position: "relative" }}>
                            <select  style={{appearance:"none"}} data-test-id="allowancePart" value={value.section_name} onChange={this.handleSelectChangeExempt.bind(this, index)}>
                              <option value="">Select</option>
                              {this.state.exampt_allowances.map((item: string) => {
                                return (
                                  <option value={item}> {item}</option>
                                )
                              })}
                            </select>
                          </div>
                          <div className='amountValueInput'>
                            <span className='rsSign'>₹</span>
                            <input
                              data-test-id="handleexemption"
                              type="number"
                              placeholder='0'
                              value={value.amount}
                              onChange={this.handleInputChangeExemption.bind(this, index)}
                            />
                          </div>
                         {index > 0 && <div className='deleteIcon' data-test-id="deleteExemp" onClick={this.handleDeleteExemption.bind(this, index)} />}
                        </div>
                      ))}
                      <div data-test-id="exemptionpart" onClick={this.exemption} className='addIcon'>Add more items</div>
                    </div>
                  )}
                </div>
                <div className="accordianStep balanceValue">
                <span style={{marginLeft:"20px"}}>

                  Balance (1-2)
                  </span>
                  <div className='balanceExemption'>
                    <span className='rsSign'>₹</span>
                    {
                      Math.abs(Number(this.state.exempt_allowances_under_section_10 )- Number(this.state.gross_salary))
                    }
                  </div>
                </div>
                <div className={`accordianStep ${activeStepAccordian === 2 ? 'active' : ''}`}>
                  <div className="accordianStepHeader" onClick={this.toggleSteps.bind(this, 2)}>
                    <div className="accordianStepTitle">
                     Deductions and Relief
                    </div>
                    <span className={`arrow ${activeStepAccordian === 2 ? 'up' : 'down'}`}></span>
                  </div>
                  {activeStepAccordian === 2 && (
                    <div className="accordianStepContent">
                       <div className='valueList'>
                              4.  Deductions and Relief
                             
                            </div>
                      <div className='valueList' style={{marginLeft:"30px",marginTop:"30px",marginBottom:"20px"}}>
                        4A Deductions under section 16
                      </div>
                      <div className='valueList standardDeduction'>
                        <span className='titleLabel'>Standard deductions 16 (ia)</span>
                        <div className='amountValueInput' style={{ background: "#DEE4EA" }}>
                          <span className='rsSign'>₹</span>
                          <input
                            type="number"
                            id="standardDeduction"
                            placeholder="50000"
                            data-test-id="standardDeduction"
                            name="standardDeduction"
                            onChange={this.handleOnChangeSalary}
                            autoComplete="off"
                            value={this.state.standard_deductions_16_ia}
                            onFocus={this.handleFocus}
                          />
                        </div>
                      </div>
                      <div className='valueList pf'>
                        <span className='titleLabel'>Professional tax under section 16(iii)</span>
                        <div className='amountValueInput'>
                          <span className='rsSign'>₹</span>
                          <input
                            type="number"
                            id="professional_tax_under_16_iii"
                            placeholder="Type here..."
                            data-test-id="professional_tax_under_16_iii"
                            onChange={this.handleOnChangeSalary}
                            name="professional_tax_under_16_iii"
                            autoComplete="off"
                            value={this.state.professional_tax_under_16_iii}
                            onFocus={this.handleFocus}
                          />

                          <Box className='salaryError'>
                            <Typography className='errorMessage'>
                              {this.displayError('professional_tax_under_16_iii')}
                            </Typography>
                          </Box>
                        </div>
                      </div>
                      <div className='valueList reliefSection'>
                        <span className='titleLabel'>4B Relief under section 89<span style={{fontSize:"18px",fontWeight:400,fontFamily:"Nunito",textDecoration:"underline"}}>Read more</span></span>
                        <div className='amountValueInput relief'>
                          <span className='rsSign'>₹</span>
                          <input
                            type="number"
                            id="relief_under_section_89_4b"
                            placeholder="Type here..."
                            data-test-id="relief_under_section_89_4b"
                            onChange={this.handleOnChangeSalary}
                            name="relief_under_section_89_4b"
                            autoComplete="off"
                            value={this.state.relief_under_section_89_4b}
                            onFocus={this.handleFocus}
                          />
                          <Box className='salaryError'>
                            <Typography className='errorMessage'>
                              {this.displayError('relief_under_section_89_4b')}
                            </Typography>
                          </Box>
                        </div>
                      </div>
                      <div className='valueList overseas'>
                        <span className='titleLabel'>Do you have any oversea retire account?</span>
                        <div className='selectDropdownAccordian overseasDropdown2 select-dropdown2' style={{ position: "relative" }}>
                          <select
                          style={{marginLeft:"-110px",appearance:"none"}}
                            name="oversea_retire_account"
                            id="oversea_retire_account"
                            autoComplete="off"
                            data-testid="oversea_retire_account"
                            value={this.state.oversea_retire_account}
                            onChange={this.handleOnChange}
                            onFocus={this.handleFocus}
                          >
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                      <div className='valueList incomeclaimed'>
                        <span className='titleLabel'>4C Income claimed for relief under section 89A<br/><span style={{fontSize:"18px",fontWeight:400,fontFamily:"Nunito"}}>
                         Note that you should submit form 10EE to the income tax department in order <br/> <span style={{fontSize:"18px",fontWeight:400,fontFamily:"Nunito"}}>to claim this relief.</span>
                        </span></span>
                        <div className='amountValueInput relief'>
                          <span className='rsSign'>₹</span>
                          <input
                            type="number"
                            id="income_claimed_for_relief_under_section_89_a"
                            placeholder="Type here..."
                            data-test-id="income_claimed_for_relief_under_section_89_a"
                            onChange={ this.handleOnChangeSalary}
                            name="income_claimed_for_relief_under_section_89_a"
                            autoComplete="off"
                            value={this.state.income_claimed_for_relief_under_section_89_a}
                            onFocus={this.handleFocus}
                          />
                          <Box className='salaryError'>
                            <Typography className='errorMessage'>
                              {this.displayError('income_claimed_for_relief_under_section_89_a')}
                            </Typography>
                          </Box>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="accordianStep balanceValue">
                  Income chargeable under head salaries ( 3 - 4A - 4C)
                  <div className='balanceExemption'>
                    <span className='rsSign'>₹</span>
                    {
                      Math.abs(
                        Number(this.state.balance) -
                        Number(this.state.income_claimed_for_relief_under_section_89_a) -
                        this.state.standard_deductions_16_ia
                      )}
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <button className='commonBtn sssecond' data-test-id="nextBtn" onClick={this.salaryDetailsSubmit.bind(this, "submitted")}>Next</button>
        <button className='commonBtn ml' onClick={this.salaryDetailsDrafts.bind(this, "draft")}>{configJSON.savedraftbtn}</button>
      </div>
    );
  }

  renderEmploymentDetails() {
    return (
      <div className="step-third">
        <span className="prev" onClick={this.handlePrevStep} />
        <Box sx={webStyle.mainWrapper} className="tdsEditForm">
          <Grid>
            <Snackbar style={webStyle.sucessMessage} anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }} open={this.state.snackbarOpen} autoHideDuration={4000} onClose={this.handleSnackbarClose} >
              <Alert severity="success">
                {configJSON.DraftMessage}
              </Alert>
            </Snackbar>

            <h2 className="mt0 mb10 formHeading">{configJSON.Employeraddress}</h2>
            <Grid container spacing={2}>
              <Grid item sm={12} className='flex'>
                <Grid item sm={6}>
                  <label className="labelHeading" htmlFor="pincode">
                    Pin code
                    <span className="redSign" style={{marginLeft:"0px"}}>*</span>
                  </label>
                </Grid>
                <Grid item sm={6}>
                  <input
                    type="number"
                    id="pin_code"
                    placeholder="Type here..."
                    data-test-id="pin_code"
                    onChange={this.handleOnChange}
                    name="pin_code"
                    autoComplete="off"
                    value={this.state.pin_code}
                    onFocus={this.handleFocus}
                  />
                  <Box>
                    {this.state.errors.pin_code &&
                      <Typography className='errorMessage' style={{marginLeft:"30px"}}>{this.state.errors.pin_code}</Typography>
                    }
                  </Box>
                </Grid>
              </Grid>

              <Grid item sm={12} className='flex'>
                <Grid item sm={6}>
                  <label className="labelHeading" htmlFor="addressline">
                    Address line
                     <span className="redSign" style={{marginLeft:"0px"}}>*</span>
                  </label>
                </Grid>
                <Grid item sm={6}>
                  <input
                    type="text"
                    id="address_of_employer"
                    placeholder="Type here..."
                    data-test-id="address_of_employer"
                    onChange={this.handleOnChange}
                    name="address_of_employer"
                    autoComplete="off"
                    value={this.state.address_of_employer}
                    onFocus={this.handleFocus}
                  />
                  <Box>
                    {this.state.errors.address_of_employer &&
                      <Typography className='errorMessage'  style={{marginLeft:"30px"}}>{this.state.errors.address_of_employer}</Typography>
                    }
                  </Box>
                </Grid>
              </Grid>

              <Grid item sm={12} className='flex'>
                <Grid item sm={6}>
                  <label className="labelHeading" htmlFor="town">
                    Town / City
                     <span className="redSign" style={{marginLeft:"0px"}}>*</span>
                  </label>
                </Grid>
                <Grid item sm={6}>
                  <input
                    type="text"
                    id="city"
                    placeholder="Type here..."
                    data-test-id="city"
                    onChange={this.handleOnChange}
                    name="city"
                    autoComplete="off"
                    value={this.state.city}
                    onFocus={this.handleFocus}
                  />
                  <Box>
                    {this.state.errors.city &&
                      <Typography className='errorMessage'  style={{marginLeft:"30px"}}>{this.state.errors.city}</Typography>
                    }
                  </Box>
                </Grid>
              </Grid>

              <Grid item sm={12} className='flex'>
                <Grid item sm={6}>
                  <label className="labelHeading" htmlFor="state">
                    State
                     <span className="redSign" style={{marginLeft:"0px"}}>*</span>
                  </label>
                </Grid> 
                <Grid item sm={6} className='select-dropdown1' style={{ position: "relative" }}>
                  <select
                  style={{appearance:"none"}}
                    name="state"
                    id="state"
                    autoComplete="off"
                    data-testid="state"
                    value={this.state.state}
                    onChange={this.handleOnChange}
                    onFocus={this.handleFocus}
                  >
                    <option value="" disabled selected>Select</option>
                    {this.state.state_list.map((item: any) => {
                      return (
                        <>
                          <option value={item}>{item}</option>
                        </>
                      )
                    })}
                  </select>
                  <Box>
                    {this.state.errors.state &&
                      <Typography className='errorMessage'  style={{marginLeft:"30px"}}>{this.state.errors.state}</Typography>
                    }
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <button data-test-id="submitvaluebtn" type='submit' className='commonBtn submit' onClick={this.employerDetailsSubmit.bind(this, "submitted")}>Submit</button>
        <button data-test-id="draftlast" className='commonBtn ml save' onClick={this.EmployerAddressDetailsDrafts.bind(this, "draft")}>{configJSON.savedraftbtn}</button>
      </div>
    );
  }
  render() {
    const { activeStep } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Box className='commonFormBg' sx={{ width: '100%' }}>
          <div>
            <div className="step-bar">
              {this.steps.map((step: any,  index: any) => (
                <div
                  key={index}
                  className={`step ${index < activeStep ? 'visited' : ''} ${index === activeStep ? 'active' : ''}`}
                >
                  {step}
                </div>
              ))}
            </div>
            <div className="step-content">
              {activeStep === 0 && (
                this.renderEmployerDetails()
              )}
              {activeStep === 1 && (
                this.renderSalaryDetails()
              )}
              {activeStep === 2 && (
                this.renderEmploymentDetails()
              )}
            </div>
          </div>
        </Box>
      </ThemeProvider>
    );
  }
}
export default SalaryDetailsEdit;
// Customizable Area Start
const webStyle = {
  mainWrapper: {
    fontFamily: "'Nunito', sans-serif",
    background: "#F4F6F5",
  },
  sucessMessage: {
    background: "#eaf9ed",
    borderRadius: "8px"
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
// Customizable Area End