import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
} from "@material-ui/core";
import { withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';



interface MyComponentProps {
    icontype?: string;
    icon: string;
    Amount: string;
    name: string;
    onClickActive: () => void;
    id: string;
    isActive: boolean;

}

export class SalaryCustom extends React.Component<MyComponentProps> {

    render() {

        const { classes }: any = this.props;
        const { icontype, icon, Amount, name, onClickActive, isActive } = this.props;
        return (
            <>
                <Box className={classes.backgroundpriceList}>
                    <Typography className={classes.deductions}><img src={icontype} className={classes.logo} style={{ marginInline: '20px' }} /><Typography className={classes.nameData}>{name}</Typography></Typography>
                    <Box className={classes.pricelogo}>
                   { icontype && <img src={icon} className={classes.rupeelogo} />}
                        {Amount}
                        <Box  className={classes.iconstyle}>
                            {isActive ? <ExpandLessIcon  className={classes.active} onClick={onClickActive} /> : <ExpandMoreIcon className={classes.active} onClick={onClickActive} />}

                        </Box>

                    </Box>
                </Box>
            </>
        )
    }

}
 const styles = {
    backgroundpriceList: {
        borderRadius: "16px 10px 10px 16px",
        background: "#F4F6F5",
        padding: "1em 0 1em 0",
        margin: '1em 0 0 1.7em',
        display: "flex",
        justifyContent: "space-between",
        "@media(max-width:484px)": {
           flexDirection:"column" as const,
           margin: '1em 0 0 0',
        },
    },
    deductions: {
        display: "flex",
        alignItems: "center",
        "@media(max-width:484px)": {
            flexDirection:"column" as const,
         },
    },
    logo: {
        maxWidth: "30.27px",
        width:"100%",
        height: 'auto'
    },
    rupeelogo: {
        maxWidth: '13px',
        width:"100%",
        height: 'auto'
    },
    pricelogo: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        marginRight: '2em',
        "@media(max-width:484px)": {
            // flexDirection:"column" as const,
            margin:"unset"
         },
    },
    iconstyle:{
        marginLeft: "1.5em" ,
        "@media(max-width:484px)": {
            margin:"unset"
         },
    },
    nameData:{
        width:"100px"
    },
    active:{
        cursor:"pointer"
    }
}

export default withStyles(styles)(SalaryCustom);

// Customizable Area End