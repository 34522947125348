Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.landinPageParams="bx_block_landingpage2/landingpage2?type_of=";
exports.knowladge_base="bx_block_knowledge_base/knowledge_base/recent_articles?per_page=3";
exports.knowladge_baseAll="bx_block_knowledge_base/knowledge_base/show_all?per_page"
exports.controls="controls"
exports.videoId="video-id"
exports.login="login"
exports.urlVedio="url"
exports.journeyApi="journey"
exports.featuresApi="features"
exports.reviewApi="review"
exports.tutorialsApi="tutorials"
exports.introductionApi="introduction"
exports.imageApi="image"
exports.videoApi="video"
exports.LandingPage="LandingPage"
exports.Knowladgebase="knowledgeBase"
exports.titleText="Start your journey in 3 easy steps"
exports.subTitle="It will take only 5 minutes"
exports.getStartedText="GET STARTED"
exports.featurtes="FEATURES"
exports.competetors="See what we offer that makes us stand apart from our competitors"
exports.aProAccount="A pro account that combines the best of both worlds."
exports.tutorials="TUTORIALS"
exports.getMoreInfoTaxFilling="Get to know more about tax filing"
exports.getMoreInfoTaxCalculator="Tax Calculator"
exports.informativeResoures="Explore the ins and outs of tax filing and gain a better understanding of the process with our informative resoures."
exports.buttonAll="View All"
exports.HaveQuestion="Have a Question? We're Here to Help"
exports.dummyText="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aut, facilis?"
exports.makeEnquiry="Make enquiry"
exports.benfits="BENEFITS"
exports.topYourFinances="Stay on the top your finances"
exports.financialFuture="Stay on top of your spendings, investments, and performance with ease,and make informed decisions for your financial future."
exports.taxFiling="tax filing"
exports.saveExtraOn="Save extra on"
exports.accountOpening="Account Opening"

exports.inFiveMins="in 5 mins"

exports.noHiddenCharges="No hidden charges"

exports.noWorries="No Worries"

exports.yourTrustedTax="Your Trusted Tax"
exports.filingPartner="Filing Partner"
exports.filingPartnerEnsuresYourTaxes="File your taxes with confidence with our secure and reliable online filing services. Our trusted tax filing partner ensures your taxes are filed accurately and on time."
exports.fileItrNow="File ITR Now"
exports.testimonial="TESTIMONIAL"
exports.happyTaxpayers="Happy Taxpayers, Happy Returns: See What They Have To Say"
exports.dummTextOne="Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam delectus nulla rem id est dolorem voluptas architecto tenetur pariatur ducimus?"
exports.followUsOn="Follow us on"
exports.colourOne="#ffeae9"
exports.colourTwo="#e9f4fa"
exports.colourThree="#eff3d8"
exports.seeDemo="See demo"
exports.makeAnEnquiry="Make an enquiry"
exports.makeAnEnquirySubmit="Submit"
exports.makeAnEnquiryHidden="hidden"
exports.makeAnEnquiryQuery="Query"
exports.makeAnEnquiryEmail="Email"
exports.makeAnEnquiryName="Name"
exports.makeAnEnquiryNameError="Please enter name"
exports.makeAnEnquiryEmailError="Please enter email id"
exports.makeAnEnquiryEmailErrorValid="Email is invalid"
exports.makeAnEnquiryQueryError="Please enter query"
exports.makeAnEnquiryApiCall="bx_block_contact_us/contacts"
exports.clickaway="clickaway"
exports.succesMessage="Thank you! Your submission has been received successfully"
exports.enterYourName="Enter your name"
exports.enterYourEmail="Enter your email"
exports.writeSomething="Write something..."
exports.navToDashboard="Menu/Dashboard"
exports.toLogin="login"
exports.UserProfileBasicBlock="UserProfileBasicBlock"
exports.knowledgeImage="image"
exports.knowledgeVideo="video"
exports.recentArticles="Recent articles"
exports.apr15="Apr 15 2023"
exports.econoicsTime="econoics time"
exports.loadMoreArticles="Load more articles"
exports.pleasewatchthisvideoforassistance="please watch this video for assistance"
exports.Needhelpin="Need help in"
exports.taxfiling="tax filing?"
exports.dummyTextknowledge="Lorem, ipsum dolor sit amet consectetur adipisicing."
exports. allArticle=" All articles"
exports.econoicsTime="econoics time"
exports.followUs ="Follow us on"
exports.Searchbasedontopic ="Search based on topic "

exports.Searchtopic="Search"
const urlConfig = require("../../../framework/src/config");
exports.baseURL = urlConfig.baseURL;
exports.SearchResults="Search Results"
exports.NoRecords="No records found for submitted details."
exports.knowledgeVedioUrl="bx_block_knowledge_base/knowledge_base?type_of="
exports.profiledataGet="account_block/accounts"
exports.authToken="authToken"
exports.DownloadJSON = "bx_block_cfocrcommonitr2/itr1_mappings?"
// Customizable Area End