import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
import { WithStyles } from "@material-ui/core";
export const configJSON = require("./config");
import { getStorageData } from "../../../framework/src/Utilities";

interface ErrorObject {
    [key: string]: boolean;
}
// Customizable Area End

export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    open: boolean;
    type: string;
    onClick: (type: string) => void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    futureIntraday: boolean;
    nameOfBusiness: string;
    totalPositiveTrades: string;
    totalNegitiveTrades: string;
    directExpenses: string;
    indirectExpenses: string;
    turnover: string,
    grossProfit: string,
    expenditure: string,
    netProfit: string,
    error: ErrorObject
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class FutureOptionsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    futureAndOptionscallID: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            futureIntraday: false,
            nameOfBusiness: "",
            totalPositiveTrades: "",
            totalNegitiveTrades: "",
            directExpenses: "choose",
            indirectExpenses: "choose",
            turnover: "",
            grossProfit: "",
            expenditure: "",
            netProfit: "",
            error: {}

        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        this.deductionsPost()
        // Customizable Area End
    }
    // Customizable Area Start
    handleOnchangeFutures = (event: { target: { name: string, value: string } }) => {
        const { name, value } = event.target;
        this.setState((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }
    handleFocusFutures = (event: { target: { name: string, value: string } }) => {
        const { name } = event.target;
        this.setState((prevData) => ({
            ...prevData,
            error: {
                ...prevData.error,
                [name]: false
            }
        }));
    }
  

   
 
    handleSubmitFutures = () => {
        const { type } = this.props;
        let validate = false
        if (!validate) {
            // post api fo futures and options 
        } else {
            this.setState({
                error: this.state.error
            })
        }
    }


    ///post api call for futures and options start
    deductionsPost =  async() => {
        const { type } = this.props
        let validate = type === configJSON.futuresProps ? configJSON.futuresAndOptionsApi : configJSON.intradayApi

        const {
            nameOfBusiness,
            totalPositiveTrades,
            totalNegitiveTrades,
            directExpenses,
            indirectExpenses,
            turnover,
            grossProfit,
            expenditure,
            netProfit, } = this.state;
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };

        const httpBody = {
            data: {
                attributes: {
                    name_of_business: nameOfBusiness,
                    ...(type === configJSON.futuresProps
                        ? {
                            positive_trade: totalPositiveTrades,
                            negative_trade: totalNegitiveTrades,
                            direct_expanse: directExpenses,
                            indirect_expanse: indirectExpenses
                        }
                        : {
                            speculative_activity: turnover,
                            gross_profit: grossProfit,
                            net_profit: netProfit,
                            expenditure: expenditure
                        })
                }
            }
        };
        const getpasswordfrom = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.futureAndOptionscallID = getpasswordfrom.messageId;

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            validate
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod

        );
        runEngine.sendMessage(getpasswordfrom.id, getpasswordfrom);
    }
    ///post api call for futures and options start

    // Customizable Area End
}
