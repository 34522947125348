import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";

interface ErrorObject {
    [key: string]: boolean;
}
interface ApiRentalRes {
    data: {
        attributes: { draft_status: string}
    }
    meta: {
        message: [
            { id: number, message: string}
        ]
    }
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    homePageRental: () => void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    activeStep: number;
    tenent: any;
    firstRent: string;
    firstRentTax: string;
    firstRentInt: string;
    rentFinYear: string;
    rentInt: string;
    tenantName: string;
    tenantPAN: string;
    tenantPercShare: string;
    tenantFlatNum: string;
    tenantPremiseName: string;
    tenantStreetName: string;
    tenantPincode: string;
    tenantArea: string;
    tenantCity: string;
    tenantState: string;
    tenantCountry: string;
    coOwner: any;
    selfHouseID: undefined | string | number | null;
    error: ErrorObject;
    propertyId: undefined | string | number;
    isLoadingRentalProperty: boolean
    snackbarOpen: boolean

    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class RentalPropertyEditController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    rentalPostId: string = ''
    getDraftDataRental: string = ""
    getRentalById: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            activeStep: 1,
            tenent: [
                {
                    id: 0,
                    name_of_tenant: '',
                    pan_of_tenant: '',
                    percentage_share_of_tenant: '',
                }
            ],

            firstRent: '',
            firstRentTax: '',
            firstRentInt: '',
            rentFinYear: '',
            rentInt: '',
            tenantName: '',
            tenantPAN: '',
            tenantPercShare: '',
            tenantFlatNum: '',
            tenantPremiseName: '',
            tenantStreetName: '',
            tenantPincode: '',
            tenantArea: '',
            tenantCity: '',
            tenantState: '',
            tenantCountry: '',
            coOwner: [{
                id: 0,
                name_of_coowner: '',
                pan_of_coowner: '',
                percentage_share: '',
            }],
            selfHouseID: null,
            error: {},
            propertyId: undefined,
            isLoadingRentalProperty: true,
            snackbarOpen: false
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }



    async componentDidMount() {
        // Customizable Area Start
        if (this.props.id !== "") {
            this.getSignleRentalProperty(this.props.id)
        } else {
            this.handleDraftDataRentalProperty()
        }
        // Customizable Area End
    }




    // Customizable Area Start


    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (this.rentalPostId === apiRequestCallId) {
                this.handleRentalSaveAndNextData(responseJson)
            }

            if (this.getDraftDataRental === apiRequestCallId) {
                this.handleDraftDataForRentalProperty(responseJson)

            }
            if (this.getRentalById === apiRequestCallId) {
                this.handleDraftDataForRentalProperty(responseJson)
            }

        }

        // Customizable Area End
    }


    handleAddMultipleItemsRental = () => {
        let newLength = this.state.coOwner.length;
        let newItem = { id: newLength, name_of_coowner: '', pan_of_coowner: '', percentage_share: '' }
        this.setState((prev) => ({
            coOwner: [...prev.coOwner, newItem]
        }));
    };
    handleDeleteMultipleItemsRental = (id: string | number) => {
        let newData = this.state.coOwner.filter((item: { id: number | string }) => item.id !== id)
        this.setState(() => ({
            coOwner: newData
        }));
    };
    handleMultipleChangeDataRental = (id: string | number, event: { target: { name: string; value: string } }) => {
        let newArr = [...this.state.coOwner];
        const findElement = newArr.findIndex((item: { id: string | number }) => item.id === id);
        if (findElement !== -1) {
            if (event.target.name === 'percentage_share' && event.target.value !== '' && !/^\d+$/.test(event.target.value)) {
                return;
            }

            newArr[findElement][event.target.name] = event.target.value;
        }
        this.handleValidateFormFourthRental();
        this.setState({ coOwner: newArr });
    };
    handleAddMultipleTenentDetail = () => {
        let newLength = this.state.tenent.length;
        let newItem = { id: newLength, name_of_tenant: '', pan_of_tenant: '', percentage_share_of_tenant: '' }
        this.setState((prev) => ({
            tenent: [...prev.tenent, newItem]
        }));
    };
    handleDeleteMultipleTenentDetail = (id: string | number) => {
        let newData = this.state.tenent.filter((item: { id: number | string }) => item.id !== id)
        this.setState(() => ({
            tenent: newData
        }));
    };
    handleMultipleChangeTenentDetail = (id: string | number, event: { target: { name: string; value: string } }) => {
        let newArr = [...this.state.tenent];
        const findElementIndex = newArr.findIndex((item: { id: string | number }) => item.id === id);

        if (findElementIndex !== -1) {
            if (event.target.name === 'percentage_share_of_tenant' && event.target.value !== '' && !/^\d+$/.test(event.target.value)) {
                return;
            }

            newArr[findElementIndex][event.target.name] = event.target.value;
        }
        this.handleValidateTenentDetails();
        this.setState({ tenent: newArr })
    };




    handleValidateTenentDetails = () => {
        const panRegex = /[A-Z]{5}\d{4}[A-Z]$/;
        const { tenent } = this.state;
        let allValidRental = true;
        for (const owner of tenent) {
            const name = String(owner.name_of_tenant).trim();
            const pan = String(owner.pan_of_tenant).trim();
            const percentage = String(owner.percentage_share_of_tenant).trim();

            if (!owner.name_of_tenant || name === '' ||
                !owner.pan_of_tenant || pan === '' ||
                !owner.percentage_share_of_tenant || percentage === '' ||
                !panRegex.test(owner.pan_of_tenant)) {
                allValidRental = false;
                break;
            }
        }
        this.state.error["tenent"] = !allValidRental;
        return allValidRental;
    };

    handleDraftDataForRentalProperty = (apiRes: {
        data: {
            id: string | number,
            attributes: {
                step: number,
                previous_financial_year: string,
                total_interest_amount: string,
                income_detail: {
                    annual_rent: string,
                    municipal_tax: string,
                    interest_paid_on_loan: string
                },
                house_address: {
                    house_number: string,
                    premise_name: string,
                    street: string,
                    pincode: string,
                    country: string,
                    state: string,
                    area: string,
                    city: string,
                },
                coowner_details: {
                    id: number,
                    name_of_coowner: string,
                    pan_of_coowner: string,
                    percentage_share: string

                }[],
                tenant_details: []
            }

        }
    }) => {
        if (apiRes && apiRes?.data) {
            const {
                step,
                previous_financial_year,
                total_interest_amount,
                house_address,
                income_detail,
                coowner_details,
                tenant_details
            } = apiRes?.data?.attributes
            this.setState({ isLoadingRentalProperty: false })
            if (step == 1) {
                income_detail &&
                    this.setState({
                        propertyId: apiRes.data.id,
                        firstRent: income_detail.annual_rent,
                        firstRentTax: income_detail.municipal_tax,
                        firstRentInt: income_detail.interest_paid_on_loan
                    });
            } else if (step == 2) {
                income_detail &&
                    this.setState({
                        propertyId: apiRes.data.id,
                        firstRent: income_detail.annual_rent,
                        firstRentTax: income_detail.municipal_tax,
                        firstRentInt: income_detail.interest_paid_on_loan,
                        rentFinYear: previous_financial_year,
                        rentInt: total_interest_amount
                    });
            }
            else if (step == 3) {
                this.setState({
                    coOwner: coowner_details.map((coowner: { id: string | number; name_of_coowner: string | number; pan_of_coowner: string | number; percentage_share: string | number; }) => ({
                        id: coowner.id,
                        name_of_coowner: coowner.name_of_coowner,
                        pan_of_coowner: coowner.pan_of_coowner,
                        percentage_share: coowner.percentage_share,
                    })),
                    propertyId: apiRes.data.id,
                    firstRent: income_detail.annual_rent,
                    firstRentTax: income_detail.municipal_tax,
                    firstRentInt: income_detail.interest_paid_on_loan,
                    rentFinYear: previous_financial_year,
                    rentInt: total_interest_amount,
                    tenantFlatNum: house_address.house_number,
                    tenantPremiseName: house_address.premise_name,
                    tenantStreetName: house_address.street,
                    tenantPincode: house_address.pincode,
                    tenantArea: house_address.area,
                    tenantCity: house_address.city,
                    tenantState: house_address.state,
                    tenantCountry: house_address.country,
                    tenent: tenant_details,
                });
            } else if (step == 4) {
                this.setState({
                    propertyId: apiRes.data.id,
                    firstRent: income_detail.annual_rent,
                    firstRentTax: income_detail.municipal_tax,
                    firstRentInt: income_detail.interest_paid_on_loan,
                    rentFinYear: previous_financial_year,
                    rentInt: total_interest_amount,
                    tenantFlatNum: house_address.house_number,
                    tenantPremiseName: house_address.premise_name,
                    tenantStreetName: house_address.street,
                    tenantPincode: house_address.pincode,
                    tenantArea: house_address.area,
                    tenantCity: house_address.city,
                    tenantState: house_address.state,
                    tenantCountry: house_address.country,
                    coOwner: coowner_details,
                });
            }
        } else {
            this.setState({ isLoadingRentalProperty: false })
        }
    }
    handleRentalSaveAndNextData = (apiResData:ApiRentalRes) => {
        if (apiResData && apiResData.data.attributes.draft_status === "save as draft") {
            let currentId = apiResData.meta.message[0].id
            this.setState((prev) => ({ ...prev, activeStep: prev.activeStep + 1, propertyId: currentId, snackbarOpenRental: true }))
        } else if (apiResData && apiResData.data.attributes.draft_status === "submitted") {
            this.props.homePageRental()
        }
    }
    handleStepsRental = () => {
        if (this.state.activeStep > 1) {
            this.setState((prev) => ({
                ...prev,
                activeStep: prev.activeStep - 1
            }))
        } else {
            this.props.homePageRental()
        }


    }
    getSignleRentalProperty = (id: string | number) => {
        const header = {
            "token": localStorage.getItem(configJSON.authToken),
            "Content-Type": configJSON.exampleApiContentType
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getRentalById = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.selfPropertyEndPoint}/${id}`);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    handleOnchangeRentalProperty = (event: { target: { name: string, value: string } }) => {
        const { name, value } = event.target;
        const stringPattern = /^[a-zA-Z\s]*$/;
        if ((name === 'tenantCity' || name === 'tenantState' || name == "tenantCountry") && !stringPattern.test(value)) {
            return;
        }
        this.setState((prevData) => ({
            ...prevData,
            [name]: value
        }))

    }
    handleFocusRentalProperty = (event: { target: { name: string, value: string } }) => {
        const { name } = event.target;
        this.setState((prevData) => ({
            ...prevData,
            error: {
                ...prevData.error,
                [name]: false
            }
        }));
    }
    handleBlurRentalProperty = (event: { target: { name: string, value: string } }) => {
        const { name, value } = event.target;
        this.setState((prevData) => ({
            ...prevData,
            error: {
                ...prevData.error,
                [name]: String(value).trim() === ""
            }
        }));
    }
    handleValidateRentalFormOne = () => {
        let isValid = true;
        const setError = (field: string) => {
            isValid = false;
            this.state.error[field] = true;
        };
        const validateField = (value: string, field: string) => {
            if (value === null || value === "") {
                setError(field);
            }
        };
        validateField(this.state.firstRent, "firstRent");
        validateField(this.state.firstRentTax, "firstRentTax");
        validateField(this.state.firstRentInt, "firstRentInt");


        return isValid
    }
    handleValidateRentalFormSecond = () => {
        let isValid = true;
        if (this.state.rentFinYear == "" || this.state.rentFinYear == null) {
            this.state.error["rentFinYear"] = true
            isValid = false
        }
        if (this.state.rentInt == "" || this.state.rentInt == null) {
            this.state.error["rentInt"] = true
            isValid = false
        }
        return isValid
    }
    handleValidateRentalFormThird = () => {
        let isValid = true;
        const setErrorRental = (field: string) => {
            isValid = false;
            this.state.error[field] = true;
        };
        const validateFieldrental = (value: string, field: string) => {
            if (value === null || value === "") {
                setErrorRental(field);
            }
        };
        validateFieldrental(this.state.tenantFlatNum, "tenantFlatNum");
        validateFieldrental(this.state.tenantPremiseName, "tenantPremiseName");
        validateFieldrental(this.state.tenantStreetName, "tenantStreetName");
        validateFieldrental(this.state.tenantPincode, "tenantPincode");
        validateFieldrental(this.state.tenantArea, "tenantArea");
        validateFieldrental(this.state.tenantCity, "tenantCity");
        validateFieldrental(this.state.tenantState, "tenantState");
        validateFieldrental(this.state.tenantCountry, "tenantCountry");
        return isValid
    }
    handleThridSub = () => {
        let stepOne = this.handleValidateTenentDetails()
        let stepTwo = this.handleValidateRentalFormThird()
        if (stepOne && stepTwo) {
            return true
        } else {
            return false
        }
    }
    handleValidateFormFourthRental = () => {
        const { coOwner } = this.state;
        const panRegexRental = /[A-Z]{5}\d{4}[A-Z]$/;
        let allValidRental = true;

        for (const owner of coOwner) {
            const ownerName = String(owner.name_of_coowner).trim();
            const ownerPan = String(owner.pan_of_coowner).trim();
            const percentage = String(owner.percentage_share).trim();

            if (!owner.name_of_coowner || ownerName === '' ||
                !owner.pan_of_coowner || ownerPan === '' ||
                percentage === '' || !owner.percentage_share ||
                !panRegexRental.test(owner.pan_of_coowner)) {
                allValidRental = false;
                break;
            }
        }

        this.state.error["coOwner"] = !allValidRental;
        return allValidRental;
    };
    handleValidateRentalAllForms = () => {
        switch (this.state.activeStep) {
            case 1:
                return this.handleValidateRentalFormOne()
            case 2:
                return this.handleValidateRentalFormSecond()
            case 3:
                return this.handleThridSub()
            case 4:
                return this.handleValidateFormFourthRental()
        }

    }
    handleValidationRentalSubmit = () => {
        if (this.handleValidateRentalAllForms()) {
            this.rentalDataPost()
        } else {
            this.setState({
                error: this.state.error
            })
        }
    }

    handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === configJSON.clickaway) {
            return;
        }

        this.setState({ snackbarOpen: false })
    };
    costumStepperRentalData = () => {
        switch (this.state.activeStep) {
            case 1:
                return {
                    property_type: "rental",
                    step: 1,
                    draft_status: "save as draft",
                    income_detail: {
                        annual_rent: this.state.firstRent,
                        municipal_tax: this.state.firstRentTax,
                        interest_paid_on_loan: this.state.firstRentInt,

                    },
                }
            case 2:
                return {
                    property_type: "rental",
                    step: 2,
                    previous_financial_year: this.state.rentFinYear,
                    total_interest_amount: this.state.rentInt,
                    draft_status: "save as draft",
                }
            case 3:
                return {
                    property_type: 'rental',
                    step: 3,
                    house_address: {
                        house_number: this.state.tenantFlatNum,
                        premise_name: this.state.tenantPremiseName,
                        street: this.state.tenantStreetName,
                        pincode: this.state.tenantPincode,
                        country: this.state.tenantCountry,
                        area: this.state.tenantArea,
                        city: this.state.tenantCity,
                        state: this.state.tenantState
                    },
                    tenant_details: this.state.tenent,
                    draft_status: "save as draft",

                }
            case 4:
                return {
                    step: 4,
                    property_type: 'rental',
                    id: this.state.selfHouseID,
                    coowner_details: this.state.coOwner,
                    draft_status: "submitted",
                }

        }
    }
    rentalDataPost = async () => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };
        const data = this.costumStepperRentalData();
        if (this.state.propertyId !== "" && this.state.propertyId !== undefined) {
            if (data) {
                data.id = this.state.propertyId;
            }
        }
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

        this.rentalPostId = requestMessage.messageId;

        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.selfPropertyEndPoint)

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    handleDraftDataRentalProperty = async () => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getDraftDataRental = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.checkDraftStatus}rental`);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}
