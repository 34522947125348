import React from "react";

import {
    Box,
    Container,
    Card,
    styled,
    Typography,
    List,
    ListItem,
    ListItemText,
    Grid,
    Button,
    Snackbar,
    Slide
} from "@material-ui/core";
import { OtherAssets1, OtherAssets2, OtherAssets3, OtherAssets4, LeftArrow, calanderIcon, downArrow, info, infoColorIcon } from "./assets"

import CustomCalander from "../../../components/src/CustomCalander.web";
import CapitalGainLangingPageController, {
    Props,
} from "./CapitalGainLangingPageController.web";
import Header from "../../../components/src/Header.web";
import { SuccessDialog, CustomAutocomplete } from "../../../components/src/StyledMuiComponent";
import { CustomTextfield } from "../../../components/src/CustomTextfield.web";
import Alert from '@material-ui/lab/Alert';
import DashboardPanselector from "../../dashboard/src/DashboardPanselector.web";
const CustomList = styled(List)({
    listStyleType: "disc",
    marginLeft: "20px",
    "& .MuiListItemText-primary": {
        color: "rgba(54, 64, 59, 0.75)",
        fontFamily: "Nunito",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400
    },
    "& .MuiListItem-gutters": {
        padding: "0px",
        display: "revert"
    },
    "& .MuiListItemText-root": {
        margin: "0px"
    },
    "&>li::marker ": {
        fontSize: "24px",
        color: "#A1A1A1"
    }
})

export const RenderDropdown = (props: any) => {
    return (
        <Box className="OCA_autocomplete_box">
            <CustomAutocomplete
                renderInput={(params: any) => <CustomTextfield
                    {...params}
                    variant="outlined"
                    fullWidth
                    placeholder="Select"
                />}
                onChange={(e: any, value: any) => {
                    props.handleGetValue(value)
                }}
                options={["1", "2", "3"]}
                fullWidth
            />
            <img className="OCA_downArrow_img" src={downArrow} />
        </Box>
    )
}
export const ReturnGrid = (props: any) => {
    const { title, value, name, handleSetValue, errormessage } = props;
    return (
        <Grid container className="grid_container">
            <Grid className="align_flex" item lg={4} sm={12} md={4}>
                <Typography className="font_style-1">{title}</Typography>
            </Grid>
            <Grid item lg={8} sm={12} md={8}>
                <Grid container spacing={2}>
                    <Grid item lg={6} md={12} className="OCA_textfield_error_container">
                        <Box className="OCA_textfield_box">
                            <Box className="OCA_ruppee">
                                <span>₹</span>
                            </Box>
                            <CustomTextfield data-testid="test_GridTextField" className="form_textfield" variant="outlined" placeholder="00" value={value} name={name} onChange={(e) => handleSetValue(e)} />
                        </Box>
                        {errormessage && <Box className="error_message">{errormessage}</Box>}
                    </Grid>
                    <Grid item lg={6} md={12}></Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default class OtherCapitalAssets extends CapitalGainLangingPageController {
    constructor(props: Props) {
        super(props);
    }
    renderheading(state: any) {
        switch (state) {
            case 0:
                return (
                    "Sale of any other capital asset"
                );
            case 1:
                return (
                    "Sale of any other asset"
                );
            case 2:
                return (
                    "Reversal of capital gains exemptions"
                );
            case 3:
                return (
                    "Pass through Income taxable as Capital Gains"
                );
        }
    }

    render() {
        const LongTerm = [
            {
                title: "Taxable at 10%",
                name: "taxable_at_10_percent",
                error: this.state.pass_through_income_errors.taxable_at_10_percent
            },
            {
                title: "Taxable at 10% other than under section 112A",
                name: "taxable_at_10_percent_other_than_112A",
                error: this.state.pass_through_income_errors.taxable_at_10_percent_other_than_112A
            },
            {
                title: "Taxable at 20%",
                name: "taxable_at_20_percent",
                error: this.state.pass_through_income_errors.taxable_at_20_percent
            }
        ]
        const ShortTerm = [
            {
                title: "Taxable at 15%",
                name: "taxable_at_15_percent",
                error: this.state.pass_through_income_errors.taxable_at_15_percent
            },
            {
                title: "Taxable at 30%",
                name: "taxable_at_30_percent",
                error: this.state.pass_through_income_errors.taxable_at_30_percent
            },
            {
                title: "Taxable at slab rates",
                name: "taxable_at_slab_rate",
                error: this.state.pass_through_income_errors.taxable_at_slab_rate
            }
        ]

        return (
            <MainWrapper maxWidth={false}>
                <DashboardPanselector navigation={this.props.navigation} id={""} data-test-id="pancard" />
                <Box className="OCA_body">
                    <Box className="OCA_heading">
                        <img className="OCA_left_arrow" src={LeftArrow} data-testid="go_back" onClick={() => this.handleSetActiveAsset(0)} />
                        <Typography className="OCA_heading--text">{this.renderheading(this.state.activeAsset)}</Typography>
                    </Box>
                    {(this.state.activeAsset === 0 || this.state.activeAsset === 4) &&
                        <>
                            <Card className="OCA_form_card" data-testid="card_1" onClick={() => this.handleSetActiveAsset(1)}>
                                <Box className="OCA_image_box">
                                    <img src={OtherAssets1} />
                                </Box>
                                <Box>
                                    <Typography className="OCA_detail--title">Sale of any other asset being</Typography>
                                    <CustomList>
                                        <ListItem>
                                            <ListItemText>Unit Linked Insurance Policy</ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText>Personal effects such as -  &#40;a&#41; jewellery;  &#40;b&#41; archaeological collections; &#40;c&#41; drawings; &#40;d&#41; paintings; &#40;e&#41; sculptures; or &#40;f&#41; any work of art</ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText>Gold or Silver</ListItemText>
                                        </ListItem>
                                    </CustomList>
                                </Box>
                            </Card>
                            <Card className="OCA_form_card" data-testid="card_2" onClick={() => this.handleSetActiveAsset(2)}>
                                <Box className="OCA_image_box">
                                    <img src={OtherAssets2} />
                                </Box>
                                <Box >
                                    <Typography className="OCA_detail--title">Reversal of Capital Gains Exemptions claimed in prior years</Typography>
                                </Box>
                            </Card>
                            <Card className="OCA_form_card" data-testid="card_3" onClick={() => this.handleSetActiveAsset(3)}>
                                <Box className="OCA_image_box">
                                    <img src={OtherAssets3} />
                                </Box>
                                <Box className="OCA_image">
                                    <Typography className="OCA_detail--title">Pass through income</Typography>
                                </Box>
                            </Card>
                            <Card className="OCA_form_card" data-testid="card_4" onClick={() => this.handleSetActiveAsset(4)}>
                                <Box className="OCA_image_box">
                                    <img src={OtherAssets4} />
                                </Box>
                                <Box>
                                    <Typography className="OCA_detail--title">Other cases</Typography>
                                    <CustomList>
                                        <ListItem>
                                            <ListItemText>Slump Sale &#40;edge case&#41;</ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText>Gains taxable at DTAA rates &#40;edge case&#41;</ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText>Foreign exchange asset sold by NRI &#40;edge case&#41;</ListItemText>
                                        </ListItem>
                                    </CustomList>
                                </Box>
                            </Card>
                        </>
                    }
                    {this.state.activeAsset > 0 && <Card className="OCA_card">
                        {this.state.activeAsset === 1 && < Box >
                            <ReturnGrid data-testid="sales_consideration" title={"Sales Consideration"} name="sales_consideration" value={this.state.sale_of_other_asset.sales_consideration} handleSetValue={this.handleSetForm1Value} errormessage={this.state.sale_of_other_asset_errors.sales_consideration} />
                            <Grid container className="grid_container">
                                <Grid className="align_flex" item lg={4} sm={12} md={4}>
                                    <Typography className="font_style-1">Cost of acquisition</Typography>
                                </Grid>
                                <Grid item lg={8} sm={12} md={8}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={12} className="OCA_textfield_error_container width_260">
                                            <Box className="OCA_textfield_box">
                                                <Grid className="OCA_ruppee">
                                                    <span>₹</span>
                                                </Grid>
                                                <CustomTextfield data-testid="cost_of_acquisition" className="form_textfield" variant="outlined" placeholder="00" name="cost_of_acquisition" value={this.state.sale_of_other_asset.cost_of_acquisition} onChange={(e) => this.handleSetForm1Value(e)} />
                                            </Box>
                                            {this.state.sale_of_other_asset_errors.cost_of_acquisition && <Box className="error_message">{this.state.sale_of_other_asset_errors.cost_of_acquisition}</Box>}
                                        </Grid>
                                        <Grid item lg={6} md={12}>
                                            <CustomCalander data-testid="cost_of_acquisition_date" calanderIcon={calanderIcon} name="cost_of_acquisition_date" handleGetDate={this.handleGetDate} />
                                            {this.state.sale_of_other_asset_errors.cost_of_acquisition_date && <Box className="error_message">{this.state.sale_of_other_asset_errors.cost_of_acquisition_date}</Box>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container className="grid_container">
                                <Grid className="align_flex" item lg={4} sm={12} md={4}>
                                    <Typography className="font_style-1">Cost of improvemen</Typography>
                                </Grid>
                                <Grid item lg={8} sm={12} md={8}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={12} className="OCA_textfield_error_container width_260">
                                            <Box className="OCA_textfield_box">
                                                <Grid className="OCA_ruppee">
                                                    <span>₹</span>
                                                </Grid>
                                                <CustomTextfield data-testid="cost_of_improvement" className="form_textfield" variant="outlined" placeholder="00" name="cost_of_improvement" value={this.state.sale_of_other_asset.cost_of_improvement} onChange={(e) => this.handleSetForm1Value(e)} />
                                            </Box>
                                            {this.state.sale_of_other_asset_errors.cost_of_improvement && <Box className="error_message">{this.state.sale_of_other_asset_errors.cost_of_improvement}</Box>}
                                        </Grid>
                                        <Grid item lg={6} md={12}>
                                            <CustomCalander data-testid="cost_of_improvement_date" calanderIcon={calanderIcon} name="cost_of_improvement_date" handleGetDate={this.handleGetDate} />
                                            {this.state.sale_of_other_asset_errors.cost_of_improvement_date && <Box className="error_message">{this.state.sale_of_other_asset_errors.cost_of_improvement_date}</Box>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <ReturnGrid data-testid="transfer_expenses" title={"Transfer Expenses"} name="transfer_expenses" value={this.state.sale_of_other_asset.transfer_expenses} handleSetValue={this.handleSetForm1Value} errormessage={this.state.sale_of_other_asset_errors.transfer_expenses} />
                            <ReturnGrid data-testid="capital_gain" title={"Capital Gains"} name="capital_gain" value={this.state.sale_of_other_asset.capital_gain} handleSetValue={this.handleSetForm1Value} errormessage={this.state.sale_of_other_asset_errors.capital_gain} />
                        </Box>}
                        {this.state.activeAsset === 2 && <Box>
                            <Typography className="OCA_title--1">In case amount was deposited in <span className="text_bold">Capital Gains Scheme</span> account</Typography>
                            <Grid container className="grid_container">
                                <Grid className="align_flex" item lg={4} sm={12} md={4}>
                                    <Typography className="font_style-1">Year in which deduction was claimed</Typography>
                                </Grid>
                                <Grid item lg={8} sm={12} md={8}>
                                    <RenderDropdown data-testid="deduction_caimed_year" handleGetValue={this.handleGetDeductionClaimedYear} />
                                    {this.state.reversal_of_capital_gain_errors.deduction_claimed_year && <Box className="error_message">{this.state.reversal_of_capital_gain_errors.deduction_claimed_year}</Box>}
                                </Grid>
                            </Grid>
                            <Grid container className="grid_container">
                                <Grid className="align_flex" item lg={4} sm={12} md={4}>
                                    <Typography className="font_style-1">Section under which deduction was claimed</Typography>
                                </Grid>
                                <Grid item lg={8} sm={12} md={8}>
                                    <RenderDropdown data-testid="deduction_claimed_section" handleGetValue={this.handleGetDeductionClaimedSection} />
                                    {this.state.reversal_of_capital_gain_errors.deduction_claimed_section && <Box className="error_message">{this.state.reversal_of_capital_gain_errors.deduction_claimed_section}</Box>}
                                </Grid>
                            </Grid>
                            <Grid container className="grid_container">
                                <Grid className="align_flex" item lg={4} sm={12} md={4}>
                                    <Typography className="font_style-1">Year in which new asset acquired</Typography>
                                </Grid>
                                <Grid item lg={8} sm={12} md={8}>
                                    <CustomCalander data-testid="asset_required_year" calanderIcon={calanderIcon} name="asset_required_year" handleGetDate={this.handleGetDate} />
                                    {this.state.reversal_of_capital_gain_errors.asset_required_year && <Box className="error_message">{this.state.reversal_of_capital_gain_errors.asset_required_year}</Box>}
                                </Grid>
                            </Grid>
                            <ReturnGrid data-testid="amount_utilized" title={"Amount utilised"} name="amount_utilized" value={this.state.reversal_of_capital_gain.amount_utilized} handleSetValue={this.handleSetForm2Value} errormessage={this.state.reversal_of_capital_gain_errors.amount_utilized} />
                            <ReturnGrid data-testid="amount_not_utilized" title={"Amount not utilised"} name="amount_not_utilized" value={this.state.reversal_of_capital_gain.amount_not_utilized} handleSetValue={this.handleSetForm2Value} errormessage={this.state.reversal_of_capital_gain_errors.amount_not_utilized} />
                            <Typography className="OCA_title--1">In any other case</Typography>
                            <ReturnGrid data-testid="amount_deemed_as_capital_gain" title={"Amount deemed as Capital Gains"} name="amount_deemed_as_capital_gain" value={this.state.reversal_of_capital_gain.amount_deemed_as_capital_gain} handleSetValue={this.handleSetForm2Value} errormessage={this.state.reversal_of_capital_gain_errors.amount_deemed_as_capital_gain} />

                        </Box>}
                        {this.state.activeAsset === 3 && <Box>
                            <Typography className="OCA_title--1">Long Term</Typography>
                            {LongTerm.map((data) => {
                                return (
                                    <ReturnGrid data-testid="test_LongTerm" key={data.name} title={data.title} name={data.name} handleSetValue={this.handleSetForm3Value} errormessage={data.error} />
                                )
                            })}
                            <Typography className="OCA_title--1">Short Term</Typography>
                            {ShortTerm.map((data) => {
                                return (
                                    <ReturnGrid data-testid="test_ShortTerm" key={data.name} title={data.title} name={data.name} handleSetValue={this.handleSetForm3Value} errormessage={data.error} />
                                )
                            })}
                        </Box>
                        }
                    </Card>}
                    {this.state.activeAsset > 0 && this.state.activeAsset <= 3 && <Button variant="contained" data-testid="submitForm" className="OCA_save_button" onClick={(e) => this.handleSubmitForm(e, this.state.activeAsset)}>Save</Button>}
                    <SuccessDialog open={this.state.activeAsset === 4}>
                        <img className="capital_success_icon" src={infoColorIcon} />
                        <Typography className="S_text-2">Our software does not support these cases as of now.</Typography>
                        <Typography className="S_text-1">We recommend seeking expert advise for these cases.</Typography>
                        <Button data-testid="continue_test" className="S_button" onClick={() => this.handleSetActiveAsset(0)}>Continue</Button>
                    </SuccessDialog>
                </Box >
                <Snackbar
                    TransitionComponent={Slide}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.alertMessageShow}
                    autoHideDuration={4000}
                    onClose={this.handleCloseMessageAlert}
                >
                    <Alert severity={this.state.alertType}>
                        {this.state.alertMessage}
                    </Alert>
                </Snackbar>
            </MainWrapper >
        );
    }
}

const MainWrapper = styled(Container)(({ theme }) => ({
    width: "100%",
    "& .OCA_body": {
        backgroundColor: "#F4F6F5",
        padding: "30px",
        borderRadius: "16px"
    },
    "& .OCA_image_box": {
        width: "236px",
        height: "144px",
        borderRadius: "12px",
        backgroundColor: "#F4F6F5",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "20px",
        flexShrink: 0
    },
    "& .OCA_form_card": {
        padding: '35px',
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        marginBottom: "20px",
        boxShadow: "none",
        width: "95%",
        minHeight: "172px",
        cursor: "pointer !important",
        "&:hover": {
            border: "2px solid #337152"
        }
    },
    "& .OCA_detail--title": {
        color: "#36403B",
        fontFamily: "Nunito",
        fontSize: "18px",
        fontStyle: 'normal',
        fontWeight: 700
    },
    "& .OCA_heading": {
        display: "flex",
        marginBottom: "30px",
        "&>img": {
            marginRight: "30px",
            cursor: "pointer"
        }
    },
    "& .OCA_heading--text": {
        color: "#36403B",
        fontFamily: "Nunito",
        fontSize: "27px",
        fontStyle: "normal",
        fontWeight: 700
    },
    "& .grid_container": {
        width: "90%",
        marginBottom: "35px"
    },
    "& .align_flex": {
        display: "flex",
        alignItems: "center",
        marginBottom: "0px"
    },
    "& .font_style-1": {
        color: "rgba(54, 64, 59, 0.70)",
        fontFamily: "Nunito",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600
    },
    "& .OCA_card": {
        borderRadius: "16px",
        padding: "35px",
        overflow: "visible"
    },
    "& .OCA_ruppee": {
        width: "54px",
        height: "48px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "8px 0px 0px 8px",
        backgroundColor: "#DEF5EA",
        zIndex: 1,
        marginRight: "-6px",
        flexShrink: 0
    },
    "& .OCA_textfield_box": {
        display: "flex",
        position: "relative"
    },
    "& .OCA_field_and_calander": {
        display: "flex",
        gap: "40px"
    },
    "& .OCA_downArrow_img": {
        position: "absolute",
        right: "15px",
        bottom: "20px",
        zIndex: 1
    },
    "& .OCA_autocomplete_box": {
        position: "relative"
    },
    "& .OCA_title--1": {
        color: "rgba(0, 0, 0, 0.85)",
        fontFamily: "Nunito",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        marginBottom: "30px"
    },
    "& .text_bold": {
        fontWeight: 600
    },
    "& .OCA_save_button": {
        width: "198px",
        height: "46px",
        backgroundColor: "#337152",
        color: "white",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontWeight: 600,
        borderRadius: "10px",
        marginTop: "30px",
        marginBottom: "40px"
    },
    [theme.breakpoints.down('sm')]: {
        "& .align_flex": {
            marginBottom: "15px"
        },
        "& .grid_container": {
            width: "100%",
            marginBottom: "20px"
        },
        "& .rmdp-input--1": {
            maxWidth: "290px"
        }
    },
    [theme.breakpoints.between(0, 900)]: {
        "& .OCA_form_card": {
            flexDirection: "column"
        },
        "& .OCA_image_box": {
            marginBottom: "20px",
            marginRight: "0px"
        },
    },
    "& .error_message": {
        color: "red",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontWeight: 600,
        marginTop: "5px",
        marginBottom: "-10px",
        marginLeft: "5px",
    },
    "& .OCA_textfield_error_container": {
        display: "flex",
        maxWidth: "260px",
        position: "relative",
        flexDirection: "column"
    },
    "& .width_260": {
        maxWidth: "260px"
    }
}));
