import React from "react";
// Customizable Area Start
import {
    Box,
    withStyles,
    Button,
    TextField,
    Input,
    Typography,
    Grid,
    Snackbar,
    Slide
}
    from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import OtherIncomesController, { Props, } from "./OtherIncomesController.web";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { deleteRed } from "./assets";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

export class OtherInc extends OtherIncomesController {
    constructor(props: Props) {
        super(props);
    }

    commonGrid(title: any, testID: any, value: any, errormessage: any) {

        const { classes } = this.props
        return (
            <Grid container spacing={2} style={{ padding: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                <Grid sm={6} item>
                    <Typography className={classes.descField}>{title}</Typography>

                </Grid>
                <Grid sm={6} item>
                    <Box className={classes.amount} style={{ position: "relative" }}>
                        <Box className={classes.ruppeslogo}>
                            <Typography className={classes.rupeessstyles11}>₹</Typography>
                        </Box>
                        <TextField
                            className={classes.textfiled}
                            variant="outlined"
                            placeholder='00'
                            type="text"
                            value={value}
                            onChange={(e: any) => this.handleChange(testID, e.target.value)}
                            data-test-id={`commonInput-${testID}`}
                        />
                        {errormessage && <Box className={classes.error_message}>{errormessage}</Box>}
                    </Box>
                </Grid>
            </Grid>
        )
    }
    renderDescriptionError(index: any, classes: any) {
        return this.state.otherIncomeDescError.map((element: any, key: any) => {
            if (index === element.index && element?.index !== undefined) {
                return <Box className={classes.error_message_2} key={key}>{element.error}</Box>
            }
        })
    }
    renderAmountError(index: any, classes: any) {
        return this.state.otherIncomeAmtError.map((element: any, key: any) => {
            if (index === element.index) {
                return <Box className={classes.error_message_2} key={key}>{element.error}</Box>
            }
        })
    }



    render() {

        const { classes } = this.props
        return (
            <Box style={{ padding: '10px 20px' }}>

                <Typography className={classes.descField} style={{ fontSize: 22, marginBottom: 15 }}>Any Other Income </Typography>

                {this.state.otherInc.map((item: any, index: any) => (
                    <div key={index}>
                        <Grid container spacing={2} >
                            <Grid sm={6} item>
                                <Typography className={classes.descField} >Description</Typography>
                                <Input placeholder="Enter" value={item.description} onChange={(e: any) => this.handleOtherIncChangeDesc(index, e.target.value)} disableUnderline className={classes.input} data-test-id='description' style={{ height: '2.5rem' }} />
                                {this.renderDescriptionError(index, classes)}
                            </Grid>
                            <Grid sm={4} item>
                                <Box>
                                    <Typography className={classes.descField}>Amount</Typography>
                                    <Box className={classes.amount}>
                                        <Box className={classes.ruppeslogo}>
                                            <Typography>₹</Typography>
                                        </Box>
                                        <Input type="text" value={item.amount} onChange={(e: any) => this.handleOtherIncChangeAmt(index, e.target.value)} disableUnderline placeholder='00' data-test-id='amount' className={classes.inputAmountHalf} />
                                    </Box>
                                </Box>
                                {this.renderAmountError(index, classes)}
                            </Grid>
                            <Grid sm={2} item style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                                {this.state.otherInc.length > 1 && index !== 0 && (
                                    <img
                                        data-test-id={'deleteFieldInc' + index}
                                        src={deleteRed}
                                        onClick={() => this.deleteFieldInc(index)}
                                        style={{ cursor: 'pointer', marginTop: '1rem', paddingRight: '2rem' }}
                                    />
                                )}
                            </Grid>

                        </Grid>
                    </div>
                ))}
                <div data-test-id='addFieldIncome' onClick={() => this.addFieldIncome()} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', margin: '1rem 0 2rem 0' }}>

                    <AddCircleIcon style={{ color: '#337152', margin: '0 5px 0 -10px' }} />
                    <Typography className={classes.addMore}>Add More Items</Typography>
                </div>
                <Box className={classes.mainDrop}>
                    <Typography className={classes.descField}>Gift tax under section 56</Typography>
                    <div className={classes.expandImg}>
                        {!this.state.giftDrop ? <ExpandMoreIcon onClick={() => this.setState({ giftDrop: true })} data-test-id='giftDrop' />
                            : <ExpandLessIcon onClick={() => this.setState({ giftDrop: false })} data-test-id='giftDrop1' />
                        }
                    </div>
                </Box>
                <Box className={classes.mainDrop} style={{ display: this.state.giftDrop ? 'block' : 'none' }}>
                    {this.commonGrid('Sum of money received without consideration (excess of Rs. 50,000)', 'giftSumOfMoney', this.state.giftSumOfMoney, this.state.otherIncomeErrorObj.giftSumOfMoney)}
                    {this.commonGrid('Stamp duty value of Immovable property received without consideration', 'giftImmovable', this.state.giftImmovable, this.state.otherIncomeErrorObj.giftImmovable)}
                    {this.commonGrid('Stamp Duty Value - Consideration Paid (if immovable property received for inadequate consideration)', 'giftConsideration', this.state.giftConsideration, this.state.otherIncomeErrorObj.giftConsideration)}
                    {this.commonGrid('Fair market value of any other type of property received without consideration', 'giftFairMarket', this.state.giftFairMarket, this.state.otherIncomeErrorObj.giftFairMarket)}
                    {this.commonGrid('Fair Market Value - Consideration paid (if any other property received for inadequate consideration)', 'giftFairConsideration', this.state.giftFairConsideration, this.state.otherIncomeErrorObj.giftFairConsideration)}
                </Box>
                <Box className={classes.mainDrop}>
                    <Typography className={classes.descField}>Unexplained Income</Typography>
                    <div className={classes.expandImg}>
                        {!this.state.unexplainedDrop ? <ExpandMoreIcon onClick={() => this.setState({ unexplainedDrop: true })} data-test-id='unexplainedDrop' />
                            : <ExpandLessIcon onClick={() => this.setState({ unexplainedDrop: false })} data-test-id='unexplainedDrop1' />
                        }
                    </div>
                </Box>
                <Box className={classes.mainDrop} style={{ display: this.state.unexplainedDrop ? 'block' : 'none' }}>
                    {this.commonGrid('Cash Credits Under Section 68', 'unexpCashCredits', this.state.unexpCashCredits, this.state.otherIncomeErrorObj.unexpCashCredits)}
                    {this.commonGrid('Unexplained Investment Under Section 69', 'unexpSection69', this.state.unexpSection69, this.state.otherIncomeErrorObj.unexpSection69)}
                    {this.commonGrid('Unexplained Money Under Section 69A', 'unexpSection69A', this.state.unexpSection69A, this.state.otherIncomeErrorObj.unexpSection69A)}
                    {this.commonGrid('Undisclosed Investment  Under Section 69B', 'unexpSection69B', this.state.unexpSection69B, this.state.otherIncomeErrorObj.unexpSection69B)}
                    {this.commonGrid('Unexplained Expenditure Under Section 69C', 'unexpSection69C', this.state.unexpSection69C, this.state.otherIncomeErrorObj.unexpSection69C)}
                    {this.commonGrid('Amount Borrowed Repaid on Hundi Under Section 69D', 'unexpSection69D', this.state.unexpSection69D, this.state.otherIncomeErrorObj.unexpSection69D)}
                </Box>
                <Box className={classes.mainDrop}>
                    <Typography className={classes.descField}>Income from race horses</Typography>
                    <div className={classes.expandImg}>
                        {!this.state.raceHorseDrop ? <ExpandMoreIcon onClick={() => this.setState({ raceHorseDrop: true })} data-test-id='raceHorseDrop' />
                            : <ExpandLessIcon onClick={() => this.setState({ raceHorseDrop: false })} data-test-id='raceHorseDrop1' />
                        }
                    </div>
                </Box>
                <Box className={classes.mainDrop} style={{ display: this.state.raceHorseDrop ? 'block' : 'none' }}>
                    {this.commonGrid('Receipts', 'incRaceHorseReceipts', this.state.incRaceHorseReceipts, this.state.otherIncomeErrorObj.incRaceHorseReceipts)}
                    {this.commonGrid('Deductions Under Section 57', 'incRaceHorseSection57', this.state.incRaceHorseSection57, this.state.otherIncomeErrorObj.incRaceHorseSection57)}
                    {this.commonGrid('Amounts Not Deductible Under Section 58', 'incRaceHorseSection58', this.state.incRaceHorseSection58, this.state.otherIncomeErrorObj.incRaceHorseSection58)}
                    {this.commonGrid('Profits Chargeable to Tax Under Section 59', 'incRaceHorseSection59', this.state.incRaceHorseSection59, this.state.otherIncomeErrorObj.incRaceHorseSection59)}
                    {this.commonGrid('Net Income from Race Horses', 'incRaceHorseRaceHorse', this.state.incRaceHorseRaceHorse, this.state.otherIncomeErrorObj.incRaceHorseRaceHorse)}
                </Box>
                <Typography className={classes.descField} style={{ fontSize: 22, marginBottom: 15 }}>Pass through income</Typography>
                <Grid container spacing={2} style={{ marginBottom: "1rem" }}>
                    <Grid sm={6} item style={{ position: "relative" }}>
                        <Typography className={classes.descField}>Description</Typography>
                        <Input placeholder="Enter" disableUnderline className={classes.input} value={this.state.passThroughInc.desc} onChange={(e) => this.handleSetpassThroughIncDesc(e)} data-test-id='description1' style={{ height: '2.5rem' }} />
                        {this.state.otherIncomeErrorObj.passThroughInDesc && <Box className={classes.error_message_1}>{this.state.otherIncomeErrorObj.passThroughInDesc}</Box>}
                    </Grid>
                    <Grid sm={4} item style={{ position: "relative" }}>
                        <Typography className={classes.descField}>Amount</Typography>
                        <Box className={classes.amount}>
                            <Box className={classes.ruppeslogo}>
                                <Typography>₹</Typography>
                            </Box>
                            <Input disableUnderline placeholder='00' data-test-id='amount1' type="text"
                                value={this.state.passThroughInc.amt} className={classes.inputAmountHalf}
                                onChange={(e) => { this.handleSetpassThroughIncAmt(e) }} />
                            {this.state.otherIncomeErrorObj.passThroughIncAmt && <Box className={classes.error_message_1}>{this.state.otherIncomeErrorObj.passThroughIncAmt}</Box>}
                        </Box>
                    </Grid>
                    <Grid item sm={2} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'right' }}>
                        <img src={deleteRed} data-test-id='deletePassThroughInc' onClick={() => this.deletePassThroughInc()} style={{ cursor: 'pointer', marginTop: '1.7rem', paddingRight: '2rem' }} />
                    </Grid>
                </Grid>
                {this.commonGrid('Pension from PMVVY/Any Other Scheme', 'pension', this.state.pension, this.state.otherIncomeErrorObj.pension)}
                {this.commonGrid('Pension from Family ', 'familyPension', this.state.familyPension, this.state.otherIncomeErrorObj.familyPension)}
                <Button onClick={this.saveOtherInc} data-test-id='saveBtn'
                    style={{ borderRadius: 8, background: '#337152', color: 'white', width: '25%', fontFamily: 'Nunito', fontSize: 16, fontWeight: 600 }}
                >Save</Button>

                <Snackbar
                    TransitionComponent={Slide}
                    autoHideDuration={4000}
                    anchorOrigin={{
                        horizontal: 'center',
                        vertical: 'top',
                    }}
                    open={this.state.snack ? this.state.snack : this.state.snackError}
                    onClose={this.handleMessageClose}
                >
                    <Alert data-testid="alert_test" severity={this.state.errorType}>
                        {this.state.errorType === "success" ? "Saved Succesfully" : "Some information is missing or incorrect"}
                    </Alert>
                </Snackbar>
            </Box>
        )
    }
}

const styles = {
    expandImg: {
        background: '#F4F6F5',
        padding: 10,
        borderRadius: 10,
    },
    mainDrop: {
        display: 'flex',
        border: '0.5px solid #C3CAD9',
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '5px 20px',
        margin: '5px 0'
    },
    descField: {
        color: 'rgba(54, 64, 59, 0.70)',
        fontFamily: 'Nunito',
        fontSize: 18,
        fontStyle: 'normal',
        fontWeight: 600
    },
    subHeading: {
        color: '#242B28',
        fontFamily: 'Nunito',
        fontSize: 20,
        fontStyle: 'normal',
        fontWeight: 600,
        margin: '1rem 0rem'
    },
    textfiled: {
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
                borderColor: '#c3cad9',
            },
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: "0px 8px 8px 0px",
                borderLeft: 'none',
            },
        },
        "& input": {
            border: "1px solid #grey",
            padding: "11px 0px 11px 12px",
        },
    },
    addMore: {
        color: '#000',
        fontFamily: 'Nunito',
        fontSize: 18,
        fontStyle: 'normal',
        fontWeight: 600
    },
    input: {
        border: ' 0.5px solid #C3CAD9',
        borderRadius: 8,
        "& .MuiInputBase-input": {
            padding: "11px 0px 11px 12px"
        }
    },
    ruppeslogo: {
        background: "#ddfef7",
        padding: '1em 1.7em 1em 1em',
        height: '42px',
        borderRadius: "8px 0px 0px 8px",
        display: "flex",
        alignItems: "center"
    },
    rupeessstyles11: {
        color: "rgba(54,64,59,0.9)",
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Nunito",
    },
    amount: {
        display: "flex",
    },
    inputAmountHalf: {
        border: ' 0.5px solid #C3CAD9',
        borderRadius: '0px 8px 8px 0px',
        borderLeft: 'none',
        "& .MuiInputBase-input": {
            padding: "11px 0px 11px 12px"
        }
    },
    error_message: {
        color: "red",
        position: "absolute",
        top: "50px",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontWeight: 600,
    } as any,
    error_message_1: {
        color: "red",
        position: "absolute",
        top: "80px",
        fontFamily: "Nunito",
        fontSize: "16px",
        width: "215px",
        fontWeight: 600,
    } as any,
    error_message_2: {
        color: "red",
        fontFamily: "Nunito",
        fontSize: "16px",
        width: "215px",
        fontWeight: 600,
    }



}


export default withStyles(styles)(OtherInc);
// Customizable Area End