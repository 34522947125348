import React from "react";
// Customizable Area Start
import {
  Box, Switch,
  withStyles,
  Button, TextField,
  Input, Snackbar, Slide,
  Typography, Grid,
  styled
}
  from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import OtherIncomesController, { Props, } from "./OtherIncomesController.web";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { deleteRed } from "./assets";


const CustomSwitch = styled(Switch)({
  padding: 8,
  "&.MuiSwitch-root": {
    width: "67px",
    height: "40px",
  },
  "& .MuiSwitch-colorSecondary.Mui-checked": {
    color: "white !important"
  },
  "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#347054",
  },
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    opacity: '1 !important',
    backgroundColor: "rgb(143, 179, 161)",
    '&::before': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
      left: 12,
    },
    '&::after': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 20,
    height: 20,
    margin: 1
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    transform: "translateX(27px)"
  },
  "& .error_message": {
    color: "red",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    marginTop: "5px",
    marginBottom: "-10px",
    marginLeft: "5px",
  }
})
export class OtherDividend extends OtherIncomesController {
  constructor(props: Props) {
    super(props);
  }

  commonGridFn1(title: any, state: any, classes: any) {
    
    return (
      <Grid container spacing={2} className={classes.dividendGrid}>
        <Grid sm={6} item>
          <Typography className={classes.descField}>{title}</Typography>

        </Grid>
        <Grid sm={6} item>
          <Box className={classes.amount}>
            <Box className={classes.ruppeslogo}>
              <Typography className={classes.rupeessstyles11}>₹</Typography>
            </Box>
            <TextField
              data-testid="divident"
              className={classes.textfiled} variant="outlined"
              placeholder='00'
              value={this.state.dividend} data-test-id={`commonInput-${state}`}
              onChange={this.handleDivident}
            />
          </Box>
          {
            this.state.dividendError &&
            <Box className={classes.error_message}>{this.state.dividendError}</Box>
          }
        </Grid>
      </Grid>
    )
  }

  periods(state:any, value:any, amtValue:any, amtName:any) {
    const { classes } = this.props;
    const availableOptions = this.getAvailableOptions(value);

    return (
      <Grid spacing={2} style={{ padding: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} container>
        <Grid sm={6} item>
          <Typography className={classes.descField}>Periods</Typography>
          <select
            name={state}
            id={state}
            autoComplete="off"
            data-test-id={`period-${state}`}
            value={value}
            onChange={this.handleOnChange}
            className={classes.drops1}
          >
            {/* <option value="" disabled>Select</option> */}
            {availableOptions.map((item) => (
              <option key={item} value={item}>{item}</option>
            ))}
          </select>
        </Grid>
        <Grid item sm={6}>
          <Typography className={classes.descField}>Amount</Typography>
          <Box className={classes.amount}>
            <Box className={classes.ruppeslogo}>
              <Typography className={classes.rupeessstyles11}>₹</Typography>
            </Box>
            <TextField
              className={classes.textfiled}
              variant="outlined"
              placeholder='00'
              value={amtValue}
              data-test-id={amtName}
              onChange={(e) => this.handleSetDividentValue(amtName, e.target.value)}
            />
          </Box>
          {this.state.otherDividentAmount[amtName] &&
            <Box className={classes.error_message}>{this.state.otherDividentAmount[amtName]}</Box>
          }
        </Grid>
      </Grid>
    );
  }



  render() {

    const { classes } = this.props
    const handlealertFieldError = () => {
      const {alertFieldError} = this.state;
      if (alertFieldError){
        return 'Saved successfully';
      } else {
        return 'Something went wrong';
      }
    }
    return (
      <>
        <Box style={{ padding: '10px 20px' }}>
          {this.commonGridFn1('Dividend', 'dividend',classes)}

          <Box style={{
            display: this.state.isDividend ? 'flex' : 'none', justifyContent: 'space-between', borderRadius: 10, border: '1px solid #337152', background: '#E5F7EE'
          }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography className={classes.greenText} style={{ width: '80%', padding: 10 }}>I want to divide the total dividend income into different quarters on the basis of date of receipt</Typography>
              <div style={{ width: '20%', marginLeft: 'auto', marginRight: 0 }}>
                <CustomSwitch
                  data-test-id="switch"
                  checked={this.state.divideDividends}
                  onChange={(e: any) => this.setState({ divideDividends: e.target.checked, dividendPeriod1: '', dividendPeriod2: '', dividendPeriod3: '', dividendPeriod4: '', dividendPeriod5: '', dividendValue1: '', dividendValue2: '', dividendValue3: '', dividendValue4: '', dividendValue5: '' })}
                  name="checkedB"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </div>
            </div>
          </Box>

          <Snackbar TransitionComponent={Slide} anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }} open={this.state.snack ? this.state.snack : this.state.snackError} autoHideDuration={4000} onClose={this.handleMessageClose} >
            <Alert severity={this.state.alert ? "success" : "error"}>
            { this.state.alertRequired ? "At least one field is required" : handlealertFieldError() }
            </Alert>
          </Snackbar>
          {this.state.divideDividends && this.periods('dividendPeriod1', this.state.dividendPeriod1, this.state.dividendValue1, 'dividendValue1')}
          {this.state.divideDividends && this.periods('dividendPeriod2', this.state.dividendPeriod2, this.state.dividendValue2, 'dividendValue2')}
          {this.state.divideDividends && this.periods('dividendPeriod3', this.state.dividendPeriod3, this.state.dividendValue3, 'dividendValue3')}
          {this.state.divideDividends && this.periods('dividendPeriod4', this.state.dividendPeriod4, this.state.dividendValue4, 'dividendValue4')}
          {this.state.divideDividends && this.periods('dividendPeriod5', this.state.dividendPeriod5, this.state.dividendValue5, 'dividendValue5')}
          <Typography className={classes.subHeading}>Expenses</Typography>
          {this.state.divExpenses.map((item: any, index: any) =>
          (
            <div key={index}>
              <Grid spacing={2} container>
                <Grid sm={5} item>
                  <Typography className={classes.descField}>Description</Typography>
                  <Input value={item.description} disableUnderline className={classes.input} data-test-id={`description${index}`} style={{ height: '2.5rem' }}
                    onChange={(e: any) => this.divExpensesDesc(index, e.target.value)} fullWidth placeholder="Enter" />
                  {
                    this.state.divExpenses[index].descError &&
                    <Box className={classes.error_message}>{this.state.divExpenses[index].descError}</Box>
                  }
                </Grid>
                <Grid sm={5} item>
                  <Typography className={classes.descField}>Amount</Typography>
                  <Box className={classes.amount}>
                    <Box className={classes.ruppeslogo}>
                      <Typography className={classes.rupeessstyles11}>₹</Typography>
                    </Box>
                    <TextField
                      className={classes.textfiled} variant="outlined" placeholder='00'  data-test-id={`amount${index}`}
                      onChange={(e: any) => this.divExpensesAmt(index, e.target.value)} value={item.amount} />
                  </Box>
                  {
                  this.state.divExpenses[index].error &&
                  <Box className={classes.error_message}>{this.state.divExpenses[index].error}</Box>
                  }
                </Grid>
                <Grid sm={2} item style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'right' }}>
                  <img src={deleteRed} data-test-id={`delete${index}`} onClick={() => this.deleteFieldExp(index)} style={{ cursor: 'pointer', marginTop: '1.7rem', paddingRight: '2rem' }} />
                </Grid>
              </Grid>
            </div>
          )

          )}
          <div style={{ display: 'flex', margin: '1rem 0 2rem 0', alignItems: 'center', cursor: 'pointer' }} data-testid="addExpense_test" onClick={() => this.addExpFields()}>

            <AddCircleIcon style={{ margin: '0 5px 0 -10px', color: '#337152', }} />
            <Typography className={classes.addMore1}>Add More Items</Typography>
          </div>
          <Button data-test-id='saveBtn' onClick={() => this.saveDividend()}
            style={{ borderRadius: 8, background: '#337152', color: 'white', width: '25%', fontFamily: 'Nunito', fontSize: 16, fontWeight: 600 }}
          >Save</Button>
        </Box>
      </>
    )
  }
  CheckotherDividentAmount() {
    throw new Error("Method not implemented.");
  }
}

const styles = {
  descField: {
    color: 'rgba(54, 64, 59, 0.70)',
    fontFamily: 'Nunito',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 600
  },
  dividendGrid: {
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem'
  },
  drops1: {
    fontFamily: 'Nunito',
    fontSize: '16px',
    padding: '8px',
    borderRadius: '8px',
    border: '1px solid #ccc',
    width: '100%',
    backgroundColor: 'white'
  },
  addMore1: {
    color: '#000',
    fontFamily: 'Nunito',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 600
  },
  subHeading: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontSize: 20,
    margin: '1rem 0rem',
    color: '#242B28',
    fontWeight: 600,
  },
  input: {
    borderRadius: 8,
    border: ' 0.5px solid #C3CAD9',
    padding: 8,
    "& .MuiInputBase-input": {
      paddingLeft: "10px"
    }
  },
  inputAmountHalf: {
    border: ' 0.5px solid #C3CAD9',
    borderRadius: '0px 8px 8px 0px',
    borderLeft: 'none',
  },
  greenText: {
    fontFamily: 'Nunito',
    color: '#337152',
    fontStyle: 'normal',
    fontSize: 18,
    fontWeight: 500
  },
  amount: {
    display: "flex",
  },
  textfiled: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: "0px 8px 8px 0px",
        borderLeft: 'none',
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      '& fieldset': {
        borderColor: '#c3cad9',
      },
    },

    "& input": {
      border: "1px solid #grey",
      padding: "11px 0px 11px 17px",
    },
  },
  rupeessstyles11: {
    color: "rgba(54,64,59,0.9)",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Nunito",
  },
  ruppeslogo: {
    background: "#ddfef7",
    padding: '1em 2em 1em 1em',
    borderRadius: "8px 0px 0px 8px",
    display: "flex",
    height: '42px',
    alignItems: "center"
  },
  error_message: {
    color: "red",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    marginTop: "5px",
    marginBottom: "-10px",
    marginLeft: "5px",
  }
}


export default withStyles(styles)(OtherDividend);
// Customizable Area End