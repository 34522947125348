import React from "react";

import {
    Box,
    Button,
    Card,
    Container,
    styled,
    RadioGroup,
    Radio,
    Typography,
    Dialog,
    FormControlLabel,
    IconButton,
    Snackbar,
    Slide,List,ListItem,ListItemText
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { Close, AddCircle ,Delete as DeleteIcon, CloudDownload as DownloadIcon} from "@material-ui/icons"
import { LeftArrow, info, listedshare1, listedshare2, listedshare3, upload, search, DeleteRedIcon, edit, tooltip } from "./assets"

import CapitalGainLangingPageController, {
    Props,
} from "./CapitalGainLangingPageController.web";
import Header from "../../../components/src/Header.web";
import AddMoreSalesOfShare from "./AddMoreSalesOfShare.web";
import { CustomTextArea } from "../../../components/src/CustomTextfield.web";
import DashboardPanselector from "../../dashboard/src/DashboardPanselector.web";

const CustomDialog = styled(Dialog)({
    "& .MuiDialog-paper": {
        borderRadius: "24px",
        padding: "30px 0px"
    },
    "& .MuiDialog-paperWidthSm": {
        maxWidth: "924px",
        width: "924px",
        "&>div": {
            overflowY: "scroll",
            height: "800px",
            "&>div": {
                padding: "0px 30px"
            }
        }
    },
    "& .IS_header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    "& .IS_header--text": {
        color: "#36403B",
        fontFamily: "Nunito",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
    },
    "& .IS_header--icon": {
        transform: "scale(1.2)",
        color: "#337152"
    },
    "& .btn": {
        width: "250px",
        height: "50px",
        borderRadius: "10px",
        fontFamily: "Nunito",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600,
        color: "#858585",
        marginRight: "35px"
    },
    "& .btn_active": {
        backgroundColor: "#337152",
        color: "#ffffff !important"
    },
    "& .btn_group": {
        margin: "30px 0px"
    },
    "& .MuiFormGroup-root": {
        flexDirection: "row"
    },
    "& .MuiSvgIcon-root": {
        fontSize: "1.7rem"
    },
    "& .MuiFormControlLabel-label": {
        color: "rgba(54, 64, 59, 0.70)",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
    },
    "& .Mui-checked": {
        color: "#337152 !important"
    },
    "& .MuiRadio-root": {
        color: "#85988F"
    },
    "& .MuiFormControlLabel-root": {
        marginRight: "50px"
    },
    "& .IS_document": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "50px",
        "&>p": {
            color: "#337152",
            fontFamily: "Nunito",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            marginBottom: "30px"
        },
        "&>button": {
            width: "171px",
            height: "46px",
            borderRadius: "8px",
            backgroundColor: "#FFF5DC"
        }
    },
    "& .IS_document--box": {
        width: "518px",
        height: "193px",
        borderRadius: "8px",
        border: " 1px dashed #2B664B",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "20px"

    },
    "& .need_help": {
        color: "#C7AA61",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
    },
    "& .file_formate": {
        color: "rgba(54, 64, 59, 0.70)",
        fontFamily: "Nunito",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600
    },
    "& .upload_icon": {
        marginTop: "40px",
        marginBottom: "20px"
    },
    "& .IS_line": {
        height: "2px",
        backgroundColor: "rgba(152, 152, 152, 0.50)",
        width: "100%",
        marginTop: "30px"
    },
    "& .IS_dounload--button": {
        borderRadius: "8px",
        border: "1px solid #337152",
        width: "183px",
        height: "46px",
        color: "#337152",
        textAlign: "center",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600
    },
    "& .IS_dounload--box": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "30px"
    },
    "& .IS_dounload--text": {
        color: "#36403B",
        fontFamily: "Nunito",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 600
    },
    "& .btn-small": {
        width: "198px",
        height: "46px",
        borderRadius: "10px",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        backgroundColor: "#337152",
        color: "#ffffff !important",
        marginTop: "50px"
    }
})
const TableContainer = styled(Box)({
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
        height: "7.94px"
    },
    "&::-webkit-scrollbar-track": {
        background: "#D8D8D8"
    },
    "&::-webkit-scrollbar-thumb": {
        background: "#337152",
        borderRadius: "10px"
    },
    '& table': {
        borderSpacing: '0px 10px',
        width: "1730px",
    },
    '& thead th': {
        background: '#F4F6F5',
        border: '1px solid #DCDCDC',
        borderLeft: 'none',
        borderRight: 'none',
        height: "48px",
        fontSize: "20px",
        fontWeight: 400,
        color: "#36403B"
    },
    '& tbody td': {
        background: '#F4F6F5',
        border: '1px solid #DCDCDC',
        padding: 15,
        borderLeft: 'none',
        borderRight: 'none',
        textAlign: "center",
        fontSize: "20px",
        fontWeight: 400,
        color: "#36403B"
    },
    '& thead th:first-child': {
        borderTopLeftRadius: '15px',
        borderLeft: '1px solid #DCDCDC',
    },
    '& thead th:last-child': {
        borderTopRightRadius: '15px',
        borderRight: '1px solid #DCDCDC'
    },
    '& tbody td:first-child': {
        borderLeft: '1px solid #DCDCDC',
    },
    '& tbody td:last-child': {
        borderRight: '1px solid #DCDCDC'
    },
})
export default class ListedShares extends CapitalGainLangingPageController {
    constructor(props: Props) {
        super(props);
    }
    ImportDetailUsingStatement = () => {
        return (
            <Box>
                <Box>
                    <Box className="IS_header">
                        <Typography className="IS_header--text">Import details using statements available with me</Typography>
                        <IconButton onClick={this.handleSaleAndSecurityPopup}>
                            <Close className="IS_header--icon" />
                        </IconButton>
                    </Box>
                    <Box>
                        <Box className="btn_group">
                            <Button className="btn btn_active" variant="outlined">Mutual Funds</Button>
                            <Button className="btn" variant="outlined">Equity Shares</Button>
                        </Box>
                        <Box>
                            <RadioGroup>
                                <FormControlLabel value="CAMS/KFIN Statements" control={<Radio />} label="CAMS/KFIN Statements" />
                                <FormControlLabel value="Broker Statements/Template" control={<Radio />} label="Broker Statements/Template" />
                            </RadioGroup>
                        </Box>
                        <Box
                            className="IS_document"
                            onDragOver={this.handleDragOver}
                            onDrop={this.handleDrop}
                            style={{ border: '2px dashed #ddd', padding: '20px', borderRadius: '5px', textAlign: 'center' }}
                        >
                            {this.state.errorMessage && (
                                <Typography color="error" style={{ margin: '10px 0' , color:"red"}}>
                                    {this.state.errorMessage}
                                </Typography>
                            )}
                            <Box className="IS_document--box">
                                <img className="upload_icon" src={upload} alt="upload_icon" />
                                <Typography style={{ fontSize: '1.13rem', fontWeight: 600, fontFamily: 'Nunito', marginBottom: '5px' }}>
                                    Drag & drop files or{' '}
                                    <span
                                        style={{ textDecoration: 'underline', color: '#2B664B', cursor: 'pointer' }}
                                        onClick={this.triggerFileInput}
                                    >
                                        Browse
                                    </span>
                                </Typography>
                                <Typography className="file_formate">Supported format - CSV/XLXS</Typography>
                                <input
                                    type="file"
                                    ref={this.fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={this.handleFileUpload}
                                    multiple
                                />
                            </Box>
                            <List>
                                {this.state.uploadedFiles.map((file:any, index:number) => (
                                    <ListItem key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <ListItemText primary={file.name} />
                                        <div>
                                            <IconButton onClick={() => this.handleDownloadFile(file)}>
                                                <DownloadIcon />
                                            </IconButton>
                                            <IconButton onClick={() => this.handleDeleteFile(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    </ListItem>
                                ))}
                            </List>
                            <Typography>
                                Capital Gains documents from CAMS and KFIN can be imported here – this will cover all your mutual funds
                            </Typography>
                            <Button variant="text" className="need_help" onClick={this.handOpenNeedHelpPopup}>
                                Need Help?
                            </Button>
                            <Box className="IS_line"></Box>
                        </Box>
                        <Box className="IS_dounload--box">
                            <Typography className="IS_dounload--text">Download our standard template</Typography>
                            <Button variant="text" className="IS_dounload--button">Download</Button>
                        </Box>
                        <Button className="btn-small">Next</Button>
                    </Box>
                </Box>
            </Box>
        )
    }


    render() {

        return (
            <MainWrapper maxWidth={false}>
                <Box className="body">
                <DashboardPanselector navigation={this.props.navigation} id={""} data-test-id="pancard" />
                    <Box className="ls_main">
                        {this.state.activeCard === 0 && <Box>
                            <Box className="ls_main-title">
                                <img data-testid="left_arrow" src={LeftArrow} onClick={() => this.props.changeTab("Capital Gains")} />
                                <Typography className="ls_body_title-text">Listed Shares, Equity Mutual Funds or Debt Mutual Funds</Typography>
                            </Box>
                            <Typography className="ls_text_box">In case of listed equity shares and equity oriented mutual funds,
                                if you have short term capital gains or long term capital gains on shares/mutual
                                funds purchased after 31.01.2018 - then a single consolidated entry can be passed
                                for your capital gains. This can save a lot of time and effort. You can find this
                                option in our manual entry menu.</Typography>
                            <Box className="ls_card_body">
                                <Card className="ls_form_card" data-testid="manual_entry" onClick={() => this.setState({ activeCard: 1 })}>
                                    <Box className="ls_image_box">
                                        <img className="capital_img" src={listedshare1} />
                                    </Box>
                                    <Box className="capital_detail">
                                        <Typography className="ls_detail--title">Add entries manually</Typography>
                                    </Box>
                                </Card>
                                <Card className="ls_form_card">
                                    <Box className="ls_image_box">
                                        <img className="capital_img" src={listedshare2} />
                                    </Box>
                                    <Box className="capital_detail">
                                        <Typography className="ls_detail--title">Import details from Income Tax Portal </Typography>
                                    </Box>
                                </Card>
                                <Card className="ls_form_card" style={{ marginBottom: "100px" }} onClick={this.handleSaleAndSecurityPopup}>
                                    <Box className="ls_image_box">
                                        <img className="capital_img" src={listedshare3} />
                                    </Box>
                                    <Box className="capital_detail">
                                        <Typography className="ls_detail--title">Import details using statements available with me</Typography>
                                    </Box>
                                </Card>

                                <CustomDialog open={this.state.SaleAndSecurityPopup}>
                                    <this.ImportDetailUsingStatement />
                                </CustomDialog>
                            </Box>
                        </Box>}
                        {this.state.activeCard === 1 && !this.state.showSalesOfShareManualForm && <Box>
                            <Box className="ls_main-title">
                                <img src={LeftArrow} data-testid="go_to_listed_share" onClick={() => { this.setState({ activeCard: 0 }) }} />
                                <Typography className="ls_body_title-text">Lorem Ipsum has been the industry's standard dummy</Typography>
                            </Box>
                            <Card className="hover_block">
                                <Box className="DL_header">
                                    <Box className="DL_Search">
                                        <CustomTextArea variant="outlined" fullWidth placeholder="Search" />
                                        <img src={search} alt="" className="DL_Search-icon" />
                                    </Box>
                                    <Button className="btn" variant="outlined">Re - Import</Button>
                                </Box>
                                <TableContainer>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Security Name</th>
                                                <th>Purchase Cost</th>
                                                <th>FMV/Indexed Cost</th>
                                                <th>Sale Consideration</th>
                                                <th>Capital Gain</th>
                                                <th>Short/Long</th>
                                                <th>Debt/Equity</th>
                                                <th>Shares/Mutual Funds</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.SaleofShareAndSecuritiesTableData.map((data: any, index: any) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index > 8 ? index + 1 : `0${index + 1}`}</td>
                                                        <td>{data.attributes.security_name}</td>
                                                        <td>₹ {data.attributes.purchase_cost.substring(0, 12)}</td>
                                                        <td>₹ {data.attributes.fmv_indexed_cost.substring(0, 12)}</td>
                                                        <td>₹ {data.attributes.sale_consideration.substring(0, 12)}</td>
                                                        <td>₹ {data.attributes.capital_gain_data.substring(0, 12)}</td>
                                                        <td>{data.attributes.short_long}</td>
                                                        <td>{data.attributes.debt_equity}</td>
                                                        <td>{data.attributes.share_mutual_funds}</td>
                                                        <td>
                                                            <Box className="action_button">
                                                                <img data-testid="edit_icon" className="edit_icon" src={edit} alt="edit_icon" onClick={() => this.handleEditRow(data.attributes.id)} />
                                                                <img data-testid="delete_test" className="delete_icon" src={DeleteRedIcon} alt="delete_icom" onClick={() => this.handleDeleteRow(data.attributes.id)} />
                                                            </Box>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </table>
                                </TableContainer>
                                <Box className="add_item">
                                    <IconButton data-testid="add_more_data" style={{ padding: "7px" }} size="medium" onClick={() => this.handleAddMoreItem()}>
                                        <AddCircle className="add_icon" />
                                    </IconButton>
                                    <Typography>Add More items</Typography>
                                    <img src={tooltip} className="tooltip_icon" alt="tooltip" />
                                </Box>
                            </Card>
                            <Button variant="contained" className="DL_btn_save" onClick={() => this.setState({ activeCard: 0 })}>Save</Button>
                        </Box>
                        }
                        {
                            this.state.showSalesOfShareManualForm && <AddMoreSalesOfShare
                                data-testid="AddMoreSalesOfShare"
                                handleValidateShareAndScurityForm={this.handleValidateShareAndScurityForm}
                                handleGetShareAndScurity={this.handleGetShareAndScurity}
                                handleGoToTable={this.handleGoToTable}
                                rowId={this.state.rowID}
                                shareAndScurity={this.state.share_and_scurity}
                                shareAndScurityError={this.state.share_and_scurity_error}
                                hanleGetShareAndScurityTableData={this.hanleGetShareAndScurityTableData}
                                handleGetShareAndScurityDropDown={this.handleGetShareAndScurityDropDown}
                            />
                        }
                        <Snackbar
                            TransitionComponent={Slide}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={this.state.alertMessageShow}
                            autoHideDuration={4000}
                            onClose={this.handleCloseMessageAlert}
                        >
                            <Alert severity={this.state.alertType}>
                                {this.state.alertMessage}
                            </Alert>
                        </Snackbar>
                    </Box>
                </Box>
            </MainWrapper>
        );
    }
}

const MainWrapper = styled(Container)({
    width: "100%",
    "& .ls_text_box": {
        backgroundColor: "#77f2b429",
        padding: "18px",
        borderRadius: "15px",
        border: "2px solid #337152",
        marginBottom: "30px",
        color: "#337152",
        fontFamily: "Nunito",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
    },
    "& .ls_main": {
        borderRadius: "16px",
        backgroundColor: "#F4F6F5",
        padding: "30px",
    },
    "& .ls_main-title": {
        display: "flex",
        marginBottom: "30px",
        "&>img": {
            marginRight: "30px",
            cursor: "pointer"
        }
    },
    "& .ls_body_title-text": {
        color: "#36403B",
        fontFamily: "Nunito",
        fontSize: "27px",
        fontStyle: "normal",
        fontWeight: 700,
    },
    "& .ls_card_body": {
    },
    "& .ls_form_card": {
        padding: '35px',
        borderRadius: "20px",
        display: "flex",
        height: "172px",
        alignItems: "center",
        marginBottom: "20px",
        boxShadow: "none",
        width: "95%",
        cursor: "pointer !important",
        "&:hover": {
            border: "2px solid #337152"
        }
    },
    "& .hover_block": {
        padding: '35px',
        borderRadius: "20px",
        alignItems: "center",
        marginBottom: "20px",
        boxShadow: "none",
        width: "95%",
    },
    "& .ls_image_box": {
        width: "200px",
        height: "110px",
        backgroundColor: "#F4F6F5",
        borderRadius: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "20px",
    },
    "& .ls_detail--title": {
        color: "#36403B",
        fontFamily: "Nunito",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        width: "465px"
    },
    "& .DL_Search": {
        width: "552px",
        position: "relative"
    },
    "& .DL_Search-icon": {
        width: "25px",
        height: "25px",
        position: "absolute",
        top: "30%",
        right: "3.5%"
    },
    "& .MuiOutlinedInput-input": {
        background: "transparent",
        zIndex: 1
    },
    "& .DL_header": {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "20px",
        alignItems: "center"
    },
    "& .btn": {
        width: "138px",
        height: "46px",
        color: "#337152",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontWeight: 600,
        borderRadius: "10px",
        border: "1px solid"
    },
    "& .edit_icon": {
        width: "30px",
        cursor: "pointer"
    },
    "& .delete_icon": {
        width: "26px",
        cursor: "pointer"
    },
    "& .action_button": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    "& .add_item": {
        display: "flex",
        alignItems: "center",
        marginTop: "20px"
    },
    "& .tooltip_icon": {
        marginLeft: "15px",
        width: "20px"
    },
    "& .add_icon": {
        width: "24px",
        height: "24px",
        color: '#337152'
    },
    "& .DL_btn_save": {
        width: "211px",
        height: "46px",
        backgroundColor: "#337152",
        color: "white",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontWeight: 600,
        borderRadius: "10px",
        marginTop: "40px",
        marginBottom: "20px"
    },
});
