import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { WithStyles } from "@material-ui/core";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");

interface ErrorObject {
    [key: string]: boolean;
}

interface ErrorMessage {
    incomeFromFirm: string;
    name: string;
    pan: string;
    auditable_non_auditable: string;
    closing_balance_of_capital_in_the_firm: string;
    amount_of_interest_income: string;
    amount_of_remuneration_income: string;
    profit_share_received: string;
    pass_through_business_income:string;
    deemed_business_income:string;
    income_from_aop:string;
    name_of_business: string;
    business_code:string;
    registration_no_of_vechile:string;
    tonnage_capacity_in_mt:string;
    owned_leased_hired:string;
    total_profit_from_44ae:string;
    presumptive_income:string;
    number_of_months_for_which_vechile_was_owned_leased_or_hired:string;

  }

// Customizable Area End

export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    open: boolean;
    type: string;
    onClick: (type: string) => void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    incomeFromFirm: string;
    partnershipFirmUpdateId: string;
    otherBusinessIncomeUpdateId: string;
    incomeFromPlyingTruckUpdateId: string;
    name:string;
    pan:string;
    auditable_non_auditable:string; 
    closing_balance_of_capital_in_the_firm:string;
    amount_of_interest_income:string;
    amount_of_remuneration_income:string;
    profit_share_received:string;
    pass_through_business_income:string;
    deemed_business_income:string;
    income_from_aop:string;
    name_of_business: string;
    business_code:string;
    registration_no_of_vechile:string;
    tonnage_capacity_in_mt:string;
    owned_leased_hired:string;
    total_profit_from_44ae:string;
    presumptive_income:string;
    number_of_months_for_which_vechile_was_owned_leased_or_hired:string;
    error: ErrorObject
    errorMessage:ErrorMessage,
    nameOfBusiness: string;
    futureIntraday: boolean;
    toast: {
        type: "success" | "info" | "warning" | "error";
        open: boolean;
        message: string;
    };
    businessCodes:[]
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class FutureOptionsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    IncomeFromPartneshipFirmcallID: string = ""
    OtherBusinessIncomecallID: string = ""
    IincomeFromPlyingTruckcallID: string = ""
    GetBusinessCodeListcallID: string = ""
    GetIncomFromPartnershipFirmcallID: string = ""
    GetOtherBusinessIncomecallID: string = ""
    GetIncomeFromPlyingTruckcallID: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            getName(MessageEnum.RestAPIRequestMethodMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceEndPointMessage),
        ];
        this.state = {
            error: {},
            incomeFromFirm: "",
            partnershipFirmUpdateId: "",
            otherBusinessIncomeUpdateId: "",
            incomeFromPlyingTruckUpdateId:"",
            name:"",
            pan:"",
            auditable_non_auditable:"", 
            closing_balance_of_capital_in_the_firm:"",
            amount_of_interest_income:"",
            amount_of_remuneration_income:"",
            profit_share_received:"",
            pass_through_business_income:"",
            deemed_business_income:"",
            income_from_aop:"",
            name_of_business: "",
            business_code:"enter",
            registration_no_of_vechile:"",
            tonnage_capacity_in_mt:"",
            owned_leased_hired:"",
            total_profit_from_44ae:"",
            presumptive_income:"",
            number_of_months_for_which_vechile_was_owned_leased_or_hired:"",
            nameOfBusiness: "",
            futureIntraday: false,
            toast: {
                type: "success",
                open: false,
                message: "",
            },
            errorMessage:{
                incomeFromFirm: "",
                name:"",
                pan:"",
                auditable_non_auditable:"", 
                closing_balance_of_capital_in_the_firm:"",
                amount_of_interest_income:"",
                amount_of_remuneration_income:"",
                profit_share_received:"",
                pass_through_business_income:"",
                deemed_business_income:"",
                income_from_aop:"",
                name_of_business: "",
                business_code:"",
                registration_no_of_vechile:"",
                tonnage_capacity_in_mt:"",
                owned_leased_hired:"",
                total_profit_from_44ae:"",
                presumptive_income:"",
                number_of_months_for_which_vechile_was_owned_leased_or_hired:"",
            },
            businessCodes:[]
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        const { type } = this.props;
        window.scrollTo(0, 0);
        if(type === configJSON.plyingTruckProps){
            this.getBusinessCodeList()
        }
        this.getParnershipFirmData()
        // Customizable Area End
    }
    // Customizable Area Start

    async receive(from: string, message: Message) {
        const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        if (
            this.IncomeFromPartneshipFirmcallID ===  apiCallId ||
            this.OtherBusinessIncomecallID ===  apiCallId ||
            this.IincomeFromPlyingTruckcallID ===  apiCallId
          ) {
            this.receiveResponse(message);
          }  
          if (
            this.GetBusinessCodeListcallID ===  apiCallId
          ) {
            this.receiveBusinessCodeListResponse(message);
          }

          if (
            this.GetIncomFromPartnershipFirmcallID ===  apiCallId
          ) {
            this.receiveIncomFromPartnershipFirmResponse(message);
          }
          if (
            this.GetOtherBusinessIncomecallID ===  apiCallId
          ) {
            this.receiveOtherBusinessIncomeResponse(message);
          }
          if (
            this.GetIncomeFromPlyingTruckcallID ===  apiCallId
          ) {
            this.receiveIncomeFromPlyingTruckResponse(message);
          }
        // Customizable Area End
    }

    getParnershipFirmData = ()=>{
        const { type } = this.props;
        switch(type){
            case configJSON.partnershipFirmProps:
                this.getPartnershiipFirmIncomeData()
                break;
            case configJSON.otherBusinessProps:
                this.getOtherBusinessIncomeData()
                break;
            case configJSON.plyingTruckProps:
                this.getIncomeFromPlyingTruckData()
                break;
            default:
                break
        }
    }

    getPartnershiipFirmIncomeData = async ()=>{
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };

        const getpasswordfrom = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
      
        this.GetIncomFromPartnershipFirmcallID  = getpasswordfrom.messageId

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getIncomeFromPartnershipFirmAPIEndpoint
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType

        );
        runEngine.sendMessage(getpasswordfrom.id, getpasswordfrom);

    }

    getOtherBusinessIncomeData = async () =>{
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };

        const getpasswordfrom = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
      
        this.GetOtherBusinessIncomecallID  = getpasswordfrom.messageId

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getOtherBusinessIncomeAPIEndpoint
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType

        );
        runEngine.sendMessage(getpasswordfrom.id, getpasswordfrom);
 
    }
   
    getIncomeFromPlyingTruckData =  async()=>{
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };

        const getpasswordfrom = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
      
        this.GetIncomeFromPlyingTruckcallID  = getpasswordfrom.messageId

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getIncomeFromPlyingTruckAPIEndpoint
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType

        );
        runEngine.sendMessage(getpasswordfrom.id, getpasswordfrom);
 
    }

    getBusinessCodeList = async ()=>{
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };

        const getpasswordfrom = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
      
        this.GetBusinessCodeListcallID  = getpasswordfrom.messageId

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getBusinessCodeListAPI
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType

        );
        runEngine.sendMessage(getpasswordfrom.id, getpasswordfrom);
    }

    handleBlurFutures = (event: { target: { name: string, value: string } }) => {
        const { name, value } = event.target;
        this.setState((prevData) => ({
            ...prevData,
            error: {
                ...prevData.error,
                [name]: String(value).trim() === ""
            }
        }));
    }

    handleFocusFutures = (event: { target: { name: string, value: string } }) => {
        const { name } = event.target;
        this.setState((prevData) => ({
            ...prevData,
            error: {
                ...prevData.error,
                [name]: false
            }
        }));
    }

    handleOnchangeFutures = (event: { target: { name: string, value: string } }) => {
        const { name, value } = event.target;
        this.setState((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }

    handleOnchangeNumber= (event: { target: { name: string, value: string } }) => {
    const { name, value } = event.target;
     const numericValue = value.replace(/[^0-9.]/g, '');
    this.setState((prevData) => ({
        ...prevData,
        [name]: numericValue
    }))
    }

    handleOnchangeString= (event: { target: { name: string, value: string } }) => {
        const { name, value } = event.target;
        const stringValue = value.replace(/[^a-zA-Z]/g, '');
        this.setState((prevData) => ({
            ...prevData,
            [name]: stringValue
        }))
        }

    handlepanValid=(pan:string)=>{
        let regex = /^[A-Z]{5}\d{4}[A-Z]$/;
        if (regex.test(pan.toUpperCase())) {
            return true;
        } 
        else{
           return false
        }
    }

    // handleIncomeFromPartnerhsipFirmValidation = () => {
    //    const {errorMessage, incomeFromFirm, name,pan, auditable_non_auditable, 
    //             closing_balance_of_capital_in_the_firm,
    //             amount_of_interest_income,
    //             amount_of_remuneration_income,
    //             profit_share_received } = this.state;

    //     let isValidate = true;
    //     if (incomeFromFirm.trim() === "") {
    //         this.state.error['incomeFromFirm'] = configJSON.requiredField
    //         errorMessage.incomeFromFirm = configJSON.requiredField
    //         isValidate = false
    //     }
    //     if (name.trim() === "") {
    //         this.state.error['name'] = configJSON.requiredField
    //         errorMessage.name = configJSON.requiredField
    //         isValidate = false
    //     }
    //     if (pan.trim() === "") {
    //         this.state.error['pan'] = configJSON.requiredField
    //         errorMessage.pan = configJSON.requiredField
    //         isValidate = false
    //     }
    //     if (pan.trim() !== "" && !this.handlepanValid(pan)) {
    //         this.state.error['pan'] = configJSON.inCorrectPan
    //         errorMessage.pan = configJSON.inCorrectPan
    //         isValidate = false
    //     }
    //     if (auditable_non_auditable.trim() === "") {
    //         this.state.error['auditable_non_auditable'] = configJSON.requiredField
    //         errorMessage.auditable_non_auditable = configJSON.requiredField
    //         isValidate = false
    //     }
    //     if (closing_balance_of_capital_in_the_firm.trim() === "") {
    //         this.state.error['closing_balance_of_capital_in_the_firm'] = configJSON.requiredField
    //         errorMessage.closing_balance_of_capital_in_the_firm = configJSON.requiredField
    //         isValidate = false
    //     }
    //     if (amount_of_interest_income.trim() === "") {
    //         this.state.error['amount_of_interest_income'] = configJSON.requiredField
    //         errorMessage.amount_of_interest_income = configJSON.requiredField
    //         isValidate = false
    //     }
    //     if (amount_of_remuneration_income.trim() === "") {
    //         this.state.error['amount_of_remuneration_income'] = configJSON.requiredField
    //         errorMessage.amount_of_remuneration_income = configJSON.requiredField
    //         isValidate = false
    //     }
    //     if (profit_share_received.trim() === "") {
    //         this.state.error['profit_share_received'] = configJSON.requiredField
    //         errorMessage.profit_share_received = configJSON.requiredField
    //         isValidate = false
    //     }
    //     this.setState({ errorMessage });
    //     return isValidate;
    // }

    // handleOtherIncomeValidation = () => {
    //     const {errorMessage,pass_through_business_income,
    //               deemed_business_income,
    //                 income_from_aop, } = this.state;
 
    //      let isValidate = true;
    //      if (pass_through_business_income.trim() === "") {
    //          this.state.error['pass_through_business_income'] = configJSON.requiredField
    //          errorMessage.pass_through_business_income = configJSON.requiredField
    //          isValidate = false
    //      }
    //      if (deemed_business_income.trim() === "") {
    //          this.state.error['deemed_business_income'] = configJSON.requiredField
    //          errorMessage.deemed_business_income = configJSON.requiredField
    //          isValidate = false
    //      }
    //      if (income_from_aop.trim() === "") {
    //          this.state.error['income_from_aop'] = configJSON.requiredField
    //          errorMessage.income_from_aop = configJSON.requiredField
    //          isValidate = false
    //      }
    //      return isValidate;
    //  }

    // handleIncomeFromPlyingTruckValidation = () => {
    //     const {errorMessage, name_of_business, business_code,registration_no_of_vechile,owned_leased_hired,total_profit_from_44ae,
    //         tonnage_capacity_in_mt,
    //         presumptive_income,number_of_months_for_which_vechile_was_owned_leased_or_hired  } = this.state;
 
    //      let isValidate = true;
    //      if (name_of_business.trim() === "") {
    //         this.state.error['name_of_business'] = configJSON.requiredField
    //         errorMessage.name_of_business = configJSON.requiredField
    //         isValidate = false
    //     }
    //      if (business_code=== "enter") {
    //          this.state.error['business_code'] = configJSON.requiredField
    //          errorMessage.business_code = configJSON.requiredField
    //          isValidate = false
    //      }
    //      if (registration_no_of_vechile.trim() === "") {
    //         this.state.error['registration_no_of_vechile'] = configJSON.requiredField
    //         errorMessage.registration_no_of_vechile = configJSON.requiredField
    //         isValidate = false
    //     }
    //     if (owned_leased_hired.trim() === "") {
    //         this.state.error['owned_leased_hired'] = configJSON.requiredField
    //         errorMessage.owned_leased_hired = configJSON.requiredField
    //         isValidate = false
    //     }
    //     if (total_profit_from_44ae.trim() === "") {
    //         this.state.error['total_profit_from_44ae'] = configJSON.requiredField
    //         errorMessage.total_profit_from_44ae = configJSON.requiredField
    //         isValidate = false
    //     }
    //     if (tonnage_capacity_in_mt === "") {
    //         this.state.error['tonnage_capacity_in_mt'] = configJSON.requiredField
    //         errorMessage.tonnage_capacity_in_mt = configJSON.requiredField
    //         isValidate = false
    //     }
    //     if (presumptive_income.trim() === "") {
    //         this.state.error['presumptive_income'] = configJSON.requiredField
    //         errorMessage.presumptive_income = configJSON.requiredField
    //         isValidate = false
    //     }
    //     if (number_of_months_for_which_vechile_was_owned_leased_or_hired === "") {
    //         this.state.error['number_of_months_for_which_vechile_was_owned_leased_or_hired'] = configJSON.requiredField
    //         errorMessage.number_of_months_for_which_vechile_was_owned_leased_or_hired = configJSON.requiredField
    //         isValidate = false
    //     }
    //     this.setState({ errorMessage });

    //      return isValidate;
    //  }

     getAPICallID = ()=>{
        const { type } = this.props;
        const {partnershipFirmUpdateId,otherBusinessIncomeUpdateId,incomeFromPlyingTruckUpdateId} = this.state
        let apiEndPoint
        switch(type){
            case configJSON.partnershipFirmProps:
                if(partnershipFirmUpdateId){
                    apiEndPoint= `${configJSON.incomeFromPartnershipAPI}/${partnershipFirmUpdateId}`
                }else{
                    apiEndPoint= configJSON.incomeFromPartnershipAPI
                }
                break;
            case configJSON.otherBusinessProps:
                if(otherBusinessIncomeUpdateId){
                    apiEndPoint= `${configJSON.otherBusinessIncomeAPI}/${otherBusinessIncomeUpdateId}`
                }else{
                    apiEndPoint= configJSON.otherBusinessIncomeAPI
                }
                break;
            case configJSON.plyingTruckProps:
                if(incomeFromPlyingTruckUpdateId){
                    apiEndPoint= `${configJSON.incomeFromPlyingTruckAPI}/${incomeFromPlyingTruckUpdateId}`
                }else{
                    apiEndPoint= configJSON.incomeFromPlyingTruckAPI
                }
                break;
            default:
                break
        }
        return apiEndPoint
     }

     getReqBody = ()=>{
        const { type } = this.props;
        const { incomeFromFirm, name,pan, auditable_non_auditable, 
            closing_balance_of_capital_in_the_firm,
            amount_of_interest_income,
            amount_of_remuneration_income,
            profit_share_received, 
            pass_through_business_income,
            deemed_business_income,
            income_from_aop,name_of_business,
            business_code,registration_no_of_vechile,
            owned_leased_hired,total_profit_from_44ae,
            tonnage_capacity_in_mt,
            presumptive_income,number_of_months_for_which_vechile_was_owned_leased_or_hired } = this.state;

        let reqBody
        switch(type){
            case configJSON.partnershipFirmProps:
                reqBody = {
                    partnership_firm: {
                        "share_of_income_from_firm":incomeFromFirm,
                        "name":name,
                        "pan":pan,
                        "auditable_non_auditable": auditable_non_auditable,
                        "closing_balance_of_capital_in_the_firm":closing_balance_of_capital_in_the_firm,
                        "amount_of_interest_income":amount_of_interest_income,
                        "amount_of_remuneration_income":amount_of_remuneration_income,
                        "profit_share_received":profit_share_received
                    }
                  }
                break;
            case configJSON.otherBusinessProps:
                reqBody = {
                    business_income: {
                        "pass_through_business_income":pass_through_business_income,
                        "deemed_business_income":deemed_business_income,
                        "income_from_aop":income_from_aop,
                    }
                  }
                break;
            case configJSON.plyingTruckProps:
                reqBody = {
                    playing_of_truck: {
                        "name_of_business": name_of_business,
                        "business_code":business_code,
                        "registration_no_of_vechile":registration_no_of_vechile,
                        "tonnage_capacity_in_mt":tonnage_capacity_in_mt,
                        "owned_leased_hired":owned_leased_hired,
                        "total_profit_from_44ae":total_profit_from_44ae,
                        "presumptive_income":presumptive_income,
                        "number_of_months_for_which_vechile_was_owned_leased_or_hired":number_of_months_for_which_vechile_was_owned_leased_or_hired
                    }
                  }
                break;
            default:
                break
        }
        
        return reqBody
     }

     handleIncomeFromPartnerhsipFirmAPICall =  async() => {
        const {partnershipFirmUpdateId,otherBusinessIncomeUpdateId,incomeFromPlyingTruckUpdateId} = this.state

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };

        const httpBody = this.getReqBody()

        const getpasswordfrom = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const { type } = this.props;
        switch(type){
            case configJSON.partnershipFirmProps:
                 this.IncomeFromPartneshipFirmcallID = getpasswordfrom.messageId
                break;
            case configJSON.otherBusinessProps:
                 this.OtherBusinessIncomecallID = getpasswordfrom.messageId
                break;
            case configJSON.plyingTruckProps:
                 this.IincomeFromPlyingTruckcallID  = getpasswordfrom.messageId
                break;
        }
   
        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.getAPICallID()
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        getpasswordfrom.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
         partnershipFirmUpdateId || otherBusinessIncomeUpdateId || incomeFromPlyingTruckUpdateId ? configJSON.examplePatchAPiMethod: configJSON.exampleAPiMethod

        );
        runEngine.sendMessage(getpasswordfrom.id, getpasswordfrom);
    }

    handleSubmitFutures = () => {
        const { type } = this.props;
            this.handleIncomeFromPartnerhsipFirmAPICall()

    }

    handleTostClose = () => {
        this.setState({
        toast: {
            open: false,
            message: "error.message",
            type: "error",
        },
        });
    }

    receiveResponse  = (message: Message)=>{
        const { onClick } = this.props;
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(responseJson && responseJson.data){  
            onClick("")
        }else{
            this.setState({
                toast: { open: true, message:responseJson?.error ? responseJson?.error : responseJson?.errors?.message, type: "error" },
            });
        }
      }  

    receiveBusinessCodeListResponse = (message: Message)=>{
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(responseJson){  
           this.setState({businessCodes:responseJson.business_codes})
        }
    }
    
    receiveIncomFromPartnershipFirmResponse = (message: Message)=>{
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(responseJson && responseJson.data.length > 0){  
            const response = responseJson.data[0].attributes
            this.setState({
                partnershipFirmUpdateId:response.id,
                incomeFromFirm:response.share_of_income_from_firm?.toString().replace(/\.0$/, ''),
                name:response.name,
                pan:response.pan,
                auditable_non_auditable:response.auditable_non_auditable,
                closing_balance_of_capital_in_the_firm:response.closing_balance_of_capital_in_the_firm?.toString().replace(/\.0$/, ''),
                amount_of_interest_income:response.amount_of_interest_income?.toString().replace(/\.0$/, ''),
                amount_of_remuneration_income:response.amount_of_remuneration_income?.toString().replace(/\.0$/, ''),
                profit_share_received:response.profit_share_received?.toString().replace(/\.0$/, ''),
            })
        }
    }

    receiveOtherBusinessIncomeResponse = (message: Message)=>{
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(responseJson && responseJson.data.length > 0){  
            const response = responseJson.data[0].attributes
            this.setState({
                otherBusinessIncomeUpdateId:response.id,
                pass_through_business_income:response.pass_through_business_income?.toString().replace(/\.0$/, ''),
                deemed_business_income:response.deemed_business_income?.toString().replace(/\.0$/, ''),
                income_from_aop:response.income_from_aop?.toString().replace(/\.0$/, ''),
            })
        }
    }

    receiveIncomeFromPlyingTruckResponse = (message: Message)=>{
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(responseJson && responseJson.data.length > 0){  
            const response = responseJson.data[0].attributes
              this.setState({
                incomeFromPlyingTruckUpdateId:response.id,
                name_of_business:response.name_of_business,
                business_code:response.business_code,
                registration_no_of_vechile:response.registration_no_of_vechile,
                owned_leased_hired:response.owned_leased_hired,
                tonnage_capacity_in_mt:response.tonnage_capacity_in_mt,
                total_profit_from_44ae:response.total_profit_from_44ae?.toString().replace(/\.0$/, ''),
                presumptive_income:response.presumptive_income?.toString().replace(/\.0$/, ''),
                number_of_months_for_which_vechile_was_owned_leased_or_hired:response.number_of_months_for_which_vechile_was_owned_leased_or_hired,
            })
        }

    }
    
    getErrorMessageByName(name: string) {
        return this.state.errorMessage[name as keyof ErrorMessage];
     }
    // Customizable Area End
}
