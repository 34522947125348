import React from "react";
// Customizable Area Start
import LandingPageController, { Props } from "./LandingPageController.web";
import { styled } from "@material-ui/styles";
import { profileimage } from "../../user-profile-basic/src/assets";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

export class TaxCalculator extends LandingPageController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const cards = [
            {
                title: "Income Tax Calculator",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                pathname: "Assessmenttest"
            },
            {
                title: "HRA Exemption Calculator",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                pathname: "LandingPage"
            },
            {
                title: "Capital Gains Calculator",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                pathname: "LandingPage"
            },
            {
                title: "Retirement Benefits Calculator",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                pathname: "LandingPage"
            },
            {
                title: "Residential Status Calculator",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                pathname: "ResidentialStatus"
            }
        ];
        return (
                <InfoWrapper>
                    <div className="backnavigation aboutIt">
                    <ChevronLeftIcon fontSize="large" onClick={() => { this.props.navigation.navigate("LandingPage") }} /><h3 >Tax Calculator</h3>
                    </div>
                    <div className="calcCards">
                        {cards.map((card: any, index) => (
                            <div className="calculatorCard" key={index} onClick={() => { this.props.navigation.navigate(`${card.pathname}`) }}>
                                <div className="imageCalc">
                                    <img src={profileimage} alt="calc" />
                                </div>
                                <div className="aboutIt">
                                    <h1>{card.title}</h1>
                                    <p>{card.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </InfoWrapper>
        );
    }
}

export default TaxCalculator;

const InfoWrapper = styled("div")({
    "& .backnavigation":{
        display:"flex",
        alignItems:"center",
        padding:"20px"
    },
    "& .calcCards": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        fontFamily: "Nunito",

        "& .calculatorCard": {
            cursor:"pointer",
            maxWidth: "25%",
            width: "100%",
            border: "1.5px solid gray",
            borderRadius: "7px",
            margin: "20px",
            maxHeight: "100%",
            height: "90%",
            padding: "20px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            "& .imageCalc img": {
                maxWidth: "250px",
                width: "100%",
                height: "215px"
            },
            "& .aboutIt h1": {
                fontSize: "25px",
                textAlign: "center",
                lineHeight: "62px",
                padding: "0px",
                margin: "0px"
            },
            "& .aboutIt h3": {
                fontSize: "25px",
                textAlign: "center",
                lineHeight: "62px",
                padding: "0px",
                margin: "0px"
            },
            "& .aboutIt p": {
                fontSize: "18px",
                textAlign: "center",
                padding: "0px",
                margin: "0px"
            },
            "&:hover": {
                border: "1px solid green",
                "& p": {
                    color: "green"
                },
                "& h1": {
                    color: "green"
                }
            }
        }
    }
});
// Customizable Area End
