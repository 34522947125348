import React from "react";

// Customizable Area Start
import { Box, Select, withStyles, MenuItem, Button, styled, Snackbar } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MainHeader from "../../../components/src/MainHeader.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DatePicker from "react-multi-date-picker";
import Alert from '@material-ui/lab/Alert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Customizable Area End

import AssessmenttestController, {
  Props,

} from "./AssessmenttestController";

export default class Test extends AssessmenttestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <div style={{ height: '100px',backgroundColor: '#347152',marginBottom:'50px' }}>

          <MainHeader data-test-id="main_header" handleLogin={this.handleLoginBtn} handleLogout={this.handleLogout} handlemodalopen={this.handlemodalopen} data-aos='zoom-in' navigation={undefined} type={""} handleHeaderRoute={this.handleHeaderRoute} handledashboard={this.navigateToDashBoard} handledashprofile={this.handledashprofile} handleToLandingPage={this.handleToLandingPageTo} />

        <Snackbar
          anchorOrigin={{ vertical: 'top',  horizontal: 'center' }}
          open={this.state.openStatus}
          autoHideDuration={5000}
          data-test-id="snack-bar"
          onClose={this.handleTextMessage}>
          <Alert severity="success">
            Data Submitted Successfully
          </Alert>
        </Snackbar>
        </div>
        <Box>
          <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }} >            
            <Box style={{maxWidth: '1440px',margin: '0 auto', width: '80%'}}>
            <div style={{ width: '100%', marginBottom: '5px' }}>
              <div style={{ display: 'flex', alignItems: 'center', height: '20px' , cursor:"pointer"}} onClick={this.handlenavigation} data-test-id="back_button">
                <ArrowBackIosIcon style={{ color: "#347152" }} />
                <h4 style={{ color: "#347152", fontWeight: 'normal',cursor:'pointer' }}>Back To Home</h4>
              </div>
              <h1 style={{ color: "#347152" }}>Income Tax Calculator</h1>
            </div>
            <Box style={{width: '100%',padding: '2px 20px', boxSizing: 'border-box', border: '1px solid #d3d3d3',  height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px' }}>
              <h3>Assessment Year</h3>

              <StyledSelect
                IconComponent={arrowDownIcon}

                style={{ color: 'grey', }}
                data-test-id="assessment"
                disableUnderline
                displayEmpty
                value={this.state.assessmentYear}
                name="AssessmentYear"
                onChange={this.handleChangeSelectDropDown}
                renderValue={this.state.assessmentYear !== "" ? undefined : () => "Select"}
              >

                <MenuItem className='menuItems' value={"2024-25"}>2024-25</MenuItem>
                <MenuItem className='menuItems' value={"2023-24"}>2023-24</MenuItem>
                <MenuItem className='menuItems' value={"2022-23"}>2022-23</MenuItem>
                <MenuItem className='menuItems' value={"2021-22"}>2021-22</MenuItem>
                <MenuItem className='menuItems' value={"2020-21"}>2020-21</MenuItem>
                <MenuItem className='menuItems' value={"2019-20"}>2019-20</MenuItem>
                <MenuItem className='menuItems' value={"2018-19"}>2018-19</MenuItem>
                <MenuItem className='menuItems' value={"2017-18"}>2017-18</MenuItem>
                <MenuItem className='menuItems' value={"2016-17"}>2016-17</MenuItem>
                <MenuItem className='menuItems' value={"2010-11"}>2010-11</MenuItem>
              </StyledSelect>
            </Box>
            {this.state.assessmentYearError && <span style={{ color: 'red', marginLeft: '10px' }}>This field is required!</span>}

            <Box style={{width: '100%',padding: '2px 20px', boxSizing: 'border-box', marginTop: '10px',  border: '1px solid #d3d3d3',  height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px' }}>
              <h3>Tax Payer</h3>

              <StyledSelect
                IconComponent={arrowDownIcon}
                style={{ color: 'grey' }}
                disableUnderline
                displayEmpty
                value={this.state.taxPayer}
                name="TaxPayer"
                onChange={this.handleChangeSelectDropDown}
                renderValue={this.state.taxPayer !== "" ? undefined : () => "Individual"}
              >

                <MenuItem className='menuItems' value={"Individuals"}>Individuals</MenuItem>
                <MenuItem className='menuItems' value={"Hindu Undivided Family (HUF)"}>Hindu Undivided Family (HUF)</MenuItem>
                <MenuItem className='menuItems' value={"Firms"}>Firms</MenuItem>
                <MenuItem className='menuItems' value={"Companies"}>Companies</MenuItem>
                <MenuItem className='menuItems' value={"Association of Persons (AOP)"}>Association of Persons (AOP)</MenuItem>
                <MenuItem className='menuItems' value={"Body of Individuals (BOI)"}>Body of Individuals (BOI)</MenuItem>
                <MenuItem className='menuItems' value={"Local Authority"}>Local Authority</MenuItem>


              </StyledSelect>
            </Box>
            {this.state.taxPayerError && <span style={{ color: 'red', marginLeft: '10px' }}>This field is required!</span>}

            <Box style={{width: '100%',padding: '2px 20px', boxSizing: 'border-box', marginTop: '10px',  border: '1px solid #d3d3d3',  height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px' }}>
              <h3>Male/ Female/ Senior Citizen</h3>

              <StyledSelect
                IconComponent={arrowDownIcon}
                style={{ color: 'grey' }}
                disableUnderline
                displayEmpty

                name="Gender"
                onChange={this.handleChangeSelectDropDown}
                value={this.state.gender}
                renderValue={this.state.gender !== "" ? undefined : () => "Select"}

              >

                <MenuItem className='menuItems' value={"Male"}>Male</MenuItem>
                <MenuItem className='menuItems' value={"Female"}>Female</MenuItem>
                <MenuItem className='menuItems' value={"Senior Citizen"}>Senior Citizen</MenuItem>
                <MenuItem className='menuItems' value={"Super Senior Citizen"}>Super Senior Citizen</MenuItem>



              </StyledSelect>
            </Box>
            {this.state.genderError && <span style={{ color: 'red', marginLeft: '10px' }}>This field is required!</span>}

            <Box style={{width: '100%',padding: '2px 20px', boxSizing: 'border-box', marginTop: '10px',  border: '1px solid #d3d3d3',  height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px' }}>
              <h3>Residential Status</h3>

              <StyledSelect
                IconComponent={arrowDownIcon}
                style={{ color: 'grey' }}
                disableUnderline
                displayEmpty

                name="ResidentStatus"
                onChange={this.handleChangeSelectDropDown}
                renderValue={this.state.residentialStatus !== "" ? undefined : () => "Select"}
                value={this.state.residentialStatus}

              >

                <MenuItem className='menuItems' value={"Resident"}>Resident</MenuItem>
                <MenuItem className='menuItems' value={"Non-Resident"}>Non-Resident</MenuItem>
                <MenuItem className='menuItems' value={"Not Ordinary Resident"}>Not Ordinary Resident</MenuItem>
              </StyledSelect>
            </Box>
            {this.state.residentialStatusError && <span style={{ color: 'red', marginLeft: '10px' }}>This field is required!</span>}

            <div style={{width: '100%',padding: '2px 20px', boxSizing: 'border-box', border: '1px solid #d3d3d3', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px' }}>

              <h3>Income from Salary</h3>
              <div>

              <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                      placeholder="Enter"
                      name="incomeFromSalary"
                      type="text"
                      onChange={this.handleChangeNumber}
                      value={this.state.incomeFromSalary}

                    ></input>
              </div>
            </div>
            {this.state.incomeFromSalaryError && <span style={{ color: 'red', marginLeft: '10px' }}>This field is required!</span>}

            <Accordion square expanded={this.state.expanded} style={{width: '100%'}}>

              <div style={{ width: '100%',padding: '2px 20px', border: '1px solid #d3d3d3', boxSizing: 'border-box',height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px', marginBottom: '10px' }}>

                <h3>Income From House Property</h3>
                <div>
                  <StyledButton onClick={this.toggleAccordion} id="toggleButton" style={{ marginRight: '15px', backgroundColor: 'white', color: '#347152', fontWeight: 700 , fontFamily:"Nunito" , fontSize:"10px"}} disableElevation disableFocusRipple disableRipple size='medium' variant='outlined'>{this.state.expanded ? "Hide Details" : "Show Details"}</StyledButton>

                  <input style={{ width: '180px', height: '33px', border: '1px solid #d8d8d8', boxSizing: 'border-box', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8',  paddingRight: '10px' }}

                    name="incomeHouseProperty"
                    type="text"
                    onChange={this.handleChangeNumber}
                    value={this.state.incomeHouseProperty}
                  ></input>
                </div>
              </div>

              <div>
                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <Box style={{ padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white', marginTop: '15px' }}>
                    <h3>a. Income from Self occupied Property</h3>
                  </Box>

                </AccordionDetails >
                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <Box style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Interest Paid/Payable on Housing Loan</h3>

                  </Box>

                </AccordionDetails>
                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <Box style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>1. Interest on Housing Loan</h3>

                    <input style={{ paddingRight: '10px', width: '180px', height: '33px', border: '1px solid grey', boxSizing: 'border-box', borderRadius: '4px', fontSize: '18px', }}

                      name="test1"
                      type="text"
                      onChange={this.handleChange3}
                      value={this.state.interestOnHousingLoan}
                    ></input>
                  </Box>

                </AccordionDetails>
                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <Box style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Income from self-occupied house property</h3>

                    <input style={{ width: '180px', height: '33px', border: '1px solid #d8d8d8', boxSizing: 'border-box', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8',  paddingRight: '10px' }}

                      name="incomeFromSelfHousePropery"
                      type="text"
                      onChange={this.handleChange}
                      value={this.state.incomeFromSelfHousePropery}
                    ></input>
                  </Box>

                </AccordionDetails>
                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <Box style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', boxSizing: 'border-box', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>b. Income from Let-out Property</h3>

                  </Box>

                </AccordionDetails>
                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <Box style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', boxSizing: 'border-box', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>1. Annual letable Value/Rent Received or Receivable</h3>

                    <input style={{ paddingRight: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '18px', }}

                      name="annualLetable"
                      type="text"
                      onChange={this.handleChange}
                      value={this.state.annualLetable}
                    ></input>
                  </Box>

                </AccordionDetails>
                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <Box style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', boxSizing: 'border-box', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>2. Less: Municipal Taxes Paid During the Year</h3>

                    <input style={{ paddingRight: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '18px', }}

                      name="municipalTaxPaid"
                      type="text"
                      onChange={this.handleChange}
                      value={this.state.municipalTaxPaid}
                    ></input>
                  </Box>

                </AccordionDetails>
                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <Box style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', boxSizing: 'border-box', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>3. Less:Unrealized Rent</h3>

                    <input style={{ paddingRight: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '18px', }}

                      name="unrealisedRent"
                      type="text"
                      onChange={this.handleChange}
                      value={this.state.unrealisedRent}
                    ></input>
                  </Box>

                </AccordionDetails>
                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <Box style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', boxSizing: 'border-box', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>4. Net Annual Value (1-(2+3))</h3>

                    <input style={{ width: '180px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8',  paddingRight: '10px' }}

                      name="netAnnualValue"
                      type="text"
                      onChange={this.handleChange}
                      value={this.state.netAnnualValue}
                    ></input>
                  </Box>

                </AccordionDetails>
                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <Box style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', boxSizing: 'border-box', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Less: Deductions from Net Annual Value</h3>

                  </Box>
                </AccordionDetails>
                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <Box style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', boxSizing: 'border-box', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>i. Standard Deduction @ 30% of Net Annual Value</h3>

                    <input style={{ width: '180px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8',  paddingRight: '10px' }}

                      name="standardDeduction"
                      type="text"
                      onChange={this.handleChange}
                      value={this.state.standardDeduction}
                    ></input>
                  </Box>

                </AccordionDetails>
                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <Box style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', boxSizing: 'border-box', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>ii. Interest on Housing Loan</h3>

                    <input style={{ paddingRight: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '18px', }}

                      name="InterestOnHousing"
                      type="text"
                      onChange={this.handleChange3}
                      value={this.state.interestOnHousing}
                    ></input>
                  </Box>

                </AccordionDetails>
                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <Box style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', boxSizing: 'border-box', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Income from Let-out House Property</h3>
                    {/* <TextField
          style={{ width: '20px' }}
          variant="outlined"
          size="small"
          name="incomeFromLetoutHouseProperty"
          onChange={this.handleChange}
          //.state.incomeFromLetoutHouseProperty}
        /> */}
                    <input style={{ width: '180px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8',  paddingRight: '10px' }}

                      name="incomeFromLetoutHouseProperty"
                      type="text"
                      onChange={this.handleChange}
                      value={this.state.incomeFromLetoutHouseProperty}
                    ></input>
                  </Box>

                </AccordionDetails>


              </div>

            </Accordion>

          <Accordion square expanded={this.state.expandedCapitalGains} style={{width: '100%'}}>

                <div style={{ width: '100%', padding: '2px 20px', boxSizing: 'border-box', border: '1px solid #d3d3d3', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px' }}>

                  <h3>Capital Gains</h3>
                  <div>
                    <StyledButton onClick={this.toggleAccordionCapitalGains} style={{ marginRight: '15px', backgroundColor: 'white', color: '#347152', fontWeight: 700 , fontFamily:"Nunito" , fontSize:"10px"}} id="toggleButtonCapitalGain" disableElevation disableFocusRipple disableRipple size='medium' variant='outlined'>{this.state.expandedCapitalGains ? "Hide Details" : "Show Details"}</StyledButton>
                    <input style={{ width: '180px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8',  paddingRight: '10px' }}
                      name="capitalGains"
                      onChange={this.handleChangeNumber}
                      value={this.state.capitalGains}
                    ></input>
                  </div>
                </div>

                <div >
                  <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                    <div style={{ backgroundColor: 'white', width: '100%', padding: '0px 18px', borderRadius: '5px', marginTop: '18px' }}>
                      <h2 >Short Term Capital GainS (Other than covered under section 111A)</h2>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ marginBottom: '10px' }}>
                          <p style={{ fontWeight: 'bold', margin: '0', fontSize: 'clamp(8px, 4vw, 13px)' }}>From 16/06/2014 to 15/09/2014</p>
                          <input
                            name="short_term_capital_gains_value1"
                            type="text"
                            value={this.state.short_term_capital_gains_value1}
                            onChange={this.handleChangeCapitalGains}

                            placeholder="Enter"
                            style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                          />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                          <p style={{ fontWeight: 'bold', margin: '0', fontSize: 'clamp(8px, 4vw, 13px)' }}>From 16/09/2014 to 15/12/2014</p>
                          <input
                            type="text"
                            value={this.state.short_term_capital_gains_value2}
                            name="short_term_capital_gains_value2"
                            onChange={this.handleChangeCapitalGains}

                            placeholder="Enter"
                            style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                          />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                          <p style={{ fontWeight: 'bold', margin: '0', fontSize: 'clamp(8px, 4vw, 13px)' }}>From 16/12/2014 to 15/03/2015</p>
                          <input
                            type="text"
                            name="short_term_capital_gains_value3"
                            onChange={this.handleChangeCapitalGains}
                            value={this.state.short_term_capital_gains_value3}

                            placeholder="Enter"
                            style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                          />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                          <p style={{ fontWeight: 'bold', margin: '0', fontSize: 'clamp(8px, 4vw, 13px)' }}>From 16/03/2015 to 31/03/2015</p>
                          <input
                            type="text"
                            name="short_term_capital_gains_value4"
                            value={this.state.short_term_capital_gains_value4}
                            onChange={this.handleChangeCapitalGains}
                            placeholder="Enter"
                            style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                          />
                        </div>
                        <div style={{ marginBottom: '10px', textAlign: 'center' }}>
                          <p style={{ fontWeight: 'bold', margin: '0', fontSize: 'clamp(8px, 4vw, 13px)' }}>Total</p>
                          <input
                            type="text"
                            value={this.state.total1}
                            style={{ width: '180px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8',  paddingRight: '10px' }}
                          />
                        </div>
                      </div>
                    </div>
                  </AccordionDetails >

                  <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                    <div style={{ backgroundColor: 'white', width: '100%', padding: '0px 18px', borderRadius: '5px', marginTop: '-10px' }}>
                      <h2 >Short Term Capital GainS (Covered under section 111A)</h2>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ marginBottom: '10px' }}>
                          <p style={{ fontWeight: 'bold', margin: '0', fontSize: 'clamp(8px, 4vw, 13px)' }}>From 16/06/2014 to 15/09/2014</p>
                          <input
                            name="stcgCovered1"
                            type="text"
                            value={this.state.stcgCovered1}
                            onChange={this.handleChangeCapitalGains}
                            placeholder="Enter"
                            style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                          />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                          <p style={{ fontWeight: 'bold', margin: '0', fontSize: 'clamp(8px, 4vw, 13px)' }}>From 16/09/2014 to 15/12/2014</p>
                          <input
                            type="text"
                            name="stcgCovered2"
                            onChange={this.handleChangeCapitalGains}
                            value={this.state.stcgCovered2}
                            placeholder="Enter"
                            style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                          />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                          <p style={{ fontWeight: 'bold', margin: '0', fontSize: 'clamp(8px, 4vw, 13px)' }}>From 16/12/2014 to 15/03/2015</p>
                          <input
                            type="text"
                            name="stcgCovered3"
                            value={this.state.stcgCovered3}
                            onChange={this.handleChangeCapitalGains}
                            placeholder="Enter"
                            style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                          />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                          <p style={{ fontWeight: 'bold', margin: '0', fontSize: 'clamp(8px, 4vw, 13px)' }}>From 16/03/2015 to 31/03/2015</p>
                          <input
                            type="text"
                            name="stcgCovered4"
                            value={this.state.stcgCovered4}
                            onChange={this.handleChangeCapitalGains}
                            placeholder="Enter"
                            style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                          />
                        </div>
                        <div style={{ marginBottom: '10px', textAlign: 'center' }}>
                          <p style={{ fontWeight: 'bold', margin: '0', fontSize: 'clamp(8px, 4vw, 13px)' }}>Total</p>
                          <input
                            type="text"
                            value={this.state.total2}
                            style={{ width: '180px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8',  paddingRight: '10px' }}
                          />
                        </div>
                      </div>
                    </div>
                  </AccordionDetails >

                  <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                    <div style={{ backgroundColor: 'white', width: '100%', padding: '0px 18px', borderRadius: '5px', marginTop: '-10px' }}>
                      <h2>Long Term Capital Gains (Charged to tax @20%)</h2>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ marginBottom: '10px' }}>
                          <p style={{ fontWeight: 'bold', margin: '0', fontSize: 'clamp(8px, 4vw, 13px)' }}>From 16/06/2014 to 15/09/2014</p>
                          <input
                            type="text"
                            name="long_term_capital_gains_value1"
                            value={this.state.long_term_capital_gains_value1}
                            onChange={this.handleChangeCapitalGains}
                            placeholder="Enter"
                            style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                          />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                          <p style={{ fontWeight: 'bold', margin: '0', fontSize: 'clamp(8px, 4vw, 13px)' }}>From 16/09/2014 to 15/12/2014</p>
                          <input
                            type="nu mber"
                            name="long_term_capital_gains_value2"
                            value={this.state.long_term_capital_gains_value2}
                            onChange={this.handleChangeCapitalGains}
                            placeholder="Enter"
                            style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                          />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                          <p style={{ fontWeight: 'bold', margin: '0', fontSize: 'clamp(8px, 4vw, 13px)' }}>From 16/12/2014 to 15/03/2015</p>
                          <input
                            type="text"
                            name="long_term_capital_gains_value3"
                            value={this.state.long_term_capital_gains_value3}
                            onChange={this.handleChangeCapitalGains}
                            placeholder="Enter"
                            style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                          />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                          <p style={{ fontWeight: 'bold', margin: '0', fontSize: 'clamp(8px, 4vw, 13px)' }}>From 16/03/2015 to 31/03/2015</p>
                          <input
                            type="text"
                            name="long_term_capital_gains_value4"
                            value={this.state.long_term_capital_gains_value4}
                            onChange={this.handleChangeCapitalGains}
                            placeholder="Enter"
                            style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                          />
                        </div>
                        <div style={{ marginBottom: '10px', textAlign: 'center' }}>
                          <p style={{ fontWeight: 'bold', margin: '0', fontSize: 'clamp(8px, 4vw, 13px)' }}>Total</p>
                          <input
                            type="text"
                            value={this.state.total3}

                            style={{ width: '180px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8',  paddingRight: '10px' }}
                          />
                        </div>
                      </div>
                    </div>
                  </AccordionDetails >

                  <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                    <div style={{ backgroundColor: 'white', width: '100%', padding: '0px 18px', borderRadius: '5px', marginTop: '-10px' }}>
                      <h2>Long Term Capital Gains (Charged to tax @10%)</h2>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ marginBottom: '10px' }}>
                          <p style={{ fontWeight: 'bold', margin: '0', fontSize: 'clamp(8px, 4vw, 13px)' }}>From 16/06/2014 to 15/09/2014</p>
                          <input
                            type="text"
                            name="ltcg10_value1"
                            value={this.state.ltcg10_value1}
                            onChange={this.handleChangeCapitalGains}
                            placeholder="Enter"
                            style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                          />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                          <p style={{ fontWeight: 'bold', margin: '0', fontSize: 'clamp(8px, 4vw, 13px)' }}>From 16/09/2014 to 15/12/2014</p>
                          <input
                            type="text"
                            name="ltcg10_value2"
                            value={this.state.ltcg10_value2}
                            onChange={this.handleChangeCapitalGains}
                            placeholder="Enter"
                            style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                          />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                          <p style={{ fontWeight: 'bold', margin: '0', fontSize: 'clamp(8px, 4vw, 13px)' }}>From 16/12/2014 to 15/03/2015</p>
                          <input
                            type="text"
                            name="ltcg10_value3"
                            value={this.state.ltcg10_value3}
                            onChange={this.handleChangeCapitalGains}
                            placeholder="Enter"
                            style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                          />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                          <p style={{ fontWeight: 'bold', margin: '0', fontSize: 'clamp(8px, 4vw, 13px)' }}>From 16/03/2015 to 31/03/2015</p>
                          <input
                            type="text"
                            name="ltcg10_value4"
                            value={this.state.ltcg10_value4}
                            onChange={this.handleChangeCapitalGains}
                            placeholder="Enter"
                            style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                          />
                        </div>
                        <div style={{ marginBottom: '10px', textAlign: 'center' }}>
                          <p style={{ fontSize: 'clamp(8px, 4vw, 13px)', fontWeight: 'bold', margin: '0', }}>Total</p>
                          <input
                            type="text"
                            value={this.state.total4}
                            placeholder="0"
                            style={{ color: 'black', width: '180px', paddingRight: '10px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8',  }}
                          />
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </div>

              </Accordion>

            <Accordion square expanded={this.state.expandedIncomeFromOtherSources} style={{width: '100%'}}>

              <div style={{ width: '100%',padding: '2px 20px', boxSizing: 'border-box',border: '1px solid #d3d3d3', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px' }}>

                <h3>Income From Other Sources</h3>
                <div>
                  <StyledButton onClick={this.toggleAccordionIncomeFromOtherSources} id="toggleButtonIFOS" style={{ marginRight: '15px', backgroundColor: 'white', color: '#347152', fontWeight: 700 , fontFamily:"Nunito" , fontSize:"10px"}} disableElevation disableFocusRipple disableRipple size='medium' variant='outlined'>{this.state.expandedIncomeFromOtherSources ? "Hide Details" : "Show Details"}</StyledButton>
                  <input style={{ width: '180px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8',  paddingRight: '10px' }}
                    name="incomeFromOtherSource"
                    value={this.state.incomeFromOtherSource}
                    onChange={this.handleChangeNumber}
                    type="text"
                  ></input>
                </div>

              </div>

              <div >
                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <div style={{ marginTop: '20px', padding: '2px 20px', boxSizing: 'border-box', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Interest</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px',  }}
                      placeholder="Enter"
                      name="interest"
                      type="text"
                      value={this.state.interest}
                      onChange={this.handleChangeIncomeFromOtherSource}

                    ></input>
                  </div>

                </AccordionDetails>
                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <div style={{ marginTop: '-10', padding: '2px 20px', boxSizing: 'border-box', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Commision/Other Income</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                      placeholder="Enter"
                      name="commision"
                      type="text"
                      value={this.state.commision}
                      onChange={this.handleChangeIncomeFromOtherSource}

                    ></input>
                  </div>

                </AccordionDetails>
                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <div style={{ marginBottom: '10px', marginTop: '-10px', padding: '2px 20px', boxSizing: 'border-box', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Winning from Lottery, Crossword Puzzles, etc.</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px',  }}
                      placeholder="Enter"
                      name="crossword"
                      type="text"
                      value={this.state.crossword}
                      onChange={this.handleChangeIncomeFromOtherSource}

                    ></input>
                  </div>

                </AccordionDetails>


              </div>

            </Accordion>

            <div style={{ width: '100%', padding: '2px 20px', boxSizing: 'border-box', border: '1px solid #d3d3d3', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px' }}>

              <h3>Profits and Gains of Business or Profession (enter profit only)</h3>
              <div>

                <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px',  }}
                  placeholder="Enter"
                  type="text"
                  name="profitAndGains"
                  value={this.state.profitAndGains}
                  onChange={this.handleChangeNumber}
                ></input>
              </div>
            </div>
            <div style={{ width: '100%', padding: '2px 20px', boxSizing: 'border-box', border: '1px solid #d3d3d3', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px' }}>

              <h3>Agricultural Income</h3>
              <div>

                <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px',  }}
                  placeholder="Enter"
                  type="text"
                  name="agricultureIncome"
                  value={this.state.agricultureIncome}
                  onChange={this.handleChangeNumber}
                ></input>
              </div>
            </div>

            <Accordion square expanded={this.state.expandedDeductions} style={{width: '100%'}}>

              <div style={{  width: '100%', padding: '2px 20px', boxSizing: 'border-box', border: '1px solid #d3d3d3', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px' }}>

                <h3>Deductions</h3>
                <div>
                  <StyledButton onClick={this.toggleAccordionDeductions} id="toggleButtonDeductions" style={{ marginRight: '15px', backgroundColor: 'white', color: '#347152', fontWeight: 700 , fontFamily:"Nunito" , fontSize:"10px"}} disableElevation disableFocusRipple disableRipple size='medium' variant='outlined'>{this.state.expandedDeductions ? "Hide Details" : "Show Details"}</StyledButton>
                  <input style={{ width: '180px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8',  paddingRight: '10px' }}
                    name="deduction"
                    value={this.state.deduction}
                    onChange={this.handleChangeNumber}
                    type="text"
                  ></input>
                </div>

              </div>

              <div >
                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <div style={{ marginTop: '20px', padding: '2px 20px', boxSizing: 'border-box', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Life Insurance premium paid</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                      placeholder="Enter"
                      name="LiftInsurancePremiumPaid"
                      type="text"
                      onChange={this.handleDeductionsChange}
                      value={this.state.LiftInsurancePremiumPaid}

                    ></input>
                  </div>

                </AccordionDetails>
                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <div style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Payment for annuity plan</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                      placeholder="Enter"
                      name="PaymentForAnnuityPlan"
                      type="text"
                      onChange={this.handleDeductionsChange}
                      value={this.state.PaymentForAnnuityPlan}

                    ></input>
                  </div>

                </AccordionDetails>
                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <div style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Contribution toward provident fund / PPF</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                      placeholder="Enter"
                      name="ContributionTowardProvidentFundPPF"
                      type="text"
                      onChange={this.handleDeductionsChange}
                      value={this.state.ContributionTowardProvidentFundPPF}

                    ></input>
                  </div>

                </AccordionDetails>

                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <div style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Investment in NSC (VIII issue) + Interest</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                      placeholder="Enter"
                      name="InvestmentInNSC"
                      type="text"
                      onChange={this.handleDeductionsChange}
                      value={this.state.InvestmentInNSC}

                    ></input>
                  </div>

                </AccordionDetails>

                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <div style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Contribution towards ULIP</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                      placeholder="Enter"
                      name="ContributionTowardsULIP"
                      type="text"
                      onChange={this.handleDeductionsChange}
                      value={this.state.ContributionTowardsULIP}
                    ></input>
                  </div>

                </AccordionDetails>

                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <div style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Contribution towards notified pension fund by MF/UTI</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                      placeholder="Enter"
                      name="ContributionTowardsUTI"
                      type="text"
                      onChange={this.handleDeductionsChange}
                      value={this.state.ContributionTowardsUTI}
                    ></input>
                  </div>

                </AccordionDetails>
                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <div style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Re-payment of housing loan etc</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                      placeholder="Enter"
                      name="Re_PaymentOfHousingLoan"
                      type="text"
                      onChange={this.handleDeductionsChange}
                      value={this.state.Re_PaymentOfHousingLoan}

                    ></input>
                  </div>

                </AccordionDetails>

                <AccordionDetails style={{ backgroundColor: '#f3f3f3' }}>
                  <div style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Tuition fees paid for children</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px' }}
                      placeholder="Enter"
                      name="TuitionFees"
                      type="text"
                      onChange={this.handleDeductionsChange}
                      value={this.state.TuitionFees}
                    ></input>
                  </div>
                </AccordionDetails>

                <AccordionDetails style={{ backgroundColor: '#f3f3f3' }}>
                  <div style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>5 Years fixed deposit with PO or Schedule Bank</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px' }}
                      placeholder="Enter"
                      name="FixedDeposit"
                      type="text"
                      onChange={this.handleDeductionsChange}
                      value={this.state.FixedDeposit}
                    ></input>
                  </div>
                </AccordionDetails>
                <AccordionDetails style={{ backgroundColor: '#f3f3f3' }}>
                  <div style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Contribution toward NPF</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px' }}
                      placeholder="Enter"
                      name="ContributionTowardsNPF"
                      type="text"
                      onChange={this.handleDeductionsChange}
                      value={this.state.ContributionTowardsNPF}
                    ></input>
                  </div>
                </AccordionDetails>

                <AccordionDetails style={{ backgroundColor: '#f3f3f3' }}>
                  <div style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Employee's / Self-employed contribution toward NPS (up to 20%) (u/s 80CCD)</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px' }}
                      placeholder="Enter"
                      name="EmployeeContributionNPS"
                      type="text"
                      onChange={this.handleDeductionsChange}
                      value={this.state.EmployeeContributionNPS}
                    ></input>
                  </div>
                </AccordionDetails>

                <AccordionDetails style={{ backgroundColor: '#f3f3f3' }}>
                  <div style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Additional contribution towards NPS (u/s 80CCD(1B))</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px' }}
                      placeholder="Enter"
                      name="AdditionalContributionNPS"
                      type="text"
                      onChange={this.handleDeductionsChange}
                      value={this.state.AdditionalContributionNPS}
                    ></input>
                  </div>
                </AccordionDetails>

                <AccordionDetails style={{ backgroundColor: '#f3f3f3' }}>
                  <div style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Employer's contribution toward NPS (u/s 80CCD)</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px' }}
                      placeholder="Enter"
                      name="EmployerContributionNPS"
                      type="text"
                      onChange={this.handleDeductionsChange}
                      value={this.state.EmployerContributionNPS}
                    ></input>
                  </div>
                </AccordionDetails>

                <AccordionDetails style={{ backgroundColor: '#f3f3f3' }}>
                  <div style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Long-term infrastructure bonds (u/s 80CCF)</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px' }}
                      placeholder="Enter"
                      name="InfrastructureBonds"
                      type="text"
                      onChange={this.handleDeductionsChange}
                      value={this.state.InfrastructureBonds}
                    ></input>
                  </div>
                </AccordionDetails>

                <AccordionDetails style={{ backgroundColor: '#f3f3f3' }}>
                  <div style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Investment under equity saving scheme (u/s 80CCG)</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px' }}
                      placeholder="Enter"
                      name="EquitySavingScheme"
                      type="text"
                      onChange={this.handleDeductionsChange2}
                      value={this.state.EquitySavingScheme}
                    ></input>
                  </div>
                </AccordionDetails>

                <AccordionDetails style={{ backgroundColor: '#f3f3f3' }}>
                  <div style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Deposit with Sukanya Samridhi Accounts</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px' }}
                      placeholder="Enter"
                      name="SukanyaSamridhiDeposit"
                      type="text"
                      onChange={this.handleDeductionsChange2}
                      value={this.state.SukanyaSamridhiDeposit}
                    ></input>
                  </div>
                </AccordionDetails>

                <AccordionDetails style={{ backgroundColor: '#f3f3f3' }}>
                  <div style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Any other deductible (u/s 80C)</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px' }}
                      placeholder="Enter"
                      name="OtherDeductible80C"
                      type="text"
                      onChange={this.handleDeductionsChange2}
                      value={this.state.OtherDeductible80C}
                    ></input>
                  </div>
                </AccordionDetails>

                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <Box style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Total</h3>

                    <input style={{ width: '180px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8',  paddingRight: '10px' }}

                      name="TotaInsideDeduction"
                      type="text"
                      onChange={this.handleDeductionsChange2}
                      value={this.state.TotaInsideDeduction}
                    ></input>
                  </Box>

                </AccordionDetails>

                <AccordionDetails style={{ backgroundColor: '#f3f3f3' }}>
                  <div style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Medi-claim premium (u/s 80D)</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px' }}
                      placeholder="Enter"
                      name="MediClaimPremium"
                      type="text"
                      onChange={this.handleDeductionsChange2}
                      value={this.state.MediClaimPremium}
                    ></input>
                  </div>
                </AccordionDetails>

                <AccordionDetails style={{ backgroundColor: '#f3f3f3' }}>
                  <div style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Actual payment towards medical treatment (u/s 80DDB)</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px' }}
                      placeholder="Enter"
                      name="MedicalTreatmentPayment"
                      type="text"
                      onChange={this.handleDeductionsChange2}
                      value={this.state.MedicalTreatmentPayment}
                    ></input>
                  </div>
                </AccordionDetails>

                <AccordionDetails style={{ backgroundColor: '#f3f3f3' }}>
                  <div style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Donations (u/s 80G)</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px' }}
                      placeholder="Enter"
                      name="Donations80G"
                      type="text"
                      onChange={this.handleDeductionsChange2}
                      value={this.state.Donations80G}
                    ></input>
                  </div>
                </AccordionDetails>




                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <Box style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Deduction for maintenance / medical treatment of dependent (u/s 80DD)</h3>

                    <input style={{ width: '180px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8',  paddingRight: '10px' }}

                      name="DeductionForMaintenance"
                      type="text"
                      onChange={this.handleDeductionsChange2}
                      value={this.state.DeductionForMaintenance}
                    ></input>
                  </Box>

                </AccordionDetails>

                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <Box style={{ backgroundColor: 'white', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '-10px', padding: '2px 20px', }}>

                    <h3><span><input style={{ height: '20px', width: '20px' }} type="checkbox" /></span> Tick if 80DD is claimed</h3>
                  </Box>

                </AccordionDetails >

                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <Box style={{ padding: '2px 20px', width: '100%', borderRadius: '4px', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'white', marginTop: '-10px' }}>

                    <h3><span><input style={{ width: '20px', height: '20px', }} type="checkbox" /></span> Tick if severe disability</h3>
                  </Box>

                </AccordionDetails >

                <AccordionDetails style={{ backgroundColor: '#f3f3f3' }}>
                  <div style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Interest on loan for higher education (u/s 80E)</h3>

                    <input style={{ fontSize: '14px', paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', }}
                      placeholder="Enter"
                      name="LoanForHigherEducation"
                      type="text"
                      onChange={this.handleDeductionsChange2}
                      value={this.state.LoanForHigherEducation}
                    ></input>
                  </div>
                </AccordionDetails>

                <AccordionDetails style={{ backgroundColor: '#f3f3f3' }}>
                  <div style={{ padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', marginTop: '-10px', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Interest on loan taken for Residential House (u/s 80EE)</h3>

                    <input style={{ width: '180px', height: '33px', border: '1px solid grey', paddingLeft: '10px', borderRadius: '4px', fontSize: '14px' }}
                      placeholder="Enter"
                      name="LoanForResidentialHouse"
                      type="text"
                      onChange={this.handleDeductionsChange2}
                      value={this.state.LoanForResidentialHouse}
                    ></input>
                  </div>
                </AccordionDetails>

                <AccordionDetails style={{ backgroundColor: '#f3f3f3' }}>
                  <div style={{ alignItems: 'center', marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Deduction in case of a person with disability (u/s 80U)</h3>

                    <input style={{ paddingLeft: '10px', fontSize: '14px', width: '180px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', backgroundColor: '#d8d8d8', }}

                      name="DisabilityDeduction"
                      type="text"
                      onChange={this.handleDeductionsChange2}
                      value={this.state.DisabilityDeduction}
                    ></input>
                  </div>
                </AccordionDetails>




                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <Box style={{ borderRadius: '4px', width: '100%', padding: '2px 20px', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'white', marginTop: '-10px' }}>

                    <h3><span><input style={{ height: '20px', width: '20px' }} type="checkbox" /></span> Tick if 80DD is claimed</h3>
                  </Box>

                </AccordionDetails >

                <AccordionDetails style={{ backgroundColor: '#f3f3f3', }} >
                  <Box style={{ marginTop: '-10px', height: '50px', padding: '2px 20px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white', }}>

                    <h3><span><input style={{ height: '20px', width: '20px' }} type="checkbox" /></span> Tick if severe disability</h3>
                  </Box>

                </AccordionDetails >

                <AccordionDetails style={{ backgroundColor: '#f3f3f3' }}>
                  <div style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Interest on deposits in a saving account (u/s 80TTA)</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px' }}
                      placeholder="Enter"
                      name="InterestOnSavings"
                      type="text"
                      onChange={this.handleDeductionsChange2}
                      value={this.state.InterestOnSavings}
                    ></input>
                  </div>
                </AccordionDetails>

                <AccordionDetails style={{ backgroundColor: '#f3f3f3' }}>
                  <div style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white' }}>
                    <h3>Contribution to Agnipath Scheme (u/s 80CCH)</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px' }}
                      placeholder="Enter"
                      name="AgnipathSchemeContribution"
                      type="text"
                      onChange={this.handleDeductionsChange2}
                      value={this.state.AgnipathSchemeContribution}
                    ></input>
                  </div>
                </AccordionDetails>

                <AccordionDetails style={{ backgroundColor: '#f3f3f3' }}>
                  <div style={{ marginTop: '-10px', padding: '2px 20px', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', backgroundColor: 'white', marginBottom: '10px' }}>
                    <h3>Any other deductions</h3>

                    <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px' }}
                      placeholder="Enter"
                      name="OtherDeductions"
                      type="text"
                      onChange={this.handleDeductionsChange2}
                      value={this.state.OtherDeductions}
                    ></input>
                  </div>
                </AccordionDetails>


              </div>

            </Accordion>

            <div style={{ padding: '2px 20px', boxSizing: 'border-box', border: '1px solid #d3d3d3', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px' }}>

              <h3>Net Taxable Income</h3>
              <div>

                <input style={{ width: '180px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8',  paddingRight: '10px' }}
                  name="netTaxableIncome"
                  type="text"
                  value={this.state.netTaxableIncome}
                  onChange={this.handleChangeNumber}
                ></input>
              </div>
            </div>

            <div style={{ padding: '2px 20px', boxSizing: 'border-box', border: '1px solid #d3d3d3', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px', paddingRight: '20px' }}>

              <h3 style={{ whiteSpace: 'pre-line' }}>
                Income Liable to Tax at{'\n'}
                Normal Rate ---
              </h3>

              <div>

                <div>
                  <input style={{
                    height: '33px', border: '1px solid #d8d8d8', fontSize: '18px', margin: '0px 15px 0px 15px', borderRadius: '4px',
                    backgroundColor: '#d8d8d8', width: '180px', paddingRight: '10px',                   }}
                    name="normalRate1"
                    type="text"
                    value={this.state.normalRate1}
                    onChange={this.handleChangeNumber}
                  ></input>
                  <input style={{
                    height: '33px', border: '1px solid #d8d8d8', fontSize: '18px', borderRadius: '4px',
                    backgroundColor: '#d8d8d8', width: '180px', paddingRight: '10px',                   }}
                    name="normalRate2"
                    type="text"
                    value={this.state.normalRate2}
                    onChange={this.handleChangeNumber}
                  ></input>
                </div>
              </div>
            </div>

            <div style={{ padding: '2px 20px', boxSizing: 'border-box', alignItems: 'center', border: '1px solid #d3d3d3', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', borderRadius: '4px', marginTop: '10px', paddingRight: '20px' }}>

              <h3 style={{ whiteSpace: 'pre-line' }}>
                Short Term Capital Gains{'\n'}
                (Covered u/s 111A) 15%
              </h3>

              <div>

                <div>
                  <input style={{
                    height: '33px', border: '1px solid #d8d8d8', fontSize: '18px', margin: '0px 15px 0px 15px', borderRadius: '4px',
                    backgroundColor: '#d8d8d8', width: '180px', paddingRight: '10px',                   }}
                    name="shortTermCapitalGains"
                    type="text"
                    value={this.state.shortTermCapitalGains}
                    onChange={this.handleChangeNumber}
                  ></input>
                  <input style={{
                    height: '33px', border: '1px solid #d8d8d8', fontSize: '18px', borderRadius: '4px',
                    backgroundColor: '#d8d8d8', width: '180px', paddingRight: '10px',                   }}
                    name="shortTermCapitalGains2"
                    type="text"
                    value={this.state.shortTermCapitalGains2}
                    onChange={this.handleChangeNumber}
                  ></input>
                </div>
              </div>
            </div>

            <div style={{padding: '2px 20px', boxSizing: 'border-box', borderRadius: '4px', marginTop: '10px', border: '1px solid #d3d3d3', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: '20px' }}>

              <h3 style={{ whiteSpace: 'pre-line' }}>
                Long Term Capital Gains{'\n'}
                (Charge to tax @ 20%) 20%
              </h3>

              <div>

                <div>
                  <input style={{
                    height: '33px', border: '1px solid #d8d8d8', fontSize: '18px', margin: '0px 15px 0px 15px', borderRadius: '4px',
                    backgroundColor: '#d8d8d8', width: '180px', paddingRight: '10px',                   }}
                    name="longTernCapitalGains"
                    type="text"
                    value={this.state.longTernCapitalGains}
                    onChange={this.handleChangeNumber}
                  ></input>
                  <input style={{
                    height: '33px', border: '1px solid #d8d8d8', fontSize: '18px', borderRadius: '4px',
                    backgroundColor: '#d8d8d8', width: '180px', paddingRight: '10px',                   }}
                  name="longTernCapitalGains2"
                  type="text"
                  value={this.state.longTernCapitalGains2}
                    onChange={this.handleChangeNumber}
                  ></input>
                </div>
              </div>
            </div>

            <div style={{ padding: '2px 20px', boxSizing: 'border-box', border: '1px solid #d3d3d3', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px', paddingRight: '20px' }}>

              <h3 style={{ whiteSpace: 'pre-line' }}>
                Long Term Capital Gains{'\n'}
                (Charge to tax @ 10%) 10%
              </h3>

              <div>

                <div>
                  <input style={{
                    height: '33px', border: '1px solid #d8d8d8', fontSize: '18px', margin: '0px 15px 0px 15px', borderRadius: '4px',
                    backgroundColor: '#d8d8d8', width: '180px', paddingRight: '10px',                   }}
                    name="longTermAt10"
                    type="text"
                    value={this.state.longTermAt10}
                    onChange={this.handleChangeNumber}
                  ></input>
                  <input style={{
                    height: '33px', border: '1px solid #d8d8d8', fontSize: '18px', borderRadius: '4px',
                    backgroundColor: '#d8d8d8', width: '180px', paddingRight: '10px',                   }}
                    name="longTermAt102"
                    type="text"
                    value={this.state.longTermAt102}
                    onChange={this.handleChangeNumber}
                  ></input>
                </div>
              </div>
            </div>

            <div style={{ padding: '2px 20px', boxSizing: 'border-box', border: '1px solid #d3d3d3', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px', paddingRight: '20px' }}>

              <h3 style={{ whiteSpace: 'pre-line' }}>
                Winnings from Lottery,{'\n'}
                (Crossword Puzzles, etc) 30%
              </h3>

              <div>

                <div>
                  <input style={{
                    height: '33px', border: '1px solid #d8d8d8', fontSize: '18px', margin: '0px 15px 0px 15px', borderRadius: '4px',
                    backgroundColor: '#d8d8d8', width: '180px', paddingRight: '10px',                   }}
                    name="winningLottery"
                    value={this.state.winningLottery}
                    onChange={this.handleChange2}
                  ></input>
                  <input style={{
                    height: '33px', border: '1px solid #d8d8d8', fontSize: '18px', borderRadius: '4px',
                    backgroundColor: '#d8d8d8', width: '180px', paddingRight: '10px',                   }}
                    name="winningLottery2"
                    value={this.state.winningLottery2}
                    onChange={this.handleChange2}
                  ></input>
                </div>
              </div>
            </div>

            <div style={{ padding: '2px 20px', boxSizing: 'border-box', border: '1px solid #d3d3d3', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px' }}>

              <h3>Income Tax</h3>
              <div>

                <input style={{ width: '180px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8', paddingRight: '10px', }}
                  name="incomeTax"
                  type="text"
                  value={this.state.incomeTax}
                  onChange={this.handleChangeNumber}

                ></input>
              </div>
            </div>

            <div style={{ padding: '2px 20px', boxSizing: 'border-box', border: '1px solid #d3d3d3', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px' }}>

              <h3>Surcharge</h3>
              <div>

                <input style={{ width: '180px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8', paddingRight: '10px', }}
                  name="surcharge"
                  type="text"
                  value={this.state.surcharge}
                  onChange={this.handleChangeNumber}
                ></input>
              </div>
            </div>

            <div style={{ padding: '2px 20px', boxSizing: 'border-box', border: '1px solid #d3d3d3', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px' }}>

              <h3>Education Cess</h3>
              <div>

                <input style={{ width: '180px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8', paddingRight: '10px', }}
                  name="educationCess"
                  type="text"
                  value={this.state.educationCess}
                  onChange={this.handleChangeNumber}
                ></input>
              </div>
            </div>

            <div style={{ padding: '2px 20px', boxSizing: 'border-box', border: '1px solid #d3d3d3', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px' }}>

              <h3>Secondary and higher education cess</h3>
              <div>

                <input style={{ width: '180px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8', paddingRight: '10px', }}
                  name="secondaryAndHighEducationCesss"
                  type="text"
                  value={this.state.secondaryAndHighEducationCesss}
                  onChange={this.handleChangeNumber}
                ></input>
              </div>
            </div>

            <div style={{ padding: '2px 20px', boxSizing: 'border-box', border: '1px solid #d3d3d3', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px' }}>

              <h3>Total Tax Liability</h3>
              <div>

                <input style={{ width: '180px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8', paddingRight: '10px', }}
                  name="totalTaxLiabitlity"
                  type="text"
                  value={this.state.totalTaxLiabitlity}
                  onChange={this.handleChangeNumber}
                ></input>
              </div>
            </div>

            <div style={{ padding: '2px 20px', boxSizing: 'border-box', border: '1px solid #d3d3d3', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px' }}>

              <h3>Due date of submission of return</h3>
              <div>

              <DatePicker
                    placeholder="DD/MM/YYYY"
                    data-testid="date_of_birth"
                    format="DD/MM/YYYY"
                    name="date_of_birth"
                    maxDate={Date.now()}
                    value={this.state.dueDate}
                    onChange={this.handleChangeBottomValues}
                    render={(value, openCalendar) => {
                      return (
                        <input
                          value={value}
                          readOnly
                          onFocus={openCalendar}
                          placeholder="DD/MM/YYYY"
                          style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                          name="date_of_birth"
                        />
                      );
                    }}
                  />
              </div>
            </div>
            <div style={{ padding: '2px 20px', boxSizing: 'border-box', border: '1px solid #d3d3d3', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px' }}>

              <h3>Actual date of submission of return/date of completion of assessment</h3>
              <div>

              <DatePicker
                    placeholder="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    maxDate={Date.now()}
                    onChange={(date) => this.handleDateChange(date)}
                    value={this.state.actualDate}
                    render={
                        <input
                          value={this.state.actualDate}
                          readOnly
                          placeholder="DD/MM/YYYY"
                          style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', }}
                        />
                      }
                  />
              </div>
            </div>

            <div style={{ padding: '2px 20px', boxSizing: 'border-box', border: '1px solid #d3d3d3', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px' }}>

              <h3>Relief</h3>
              <div>

                <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px',  }}
                  placeholder="Enter"
                  name="relief"
                  type="text"
                  value={this.state.relief}
                  onChange={this.handleChangeNumber}
                ></input>
              </div>
            </div>

            <div style={{ padding: '2px 20px', boxSizing: 'border-box', border: '1px solid #d3d3d3', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px' }}>

              <h3>TDS/TCS/MAT (AMT) Credit Utilized</h3>
              <div>

                <input style={{ paddingLeft: '10px', width: '180px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px',  }}
                  placeholder="Enter"
                  name="tds"
                  type="text"
                  value={this.state.tds}
                  onChange={this.handleChangeNumber}
                ></input>
              </div>
            </div>

            <div style={{ padding: '2px 20px', boxSizing: 'border-box', border: '1px solid grey', width: '100%', display: 'flex', borderRadius: '4px', marginTop: '10px', flexDirection: 'column' }}>

              <h3 style={{ marginBottom: '20px' }}>Detail of tax paid</h3>
              <div style={{ marginBottom: '10px' }}>
                <div style={{display: 'flex'}}>
                  <div style={{ color: 'white', background: '#646464', padding: '5px', paddingLeft: '10px', borderRight: "3px solid #484848",flex: '1 40%' }}>Date</div>
                  <div style={{ color: 'white', background: '#646464', padding: '5px', paddingLeft: '20px', borderRight: "3px solid #484848",flex: '1 40%'  }}>Amount</div>
                  <div style={{ color: 'white', background: '#646464', padding: '5px', textAlign: 'center',flex: '1 20%'  }}><span style={{
                    visibility: 'hidden'
                  }}> a
                  </span></div>
                </div>

                <CustomeDiv>
                {this.state.taxPaidDetail.map((i, idx) => (
                <>
                <div style={{    display: 'flex',
    alignItems: 'baseline', width: '100%'}}>
                <DatePicker
                    placeholder="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                    maxDate={Date.now()}
                    name='date'
                    onChange={(date) => this.handleDateChangeValues(idx, 'date', date)}
                    value={i.date}
                    render={
                        <input
                          readOnly
                          value={i.date}
                          placeholder="DD/MM/YYYY"
                          style={{ paddingLeft: '10px', height: '33px', border: '1px solid grey', borderRadius: '4px', fontSize: '14px', width: '100%'}} />
                     } />
                    <input name="amount" value={i.amount} onChange={(e) => this.handleChangeTaxDetail(idx, 'amount', e.target.value)} type="text" style={{ marginTop: '10px', paddingLeft: '10px', width: '95%', height: '33px', border: '1px solid grey', fontSize: '18px', marginLeft: '10px',display: 'flex', flex:'1 40%' }} />
                    <input type="text" name="subamount" value={i.subamount} onChange={(e) => this.handleChangeTaxDetail(idx, 'subamount', e.target.value)} style={{ marginTop: '10px', paddingLeft: '10px', width: '93%', height: '33px', border: '1px solid grey', fontSize: '18px', marginLeft: '10px',display: 'flex', flex:'1 20%' }} />
                  </div>
                    </>
                  ))}
                </CustomeDiv>
              </div>
              <StyledButton style={{ height: '40px', fontSize: '14px', marginRight: '10px', width: '150px', marginBottom: '20px', fontWeight: 700,fontFamily:"Nunito", borderRadius: '2px' }} id="ShowDetailsBtnID" disableElevation disableFocusRipple disableRipple onClick={this.toggleAccordion} size='medium' variant='outlined'>Add Row</StyledButton>

            </div>

            <div style={{ padding: '2px 20px', boxSizing: 'border-box', border: '1px solid #d3d3d3', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px' }}>

              <h3>Amount of interest u/s 234A</h3>
              <div>

                <input style={{ width: '180px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8', paddingRight: '10px', }}
                  name="A234"
                  type="text"
                  value={this.state.A234}
                  onChange={this.handleChangeNumber}

                ></input>
              </div>
            </div>

            <div style={{ padding: '2px 20px', boxSizing: 'border-box', border: '1px solid #d3d3d3', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px' }}>

              <h3>Amount of interest u/s 234B</h3>
              <div>

                <input style={{ width: '180px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8', paddingRight: '10px', }}
                  name="B234"
                  type="text"
                  value={this.state.B234}
                  onChange={this.handleChangeNumber}
                ></input>
              </div>
            </div>

            <div style={{ padding: '2px 20px', boxSizing: 'border-box', border: '1px solid #d3d3d3', width: '100%', height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', marginTop: '10px' }}>

              <h3>Amount of interest u/s 234C</h3>
              <div>

                <input style={{ width: '180px', height: '33px', border: '1px solid #d8d8d8', borderRadius: '4px', fontSize: '18px', backgroundColor: '#d8d8d8', paddingRight: '10px', }}
                  name="C234"
                  type="text"
                  value={this.state.C234}
                  onChange={this.handleChangeNumber}
                ></input>
              </div>
            </div>
            </Box>
            <div style={{ width: '85%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px', marginTop: '50px', }} >
              <button onClick={this.handleFormSubmit} data-test-id="form_id" style={{ height: '45px', width: '170px', color: 'white', backgroundColor: '#347152', fontSize: '16px', margin: '10px', fontWeight: "bold", borderRadius: '4px', fontFamily: 'sans-serif' }}>Calculate</button>

              <button style={{
                height: '45px', width: '170px', color: '#347152',
                border: '1px solid #347152', fontSize: '16px', margin: '10px',
                fontWeight: 'bold', borderRadius: '4px', backgroundColor: 'white', fontFamily: 'sans-serif'
              }} data-test-id="reset-id" onClick={this.handleReset}>Reset</button>
            </div>
          </Box>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomeDiv = styled('div')({
  display: 'flex',
  alignItems: 'baseline',
  flexDirection: 'column',
  "& .rmdp-container": {
    display: 'flex !important',
    flex: '1 40% !important',
    // '& div:first-child': {
    //   width: '100%'
    // },
  },
  "& .rmdp-container > div:first-child":{
    width: '100%',
  }
 
});
const StyledSelect = withStyles((theme) => ({
  root: {
    fontSize: '14px',
    width: '143px',
    padding: '5px',
    paddingLeft: '10px',
    border: '1px solid grey',
    borderRadius: '4px',
    height: '20px',
    paddingTop: '6px',
    '& .MuiSvgIcon-root': {
      color: "red !important",
      marginRight: '10px', // Adjust the value according to your needs
    },
  },
}))(Select);
const arrowDownIcon = styled(ExpandMoreIcon)({
  marginRight: "10px"
})
const Accordion = withStyles({
  root: {
    padding: '0px',
    // backgroundColor: "pink",
    width: '85.5%',
    // border: '1px solid blue',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);


const AccordionDetails = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
    // width: 'inherit',
  },
}))(MuiAccordionDetails);


const StyledButton = withStyles((theme) => ({
  root: {
    color: 'green',
    border: '1px solid green',
    borderRadius: '0px',
    fontSize: '10px',
    "&:hover": {
      border: '3=2px solid green',
    }
  }
}))(Button)




// Customizable Area End
