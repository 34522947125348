import React from "react";
// Customizable Area Start
import {
    Container,
    Box,
    withStyles,
    Button,
    Typography,
}
    from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { walletmoney, wallet, walletminus, dollarsquare, rupee } from "./assets"
import SalaryCustom from "../../../components/src/SalaryCustom.web";
import SalaryListCostum from "../../../components/src/SalaryListCostum.web";
import DialogSalaryList from "../../../components/src/DialogSalaryList.web";


import StaticSalaryController, {
    Props,
    configJSON,
} from "./StaticSalaryController.web";
import DashboardPanselector from "../../dashboard/src/DashboardPanselector.web";
import SalaryDetailsEdit from "./SalaryDetailsEdit.web";


export class CompanyDetails extends StaticSalaryController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const { classes } = this.props;

        return (
            <>
                <DashboardPanselector navigation={undefined} id={""} data-test-id="pancard" />
                {this.state.formNumber !== 3 && (
                    <Container maxWidth={false} className={classes.mainWrapper}>
                        <Box className={classes.headingButton}>
                            <Typography variant="h4" className={classes.companyNameHeading} style={{ textAlign: "center" }}>{configJSON.CompanyDetails}</Typography>
                            <Box className={classes.headingButtonalign} >
                                <Button className={classes.form16Button} onClick={this.handleNavigateToDoc} data-test-id="upload_form">{configJSON.UploadForm16}</Button>
                                <Button className={classes.employerButton} onClick={this.handleNavigateToForm2} data-test-id="add_emp">  <AddIcon /> {configJSON.Addemployer}</Button>
                            </Box>
                        </Box>

                        {this.state.employers && this.state.employers.map((item: any) => {
                            return (
                                <Box className={classes.backgroundWrapper}>
                                    <Box className={classes.companyList}>
                                        <Typography variant="h5" className={classes.companyNameHeading}>{item.attributes.emp_company}</Typography>
                                    </Box>
                                    <Box>
                                        <SalaryCustom
                                            id="gross-salary"
                                            icontype={walletmoney}
                                            icon={rupee}
                                            Amount={item.attributes.gross_salary}
                                            name="Gross Salary"
                                            isActive={this.state.activeItems["gross-salary" + item.id]}
                                            data-test-id={"dropdown" + item.id}
                                            onClickActive={this.handleActiveDrop.bind(this, "gross-salary" + item.id)}
                                        />
                                        {
                                            this.state.activeItems["gross-salary" + item.id] &&
                                            <SalaryListCostum icon={rupee}
                                                Amount="1000"
                                                name={item.attributes.Gross_Salary_list} />}
                                    </Box>
                                    <Box>
                                        <SalaryCustom
                                            id="allowances"
                                            icontype={wallet}
                                            icon={rupee}
                                            Amount={item.attributes.Allowances.total_amount_of_exemption_claimed_under_section_10}
                                            name="Allowances "
                                            data-test-id={"dropdownAllowances" + item.id}
                                            isActive={this.state.activeItems["allowances" + item.id]}
                                            onClickActive={this.handleActiveDrop.bind(this, "allowances" + item.id)}
                                        />
                                        {
                                            this.state.activeItems["allowances" + item.id] &&
                                            <SalaryListCostum icon={rupee}
                                                Amount="1000"
                                                name={item.attributes.Allowances_list} />}
                                    </Box>
                                    <Box>
                                        <SalaryCustom
                                            id="Deductions"
                                            icontype={walletminus}
                                            icon={rupee}
                                            Amount={item.attributes.Deductions}
                                            name="Deductions"
                                            data-test-id={"dropdownDeductions" + item.id}
                                            isActive={this.state.activeItems["Deductions" + item.id]}
                                            onClickActive={this.handleActiveDrop.bind(this, "Deductions" + item.id)}
                                        />
                                        {
                                            this.state.activeItems["Deductions" + item.id] &&
                                            <SalaryListCostum icon={rupee}
                                                Amount="1000"
                                                name={item.attributes.Deductions_list} />}
                                    </Box>
                                    <Box>
                                        <SalaryCustom icontype={dollarsquare}
                                            id="Net Salary"
                                            icon={rupee}
                                            Amount={item.attributes.Net_Salary}
                                            name="Net Salary"
                                            data-test-id={"dropdownNet" + item.id}
                                            isActive={this.state.activeItems["Net-Salary" + item.id]}
                                            onClickActive={this.handleActiveDrop.bind(this, "Net-Salary" + item.id)}
                                        />
                                        {
                                            this.state.activeItems["Net-Salary" + item.id] &&
                                            <SalaryListCostum icon={rupee}
                                                Amount="1000"
                                                name={item.attributes.Net_Salary_list} />}
                                    </Box>
                                    {this.state.drawer && <DialogSalaryList data-test-id={"alter_pop" + item.id} onChildChange={this.handleChildChange} open={this.state.drawer} closeIcon={this.handleDrawer.bind(this, item.id, item.type)} handleUpdateData={this.handleUpdateData.bind(this,this.state.activeId.id, this.state.activeId.type)} />}
                                    <Box className={classes.proceedNext}>
                                        <Button className={classes.buttonsGroupA} >{configJSON.everythingfineButton}</Button>
                                        <Button className={classes.buttonsGroupB} onClick={this.handleDrawer.bind(this, item.id, item.type)} data-test-id={"alter_details" + item.id}>{configJSON.alterButton}</Button>
                                    </Box>
                                </Box>
                            )
                        })

                        }


                    </Container>)}
                {this.state.formNumber == 3 &&
                    <SalaryDetailsEdit data-test-id="salary_tabmanual" navigation={this.state.formsPresent} id={this.state.activeId} classes={undefined} activeStep={undefined} steps={undefined} step={this.state.step} accordianSteps={undefined} />
                }
            </>
        );
    }
}

const styles = {
    mainWrapper: {
        background: "#F4F6F5",
        borderRadius: "16px",
        margin: "1em 0 0 1em",
        "@media(max-width:484px)": {
            margin: "0 0 0 1em",
        },

    },
    companyNameHeading: {
        color: "#36403B",
        fontFamily: "Nunito",
        fontSize: "32px",
        fontWeight: 700,        
        pading: "0.4em"
    },
    backgroundWrapper: {
        borderRadius: "16px 10px 10px 16px",
        background: "#FFF",
        marginTop: '1em',
        padding: '2em 2em 2em 0em',
        "@media(max-width:484px)": {
            padding: '2em 0 2em 0em',
        },
    },
    form16Button: {
        borderRadius: "8px",
        background: "#337152",
        color: "#fff",
        width: "170px",
        padding: '0.5em ,0 0.5em,0',
        '&:hover': {
            background: "#337152",
        },
    },
    employerButton: {
        borderRadius: "8px",
        background: "#FFF5DC",
        color: "#C7AA61",
        width: "170px",
        padding: '0.5em 0 0.5em 0',
        margin: "0 0 0 2em",
        '&:hover': {
            background: "#FFF5DC",
        },
        " & .MuiButton-label": {
            justifyContent: "space-evenly"
        },

        "@media(max-width:701px)": {
            margin: "0",
        },

    },
    headingButton: {
        display: "flex",
        justifyContent: "space-between",
        padding: '4em 0em 0.3em 1em',
        marginRight: "2.2em",
        "@media(max-width:1036px)": {
            flexDirection: "column" as const,
            alignItems: "center"
        },
    },
    headingButtonalign: {
        display: "flex",
        "@media(max-width:701px)": {
            flexDirection: "column" as const,
            alignItems: "center",
            gap: "20px"
        },
    },
    companyList: {
        borderRadius: "10px",
        border: "1.5px solid #9B4945",
        background: "rgba(236, 132, 113, 0.40)",
        paddingLeft: '2em',
        marginLeft: '1em',
        "@media(max-width:400px)": {
            paddingLeft: '5px',
        },
    },

    buttonsGroupA: {
        borderRadius: "8px",
        padding: '0.5em 1em  0.5em 1em',
        background: "#337152",
        color: "#fff",
        "& .MuiButton-label": {
            fontSize: '11px',
            fontWeight: 500,
        },
        '&:hover': {
            background: "#337152",
        },
    },
    buttonsGroupB: {
        borderRadius: "8px",
        background: "#fff",
        padding: '0.5em 1em  0.5em 1em',
        marginLeft: "1em",
        border: "1px solid #337152",
        color: "#337152",
        "& .MuiButton-label": {
            fontSize: '11px',
            fontWeight: 500,
        },
        '&:hover': {
            background: "#fff",
        },
        "@media(max-width:484px)": {
            margin: "unset"
        },
    },
    proceedNext: {
        margin: "1em 0 0 1.7em",
        padding: "1em 0 1em 0",
        "@media(max-width:955px)": {
            margin: "unset",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: '20px'
        },
    },

}

export default withStyles(styles)(CompanyDetails);
// Customizable Area End
