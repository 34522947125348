import React from "react";

import BusinessIncomeController, {
    Props,
} from "./BusinessIncomeController.web";
import {
    Box,
    Button,
    Card,
    Container,
    styled,
    Typography,
    Grid,
    Snackbar,
    Slide
} from "@material-ui/core";
import {
    info,
    LeftArrow,
    downArrow
} from "./assets"
import { CustomTextfield } from "../../../components/src/CustomTextfield.web";
import Header from "../../../components/src/Header.web";
import { CustomAutocomplete } from "../../../components/src/StyledMuiComponent";
import ReturnGrid from "../../../components/src/ReturnGrid.web.";
import ErrorMessage from "../../../components/src/ErrorMessage.web";
import Alert from '@material-ui/lab/Alert';
import DashboardPanselector from "../../dashboard/src/DashboardPanselector.web";
export const RenderDropdown = (props: any) => {
    const { options, name, handleSetValue, errormessage } = props
    return (
        <>
            <Box className="OCA_autocomplete_box">
                <CustomAutocomplete
                    renderInput={(params: any) => <CustomTextfield
                        {...params}
                        variant="outlined"
                        fullWidth
                        placeholder="Select"
                        name={name}
                    />}
                    onChange={(e: any, value: any) => { handleSetValue(e, value) }}
                    options={options}
                    fullWidth
                />
                <img className="OCA_downArrow_img" src={downArrow} />
            </Box>
            {errormessage && <Box className="error_message">{errormessage}</Box>}
        </>
    )
}
export default class SmallBusiness extends BusinessIncomeController {
    constructor(props: Props) {
        super(props);
        this.handleCloseMemoized = this.handleCloseMemoized.bind(this);

    }
    handleCloseMemoized() {
        this.setState({ alertMessageShow: false });
    }
    render() {
        return (
            <MainWrapper maxWidth={false}>
                <Box className="body">
                <DashboardPanselector navigation={this.props.navigation} id={""} data-test-id="pancard" />
                    <Box className="SB_form">
                        <Box className="SB_main-title">
                            <img src={LeftArrow} data-testid="back_test" onClick={() => { this.handleNavigateToBackPage() }} />
                            <Typography className="SB_body_title-text">{this.state.activeSmallBusinessForm === 1 ? "Very small business (no accounts maintained)" : "Financial Particulars"}</Typography>
                        </Box>
                        <Card className="BS_card">
                            {this.state.activeSmallBusinessForm === 1 && <>
                                <Typography className="SB_body_title-text BS_font--22 mb-20">Nature of Business</Typography>
                                <Grid container className="grid_container">
                                    <Grid className="align_flex" item lg={5} sm={12} md={5}>
                                        <Typography className="font_style-1">Business or Profession</Typography>
                                    </Grid>
                                    <Grid item lg={7} sm={12} md={7}>
                                        <RenderDropdown
                                            data-testid="business_or_profession"
                                            options={["Business", "Profession"]}
                                            name="business_or_profession"
                                            value={this.state.smallBusinessFields.business_or_profession}
                                            handleSetValue={this.handleSetBusinessProfession}
                                            errormessage={this.state.activeSmallBusinessFormError.business_or_profession}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container className="grid_container">
                                    <Grid className="align_flex" item lg={5} sm={12} md={5}>
                                        <Typography className="font_style-1">Name of Business</Typography>
                                    </Grid>
                                    <Grid item lg={7} sm={12} md={7}>
                                        <CustomTextfield
                                            data-testid="name_of_business"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="Enter"
                                            name="name_of_business"
                                            value={this.state.smallBusinessFields.name_of_business}
                                            onChange={this.handleSetBusinessName}
                                        />
                                        {this.state.activeSmallBusinessFormError.name_of_business && <Box className="error_message">{this.state.activeSmallBusinessFormError.name_of_business}</Box>}
                                    </Grid>
                                </Grid>
                                <Grid container className="grid_container">
                                    <Grid className="align_flex" item lg={5} sm={12} md={5}>
                                        <Typography className="font_style-1">Business Code</Typography>
                                    </Grid>
                                    <Grid item lg={7} sm={12} md={7}>
                                        <RenderDropdown
                                            data-testid="business_code"
                                            handleGetValue={() => { }}
                                            options={this.state.businessCodeList}
                                            name="business_code"
                                            value={this.state.smallBusinessFields.business_code}
                                            handleSetValue={this.handleSetBusinessCode}
                                            errormessage={this.state.activeSmallBusinessFormError.business_code}
                                        />
                                    </Grid>
                                </Grid>
                                <Typography className="SB_body_title-text BS_font--22 mb-20">Receipts and Profit</Typography>
                                <ReturnGrid
                                    data-testid="gross_receipt_or_turnover"
                                    title="Gross Receipts/Turnover"
                                    readOnly={true}
                                    errormessage={this.state.activeSmallBusinessFormError.gross_receipt_or_turnover}
                                    name="gross_receipt_or_turnover"
                                    value={this.state.smallBusinessFields.gross_receipt_or_turnover}
                                />
                                <ReturnGrid
                                    data-testid="gross_receipt_through_cheques"
                                    title="Gross Receipts through cheques/electronic mode/banking system"
                                    readOnly={false}
                                    errormessage={this.state.activeSmallBusinessFormError.gross_receipt_through_cheques}
                                    name="gross_receipt_through_cheques"
                                    value={this.state.smallBusinessFields.gross_receipt_through_cheques}
                                    handleSetValue={this.handleSetSmallBusinessValue}
                                />
                                <ReturnGrid
                                    data-testid="gross_receipt_through_cash"
                                    title="Gross Receipts through Cash"
                                    readOnly={false}
                                    errormessage={this.state.activeSmallBusinessFormError.gross_receipt_through_cash}
                                    name="gross_receipt_through_cash"
                                    value={this.state.smallBusinessFields.gross_receipt_through_cash}
                                    handleSetValue={this.handleSetSmallBusinessValue}
                                />
                                <ReturnGrid
                                    data-testid="gross_profit"
                                    title="Gross Profit"
                                    readOnly={false}
                                    errormessage={this.state.activeSmallBusinessFormError.gross_profit}
                                    name="gross_profit"
                                    value={this.state.smallBusinessFields.gross_profit}
                                    handleSetValue={this.handleSetSmallBusinessValue}

                                />
                                <ReturnGrid
                                    data-testid="expenditure_related_to_activity"
                                    title="Expenditure related to such activity"
                                    readOnly={false}
                                    errormessage={this.state.activeSmallBusinessFormError.expenditure_related_to_activity}
                                    name="expenditure_related_to_activity"
                                    value={this.state.smallBusinessFields.expenditure_related_to_activity}
                                    handleSetValue={this.handleSetSmallBusinessValue}
                                />
                                <ReturnGrid
                                    data-testid="net_profit"
                                    title="Net Profit"
                                    readOnly={true}
                                    errormessage={null}
                                    name="net_profit"
                                    value={this.state.smallBusinessFields.net_profit}
                                />
                            </>
                            }
                            {this.state.activeSmallBusinessForm === 2 && <>
                                <Typography className="SB_text--2">On the basis of your current information, ITR 3 is required to be filed, therefore we only need 4 particulars from you to file the ITR</Typography>
                                <Box className="mt-30">
                                    <Typography className="detail_date SB_text--2" display="inline">Details as on 31.03.2023</Typography>
                                </Box>
                                <ReturnGrid
                                    data-testid="cash_balance"
                                    title="Cash Balance"
                                    errormessage={this.state.financialParticularsError.cash_balance}
                                    readOnly={false}
                                    name="cash_balance"
                                    value={this.state.smallBusinessFields.cash_balance}
                                    handleSetValue={this.handleSetSmallBusinessValue}
                                />
                                <ReturnGrid
                                    data-testid="inventory_stock"
                                    title="Inventory/Stock"
                                    errormessage={this.state.financialParticularsError.inventory_stock}
                                    readOnly={false}
                                    name="inventory_stock"
                                    value={this.state.smallBusinessFields.inventory_stock}
                                    handleSetValue={this.handleSetSmallBusinessValue}
                                />
                                <ReturnGrid
                                    data-testid="amounts_payable"
                                    title="Amounts Payable (Creditors)"
                                    errormessage={this.state.financialParticularsError.amounts_payable}
                                    readOnly={false}
                                    name="amounts_payable"
                                    value={this.state.smallBusinessFields.amounts_payable}
                                    handleSetValue={this.handleSetSmallBusinessValue}
                                />
                                <ReturnGrid
                                    data-testid="amounts_receivable"
                                    title="Amounts Receivable (Debtors)"
                                    errormessage={this.state.financialParticularsError.amounts_receivable}
                                    readOnly={false}
                                    name="amounts_receivable"
                                    value={this.state.smallBusinessFields.amounts_receivable}
                                    handleSetValue={this.handleSetSmallBusinessValue}
                                />
                            </>}
                        </Card>
                        <Button data-testid="next_submit_btn" className="btn-small" onClick={() => { this.handleSetActiveBusinessForm(2) }}>{this.state.activeSmallBusinessForm === 1 ? "Next" : "Submit"}</Button>
                        <Snackbar
                            TransitionComponent={Slide}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={this.state.alertMessageShow}
                            autoHideDuration={4000}
                            onClose={this.handleCloseMessageAlert}
                        >
                            <Alert severity={this.state.alertType}>
                                {this.state.alertMessage}
                            </Alert>
                        </Snackbar>
                    </Box>
                </Box>
            </MainWrapper>
        );
    }
}

const MainWrapper = styled(Container)({
    width: "100%",
    "& .body": {
        marginBottom: "24px"
    },
    "& .SB_main-title": {
        display: "flex",
        marginBottom: "30px",
        "&>img": {
            marginRight: "30px",
            cursor: "pointer"
        }
    },
    "& .SB_body_title-text": {
        color: "#36403B",
        fontFamily: "Nunito",
        fontSize: "27px",
        fontStyle: "normal",
        fontWeight: 700,
    },
    "& .SB_form": {
        backgroundColor: "#F4F6F5",
        padding: "30px",
        borderRadius: "16px"
    },
    "& .BS_card": {
        padding: '35px',
        borderRadius: "20px",
        alignItems: "center",
        marginBottom: "20px",
        boxShadow: "none",
        width: "95%",
    },
    "& .BS_font--22": {
        fontSize: "22px !important",
    },
    "& .grid_container": {
        width: "90%",
        marginBottom: "35px"
    },
    "& .align_flex": {
        display: "flex",
        alignItems: "center",
        marginBottom: "0px"
    },
    "& .font_style-1": {
        color: "rgba(54, 64, 59, 0.70)",
        fontFamily: "Nunito",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600
    },
    "& .OCA_autocomplete_box": {
        position: "relative"
    },
    "& .OCA_downArrow_img": {
        position: "absolute",
        right: "15px",
        bottom: "20px",
        zIndex: 1
    },
    "& .mb-20": {
        marginBottom: "20px"
    },
    "& .btn-small": {
        width: "198px",
        height: "46px",
        borderRadius: "10px",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        backgroundColor: "#337152",
        color: "#ffffff !important",
        marginTop: "20px"
    },
    "& .SB_text--2": {
        color: "#36403be8",
        fontFamily: "Nunito",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 600
    },
    "& .detail_date": {
        backgroundColor: "#DEF5EA",
        padding: "5px 10px",
        borderRadius: "7px"
    },
    "& .mt-30": {
        margin: "30px 0px 40px 0px"
    },
    "& .error_message": {
        color: "red",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontWeight: 600,
        marginTop: "5px",
        marginBottom: "-10px",
        marginLeft: "5px",
    }
});
