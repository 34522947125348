import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
export const configJSON = require("./config");
// Customizable Area End

export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    nameofBusiness: string;
    commonBusiness: string;
    grossRecipt: string;
    netProfit: string;
    presumptiveIncome: string;
    error: any;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class BusinessProfessionalIncomeController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            nameofBusiness: "",
            commonBusiness: "choose",
            grossRecipt: "",
            netProfit: "",
            presumptiveIncome: "",
            error: {}
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handleOnchangeBusiness = (event: { target: { name: string, value: string } }) => {
        const { name, value } = event.target;
        this.setState((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }
    handleFocusBusiness = (event: { target: { name: string, value: string } }) => {
        const { name } = event.target;
        this.setState((prevData) => ({
            error: {
                ...prevData.error,
                [name]: false
            }
        }));
    }
    handleBlurBusiness = (event: { target: { name: string, value: string } }) => {
        const { name,value } = event.target;
     this.setState((prevData) => ({
                error: {
                    ...prevData.error,
                    [name]: String(value).trim() === "" 
                }
            }));
    }
    handleSubmitData=()=>{
       Object.values(this.state.error).every((item)=>item!==true)
    }
    // Customizable Area End
}
