Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.logoAlt="pocket_tax";
exports.fileIncomeHeading="File Income Tax Return yourself in 3 simple steps";
exports.priceAt="Price at"
exports.startFiling="Start Filing"
exports.payNotNow="No need to pay now!"
exports.payLater="Pay later at the checkout."
exports.WelcometoPockettax="Welcome to Pockettax!"
exports.Letssetupyourprofile="Lets setup your profile"
exports.AddPANcard="Add PAN card"
exports.GetOTP="Get OTP"
exports.verifyyourdetails="You will receive an SMS with OTP to verify your details"
exports.placeHolderName="Name"
exports.placeHolderCard="Pan card number"
exports.PanDocumentPage="PanDocumentPage"
exports.Pending="Pending"
exports.Complete="Complete"
exports.todolist="To DO LIST"
exports.panCardEndpoint="account_block/pan_profiles"
exports.assismentYearEndpoint = "account_block/pan_profiles/last_three_years"
exports.dashboarApiMethodTypePut = "PUT";
exports.authToken="authToken"
// Customizable Area End