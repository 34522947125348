import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
export const configJSON = require("./config");
// Customizable Area End

export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    onClick:()=>void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    nameofBusiness: string;
    businessCode: string,
    netProfitPl: string,
    Depreciation: string,
    DetailedPL:string,
    taxDepreciation:string,
    error: any;
    status: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class RegularBusinessIncomeController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            nameofBusiness: "",
            businessCode:"choose",
            netProfitPl: "",
            Depreciation: "",
            DetailedPL:"",
            taxDepreciation:"",
            error: {},
            status: false
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handleOnchangeRegularIncome = (event: { target: { name: string, value: string } }) => {
        const { name, value } = event.target;
        let val = value.replace(/^\s+/, "");
        val = val.replace(/\s{2,}/g, ' ');
        this.setState((prevData) => ({
            ...prevData,
            [name]: val
        }))
    }
    handleOnchangeRegularChar = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        let val = value.replace(/^\s+/, "");
        val = val.replace(/\s{2,}/g, ' ');
        if (!/^[a-zA-Z ]*$/.test(value) || value.length > 25) {
            return;
        }
        this.setState((prevData) => ({
            ...prevData,
            [name]: val
        }))
    }
    handleOnchangeBusiness = (event: { target: { name: string, value: string } }) => {
        const { name, value } = event.target;
        this.setState((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }
    handleOnchangeNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if(/[^0-9\b]/.test(value) || value.length > 12) {
            return
        }
        this.setState((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }
    handleFocusRegularIncome = (name: string) => {
        this.setState((prevData) => ({
            error: {
                ...prevData.error,
                [name]: false
            }
        }));
    }
    handleBlurRegularIncome = (event: { target: { name: string, value: string } }) => {
        const { name,value } = event.target;
     this.setState((prevData) => ({
                error: {
                    ...prevData.error,
                    [name]: String(value).trim() === "" 
                }
            }));
    }
    validateFields = () => {
        const { Depreciation, businessCode, nameofBusiness, netProfitPl } = this.state
        const regularFieldsObject = { nameofBusiness, businessCode,netProfitPl, Depreciation}
        const emptyFields: Record<string, boolean> = {};

        for (const [fieldName, fieldValue] of Object.entries(regularFieldsObject)) {
            if(String(fieldValue).trim() === "" || String(fieldValue).trim() === "choose") {
                emptyFields[fieldName] = true
            } else {
                emptyFields[fieldName] = false;
              }
        }
        this.setState({ error: emptyFields })
        return emptyFields
    }
    handleSubmitDataRegularIncome=()=>{
        const checkvalidation = this.validateFields();

        if (Object.values(checkvalidation).every(value => value !== true)) {
            this.setState({ status: true })
        }
    }
    handleOnClose = () => {
        this.setState({ status: false })
    }
    // Customizable Area End
}
